import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { useGetConfigurationQuery } from '../../store/purchasing'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { Grid } from '@mui/material'
import { FormField } from '@procurement/store/types'

interface FormConfigContextValue {
  get: (fieldConfigId: string) => Omit<FormField, 'id'> | undefined
}

const FormConfigContext = createContext<FormConfigContextValue>({
  get: () => undefined,
})

export const useFormConfig = () => useContext(FormConfigContext)

export const Root = ({ children }: PropsWithChildren) => {
  const { activeProjectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({ projectTag: activeProjectTag })
  const requisitionFormFields = configuration?.suite.requisitionFormFields

  const fieldsConfig = useMemo(
    () => new Map(requisitionFormFields?.map(({ id, ...field }) => [id, field])),
    [requisitionFormFields],
  )

  return (
    <FormConfigContext.Provider
      value={{
        get: fieldsConfig.get.bind(fieldsConfig),
      }}
    >
      <Grid container spacing={4}>
        {children}
      </Grid>
    </FormConfigContext.Provider>
  )
}

import { Grid } from '@mui/material'
import { AttachmentList } from './AttachmentList'
import { AttachmentUpload } from './AttachmentUpload'
import { useFieldArray } from 'react-hook-form'

interface AttachmentsProps {
  canUpload?: boolean
  name: string
}

export const Attachments = ({ canUpload = true, name }: AttachmentsProps) => {
  const { append, remove, update } = useFieldArray({ name })

  return (
    <Grid container item spacing={2}>
      <Grid item xs={8}>
        <AttachmentList name={name} onRemove={remove} onUpdate={update} />
      </Grid>
      {canUpload && (
        <Grid item xs={4} alignSelf='flex-end'>
          <AttachmentUpload name={name} onAppend={append} />
        </Grid>
      )}
    </Grid>
  )
}

import { CancelOutlined } from '@mui/icons-material'
import { Autocomplete, TextField, TextFieldProps } from '@mui/material'
import { Controller } from 'react-hook-form'
import { styleProps, errorProps } from './sharedProps'

export type AutocompleteFieldCtrlProps = TextFieldProps & {
  name: string
  options: unknown[]
  disabled?: boolean
  freeSolo?: boolean
  valueAsNumber?: boolean
  getOptionLabel?: (option: unknown) => string
}

/**
 * MUI TextField with MUI Autocomplete controlled by react-hook-form.
 * Also contains a clear button to reset the field.
 */
export const AutocompleteFieldCtrl = ({
  name,
  options,
  required,
  disabled,
  getOptionLabel,
  valueAsNumber,
  freeSolo = true,
  ...rest
}: AutocompleteFieldCtrlProps) => {
  return (
    <Controller
      name={name}
      rules={{ required }}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          freeSolo={freeSolo}
          options={options}
          disabled={disabled}
          clearIcon={<CancelOutlined color='primary' />}
          clearText='Clear'
          getOptionLabel={getOptionLabel}
          value={(field.value as string) ?? null}
          onChange={(_, value) => field.onChange(getFormattedValue(value, valueAsNumber))}
          onInputChange={(_, value) =>
            freeSolo ? field.onChange(getFormattedValue(value, valueAsNumber)) : undefined
          }
          renderInput={(params) => (
            <TextField
              {...styleProps}
              variant='filled'
              required={required}
              {...params}
              inputRef={field.ref}
              onBlur={field.onBlur}
              {...rest}
              {...errorProps(error)}
            />
          )}
        />
      )}
    />
  )
}

const getFormattedValue = (value: unknown, valueAsNumber?: boolean) => {
  const numericValue = Number(value)

  // if the input was cleared
  if (value === '' || value === null) {
    return valueAsNumber ? null : ''
  }

  return valueAsNumber && !Number.isNaN(numericValue) ? numericValue : value
}

import {
  DataPoint,
  TimeSeriesArguments,
  TimeSeriesDays,
  TimeSeriesFunction,
} from '@common/dto/dashboard/dashboard'
import { format, getISOWeek, parseISO, startOfISOWeek, startOfMonth } from 'date-fns'
import getISOWeekYear from 'date-fns/getISOWeekYear'
import { TimeFilters } from '../constants'
import { CategoryDataByPeriod } from './TimeSeriesStackedWidget'
import { NumberDataByPeriod } from './TimeSeriesWidget'

type DataPointsByPeriod<T> = Record<string, DataPoint<T>[]>
type NullableDataPointsByPeriod<T> = Record<string, DataPoint<T>[] | null>

const getArgsSum = (args: TimeSeriesArguments, dataPoint: DataPoint<number>) => {
  let argsSum = 0
  for (const arg of args) {
    argsSum += dataPoint[arg]
  }
  return argsSum
}

const dateFunctionMap = {
  [TimeFilters.WEEK]: generateWeeklyLabelsBetweenTwoDates,
  [TimeFilters.MONTH]: generateMonthlyLabelsBetweenTwoDates,
  [TimeFilters.QUARTER]: generateQuarterlyLabelsBetweenTwoDates,
}

const dateLabelMap = {
  [TimeFilters.WEEK]: getWeeklyLabel,
  [TimeFilters.MONTH]: getMonthlyLabel,
  [TimeFilters.QUARTER]: getQuarterlyLabel,
}

export const getDaysGroupedByPeriod = <T>(
  days: TimeSeriesDays<T>,
  timeFilter: TimeFilters,
): DataPointsByPeriod<T> => {
  const result: DataPointsByPeriod<T> = {}

  const allDates: Date[] = days.map((d) => parseISO(d.date))
  const sortedDates = allDates.sort((a, b) => a.getTime() - b.getTime())

  const labels = dateFunctionMap[timeFilter](sortedDates[0], sortedDates[sortedDates.length - 1])
  labels.forEach((label) => (result[label] = []))

  days.forEach((day) => {
    const label = dateLabelMap[timeFilter](parseISO(day.date))

    if (!result[label]) {
      result[label] = []
    }
    result[label].push(day.dataPoints)
  })

  return result
}

function generateWeeklyLabelsBetweenTwoDates(startDate: Date, endDate: Date): string[] {
  const labels: string[] = []
  const currentDate = startOfISOWeek(startDate)

  while (currentDate <= endDate) {
    const label = getWeeklyLabel(currentDate)
    labels.push(label)

    currentDate.setDate(currentDate.getDate() + 7)
  }
  return labels
}

function getWeeklyLabel(date: Date): string {
  return `W${getISOWeek(date)} '${getISOWeekYear(date).toString().slice(-2)}`
}

function generateMonthlyLabelsBetweenTwoDates(startDate: Date, endDate: Date): string[] {
  const labels: string[] = []
  const currentDate = startOfMonth(new Date(startDate))

  while (currentDate <= endDate) {
    labels.push(getMonthlyLabel(currentDate))
    currentDate.setMonth(currentDate.getMonth() + 1)
  }

  return labels
}

function getMonthlyLabel(date: Date): string {
  return format(date, "MMM ''yy")
}

function generateQuarterlyLabelsBetweenTwoDates(startDate: Date, endDate: Date): string[] {
  const labels: string[] = []
  const currentDate = startOfMonth(startDate)

  while (currentDate <= endDate) {
    const label = getQuarterlyLabel(currentDate)
    labels.push(label)

    currentDate.setMonth(currentDate.getMonth() + 3)
  }
  return labels
}

function getQuarterlyLabel(date: Date): string {
  return format(date, "qqq ''yy")
}

export const getAggregatedData = (
  periods: NullableDataPointsByPeriod<number>,
  func: TimeSeriesFunction,
  args: TimeSeriesArguments,
) => {
  if (func === 'sum') {
    return periodSum(periods, args)
  }

  if (func === 'cumulativeSum') {
    return periodCumulativeSum(periods, args)
  }

  if (func === 'sumDivision') {
    return periodSumDivision(periods, args)
  }

  if (func === 'cumulativeSumDivision') {
    return periodCumulativeSumDivision(periods, args)
  }

  return {}
}

const periodSum = (periods: NullableDataPointsByPeriod<number>, args: TimeSeriesArguments) => {
  const dataByPeriod: NumberDataByPeriod = {}
  for (const period in periods) {
    const periodValue = periods[period]
    dataByPeriod[period] =
      periodValue === null ? 0 : periodValue.reduce((a, b) => a + getArgsSum(args, b), 0)
  }
  return dataByPeriod
}

const periodCumulativeSum = (
  periods: NullableDataPointsByPeriod<number>,
  args: TimeSeriesArguments,
) => {
  const dataByPeriod: NumberDataByPeriod = {}
  let cumulative = 0
  for (const period in periods) {
    const periodValue = periods[period]
    const periodSum =
      periodValue === null ? 0 : periodValue.reduce((a, b) => a + getArgsSum(args, b), 0)

    dataByPeriod[period] = periodSum + cumulative
    cumulative += periodSum
  }
  return dataByPeriod
}

const periodSumDivision = (
  periods: NullableDataPointsByPeriod<number>,
  args: TimeSeriesArguments,
) => {
  const dataByPeriod: NumberDataByPeriod = {}
  for (const period in periods) {
    const periodValue = periods[period]

    if (periodValue === null) {
      dataByPeriod[period] = 0
      continue
    }

    const field1Sum = periodValue.reduce((a, b) => a + b[args[0]], 0)
    const field2Sum = periodValue.reduce((a, b) => a + b[args[1]], 0)
    dataByPeriod[period] = field2Sum > 0 ? field1Sum / field2Sum : 0
  }
  return dataByPeriod
}

const periodCumulativeSumDivision = (
  periods: NullableDataPointsByPeriod<number>,
  args: TimeSeriesArguments,
) => {
  const dataByPeriod: NumberDataByPeriod = {}
  let [cumulativeField1Sum, cumulativeField2Sum] = [0, 0]
  for (const period in periods) {
    const periodValue = periods[period]

    if (periodValue !== null) {
      const field1Sum = periodValue.reduce((a, b) => a + b[args[0]], 0)
      const field2Sum = periodValue.reduce((a, b) => a + b[args[1]], 0)
      cumulativeField1Sum += field1Sum
      cumulativeField2Sum += field2Sum
    }

    dataByPeriod[period] = cumulativeField2Sum > 0 ? cumulativeField1Sum / cumulativeField2Sum : 0
  }

  return dataByPeriod
}

export const getAggregatedCategoryData = (
  periods: NullableDataPointsByPeriod<number[]>,
  labels: string[],
  func: TimeSeriesFunction,
  args: TimeSeriesArguments,
) => {
  if (func === 'sum') {
    return aggregatePeriodCategories(periods, labels, args, aggregateBySum)
  }
  if (func === 'average') {
    return aggregatePeriodCategories(periods, labels, args, aggregateByAverage)
  }

  return {}
}

const aggregatePeriodCategories = (
  periods: NullableDataPointsByPeriod<number[]>,
  labels: string[],
  args: string[],
  aggregator: (labelIndex: number, args: string[], values: DataPoint<number[]>[]) => number,
) => {
  const dataByPeriod: CategoryDataByPeriod = {}

  for (const period in periods) {
    dataByPeriod[period] = {}

    for (let i = 0; i < labels.length; i++) {
      const currentCategory = labels[i]
      const periodValue = periods[period]

      dataByPeriod[period][currentCategory] =
        periodValue === null ? 0 : aggregator(i, args, periodValue)
    }
  }
  return dataByPeriod
}

const aggregateBySum = (
  labelIndex: number,
  args: string[],
  values: DataPoint<number[]>[],
): number => {
  return values.reduce((a, b) => {
    let argsSum = 0
    for (const arg of args) {
      argsSum += b[arg][labelIndex]
    }
    return a + argsSum
  }, 0)
}

const aggregateByAverage = (
  labelIndex: number,
  args: string[],
  values: DataPoint<number[]>[],
): number => {
  const count = values.length
  const sum = aggregateBySum(labelIndex, args, values)
  return sum / count
}

import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { useSnackbar } from 'notistack'
import { useStartNegotiationsMutation } from '@price-list/store/price-list'
import { NegotiationEventDto } from '@pactum/price-list-types'
import pluralize from 'pluralize'

export const enum NegotiationEventAction {
  START = 'START',
}

export const useNegotiationEventActions = (negotiationEvents: NegotiationEventDto[]) => {
  const [startNegotiations] = useStartNegotiationsMutation()
  const { enqueueSnackbar } = useSnackbar()

  const projectTag = negotiationEvents[0]?.projectTag

  const availableActions: NegotiationEventAction[] = [
    ...new Set(negotiationEvents.flatMap(getAvailableActions)),
  ]

  const getNegotiationEventsForAction = (action: NegotiationEventAction) =>
    negotiationEvents.filter((it) => getAvailableActions(it).includes(action))

  const handleNegotiationEventStartAction = async () => {
    const negotiationEventIds = getNegotiationEventsForAction(NegotiationEventAction.START).map(
      ({ id }) => id,
    )
    try {
      await startNegotiations({
        projectTag,
        ids: negotiationEventIds,
      }).unwrap()
      const externalIds = negotiationEvents
        .filter((it) => negotiationEventIds.includes(it.id))
        .map((it) => it.externalId)
      enqueueSnackbar(
        `${pluralize('Negotiation', externalIds.length)} started for ${externalIds.join(', ')}`,
        {
          variant: 'success',
        },
      )
    } catch (error) {
      setError(error, 'Error occurred while starting negotiations')
    }
  }

  const setError = (error: unknown, message: string) => {
    const errorMessage = isBackendApiErrorResponse(error) ? error.data.message : message
    enqueueSnackbar(errorMessage, { variant: 'error' })
  }

  return {
    availableActions,
    getNegotiationEventsForAction: getNegotiationEventsForAction,
    handleNegotiationEventStartAction: handleNegotiationEventStartAction,
  }
}

const getAvailableActions = ({ status }: NegotiationEventDto) => {
  const actions: NegotiationEventAction[] = []

  if (status === 'READY') {
    actions.push(NegotiationEventAction.START)
  }

  return actions
}

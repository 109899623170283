import { PropsWithChildren } from 'react'
import { Grid, Typography } from '@mui/material'

export const createGetFieldControllerParams =
  ({ requiredFields, disabledFields }: { requiredFields: string[]; disabledFields: string[] }) =>
  (field: string, label: string = '') => ({
    name: field,
    disabled: disabledFields.includes(field),
    label: requiredFields.includes(field) ? label : label ? `${label} (Optional)` : '',
    'data-testid': field,
  })

export const HideableBlock = ({
  children,
  hidden,
}: PropsWithChildren & {
  hidden: boolean
}) => {
  if (hidden) {
    return null
  }

  return <>{children}</>
}

export const FormRow = ({ children }: PropsWithChildren) => (
  <Grid item container spacing={2}>
    {children}
  </Grid>
)

export const FormSectionTitle = ({ children }: PropsWithChildren) => (
  <Grid sx={{ mt: 1 }} item xs={12}>
    <Typography variant='subtitle2'>{children}</Typography>
  </Grid>
)

import { NegotiationEventLineItemSchema, SupplierLineItemSchema } from './SourcingEventForm.schema'

export function negotiationEventLineItemToSupplierLineItem(
  negotiationEventLineItem: NegotiationEventLineItemSchema,
): SupplierLineItemSchema {
  return {
    numberInCollection: negotiationEventLineItem.numberInCollection,
    quantity: negotiationEventLineItem.quantity,
    rowId: negotiationEventLineItem.rowId,
    unit: negotiationEventLineItem.unit,
    unitPrice: 1000,
  }
}

import { DataGrid, dataGridProps } from '@components/table/DataGrid'
import { TooltipCell } from '@components/TooltipCell'
import { Stack, TextField, Typography } from '@mui/material'
import {
  GridRowParams,
  type GridColDef,
  type GridRenderCellParams,
  type GridRowId,
} from '@mui/x-data-grid-pro'
import { useFormatter } from '@shared/hooks'
import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormConfig } from '@procurement/components/Form'
import {
  NegotiationEventLineItemSchema,
  SupplierLineItemSchema,
  type SourcingEventFormData,
} from './SourcingEventForm.schema'

type RowModel = SupplierLineItemSchema &
  Pick<NegotiationEventLineItemSchema, 'category' | 'description' | 'name'>

type SupplierLineItemsTableProps = {
  supplierIndex: number
}

export const SupplierLineItemsTable = ({ supplierIndex }: SupplierLineItemsTableProps) => {
  const name = `suppliers.${supplierIndex}.lineItems` as const
  const formatter = useFormatter()
  const formConfig = useFormConfig()
  const disabled = !!formConfig.get('lineItems')?.disabled
  const { watch, setValue } = useFormContext<SourcingEventFormData>()
  const [supplierLineItems, negotiationEventLineItems] = watch([name, 'lineItems'])
  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([])
  const currency = negotiationEventLineItems[0]?.currency

  const rows = useMemo(
    () => [
      ...supplierLineItems.flatMap((supplierLineItem) => {
        const negotiationEventLineItem = negotiationEventLineItems.find(
          (negotiationEventLineItem) =>
            negotiationEventLineItem.numberInCollection === supplierLineItem.numberInCollection,
        )
        if (!negotiationEventLineItem) return []
        return {
          ...supplierLineItem,
          category: negotiationEventLineItem.category,
          description: negotiationEventLineItem.description,
          name: negotiationEventLineItem.name,
        }
      }),
    ],
    [negotiationEventLineItems, supplierLineItems],
  )

  const updateRow = (newRow: RowModel): RowModel => {
    const updatedLineItems = supplierLineItems.map((lineItem) =>
      lineItem.rowId === newRow.rowId ? newRow : lineItem,
    )

    setValue(name, updatedLineItems)

    return newRow
  }

  const commonColumnProps = {
    disableColumnMenu: true,
    editable: !disabled,
    flex: 1,
  }

  const tableColumns: GridColDef<RowModel>[] = [
    {
      ...commonColumnProps,
      field: 'numberInCollection',
      headerName: '#',
      type: 'number',
      flex: 0.5,
      editable: false,
    },
    {
      ...commonColumnProps,
      field: 'name',
      headerName: 'Name',
      flex: 2,
      editable: false,
      renderCell: ({ value }: GridRenderCellParams<RowModel, string>) => (
        <TooltipCell tooltip={value}>{value}</TooltipCell>
      ),
    },
    {
      ...commonColumnProps,
      field: 'category',
      headerName: 'Category',
      flex: 1,
      editable: false,
    },
    {
      ...commonColumnProps,
      field: 'quantity',
      headerName: 'Quantity',
      type: 'number',
      flex: 1,
      editable: false,
    },
    {
      ...commonColumnProps,
      field: 'unit',
      headerName: 'Unit of measure',
      flex: 1,
      editable: false,
    },
    {
      ...commonColumnProps,
      field: 'unitPrice',
      flex: 2,
      type: 'number',
      headerName: 'Price per unit',
      renderCell: ({ value }: GridRenderCellParams<RowModel, number>) => {
        return formatter.currency(value!, { currency, maxFractionDigits: 6 })
      },
    },
  ]

  const totalLineItemsPrice = useMemo(() => {
    return supplierLineItems.reduce((sum, lineItem) => {
      return sum + lineItem.unitPrice * lineItem.quantity
    }, 0)
  }, [supplierLineItems])

  const expandedPanel = ({ row }: GridRowParams<RowModel>) => (
    <Stack direction='row' pl={14.5} py={1}>
      <TextField
        color='accent'
        disabled
        fullWidth
        label='Description'
        value={
          negotiationEventLineItems.find(
            (negotiationEventLineItem) =>
              negotiationEventLineItem.numberInCollection === row.numberInCollection,
          )?.description ?? ''
        }
        variant='outlined'
      />
    </Stack>
  )

  return (
    <Stack spacing={1}>
      <DataGrid
        {...dataGridProps}
        autoHeight={supplierLineItems.length > 0}
        columns={tableColumns}
        detailPanelExpandedRowIds={expandedRowIds}
        getDetailPanelContent={expandedPanel}
        getDetailPanelHeight={() => 'auto'}
        getRowId={(row) => row.rowId}
        onDetailPanelExpandedRowIdsChange={setExpandedRowIds}
        processRowUpdate={updateRow}
        onProcessRowUpdateError={(error) => console.error(error)}
        // Redux state is frozen for some reason, making a array copy fixes updates of the form
        // Related issue: https://github.com/orgs/react-hook-form/discussions/3715
        rows={rows}
        sx={{
          height: 'auto',
          minHeight: '76px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 1,
          },
        }}
      />
      <Stack direction='row' justifyContent='flex-end'>
        <Typography variant='subtitle2' fontSize='0.875rem'>
          Total:{' '}
          {currency ? formatter.currency(totalLineItemsPrice, { currency }) : totalLineItemsPrice}
        </Typography>
      </Stack>
    </Stack>
  )
}

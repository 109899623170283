import { ConfirmationModal } from '@components/ConfirmationModal'

interface NegotiationEventCommonModalProps {
  open: boolean
  onCancel: () => void
  onSubmit: () => void
  loading?: boolean
}

export const StartNegotiationConfirmationModal = ({
  ...props
}: NegotiationEventCommonModalProps) => {
  const title = 'Start negotiation'
  const content =
    'A negotiation link will be emailed to the supplier. This action can not be undone.'

  return (
    <ConfirmationModal
      confirmType='positive'
      title={title}
      content={content}
      confirmButtonText='Start'
      {...props}
    />
  )
}

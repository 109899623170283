import { Box } from '@mui/material'
import { LegendBarItem } from '@pages/DashboardPage/Widgets/Legend/LegendBarItem'
import { OverflowMenu } from '@pages/DashboardPage/Widgets/Legend/OverflowMenu'
import { useEffect, useRef, useState } from 'react'
import { Chart } from 'chart.js'

interface Props {
  chart: Chart | null
  items: Item[]
}

export interface Item {
  text: string
  bulletColor: string
  textColor: string
  datasetIndex?: number
}

export function LegendBar({ chart, items }: Props) {
  const navRef = useRef<null | HTMLElement>(null)
  const [visibilityMap, setVisibilityMap] = useState<Record<string, boolean>>({})
  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const updatedEntries: Record<string, boolean> = {}
    entries.forEach((entry) => {
      if (entry.target instanceof HTMLElement) {
        const targetId = entry.target.dataset['targetid'] ?? ''
        updatedEntries[targetId] = entry.isIntersecting
      }
    })

    setVisibilityMap((prev) => ({
      ...prev,
      ...updatedEntries,
    }))
  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: navRef.current,
      threshold: 1,
    })

    // Add observers to children
    Array.from(navRef.current?.children ?? []).forEach((item) => {
      observer.observe(item)
    })
    return () => observer.disconnect()
  }, [items])

  const handleLegendClick = (datasetIndex: number | undefined) => {
    if (typeof datasetIndex === 'undefined') {
      return
    }

    if (chart !== null && typeof chart !== 'undefined') {
      chart.getDatasetMeta(datasetIndex).hidden =
        chart.getDatasetMeta(datasetIndex).hidden === null
          ? true
          : !chart.getDatasetMeta(datasetIndex).hidden
      chart.update() // re-draw chart to hide dataset
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyItems: 'start',
        overflow: 'hidden',
        padding: '0 20px',
      }}
      ref={navRef}
    >
      {items.map((item) => {
        return (
          <div key={item.datasetIndex} data-targetid={String(item.datasetIndex)}>
            <LegendBarItem
              datasetIndex={item.datasetIndex}
              text={item.text}
              textColor={item.textColor}
              bulletColor={item.bulletColor}
              onClick={handleLegendClick}
              sx={{
                visibility: !visibilityMap[String(item.datasetIndex)] ? 'hidden' : 'visible',
              }}
            />
          </div>
        )
      })}

      <OverflowMenu
        visibilityMap={visibilityMap}
        items={items}
        onClick={handleLegendClick}
        sx={{
          order: 99,
          position: 'sticky',
          right: '0',
          backgroundColor: 'white',
        }}
      />
    </Box>
  )
}

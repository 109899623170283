import { TextFieldProps } from '@mui/material'
import { DatePicker, PickersTimezone } from '@mui/x-date-pickers-pro'
import { useController } from 'react-hook-form'
import { styleProps, errorProps } from './sharedProps'

export type DatePickerCtrlProps = {
  name: string
  label: React.ReactNode
  required?: boolean
  disabled?: boolean
  disablePast?: boolean
  fullWidth?: boolean
  inputProps?: TextFieldProps
  timezone?: PickersTimezone
}

/** MUI DatePicker controlled by react-hook-form. */
export const DatePickerCtrl = ({
  name,
  required,
  fullWidth,
  inputProps,
  ...rest
}: DatePickerCtrlProps) => {
  const {
    field: { ref, ...fieldProps },
    fieldState: { error },
  } = useController({ name, rules: { required } })

  return (
    <DatePicker
      {...fieldProps}
      {...rest}
      slotProps={{
        textField: {
          ...styleProps,
          ...inputProps,
          ...fieldProps,
          ...errorProps(error),
          inputRef: ref,
          required,
          fullWidth,
        },
      }}
    />
  )
}

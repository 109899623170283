import { priceListBaseApi } from '@shared/api/price-list-base-api'
import {
  NegotiationEventListResultsDto,
  NegotiationPreparationsListResultsDto,
  NegotiationEventDto,
  NegotiationEventInputDto,
  NegotiationEventSupplierLineItemResultsDto,
} from '@pactum/price-list-types'
import { fileToFormData, responseAsObjectUrl } from '@utils/file'

export const priceListApi = priceListBaseApi('price-list-api')
  .enhanceEndpoints({
    addTagTypes: [
      'negotiation-events',
      'negotiation-event-details',
      'negotiation-preparations',
      'negotiation-event-line-items',
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      negotiationEventsList: build.query<
        NegotiationEventListResultsDto,
        {
          projectTag: string
          pageSize: number
          page: number
        }
      >({
        query: ({ projectTag, page, pageSize }) =>
          `price-list/${projectTag}/negotiation-events?page=${page}&pageSize=${pageSize}`,
        providesTags: ['negotiation-events'],
      }),
      negotiationEventDetails: build.query<
        NegotiationEventDto,
        {
          projectTag: string
          negotiationEventId: number
          withAudit?: boolean
          withDeleted?: boolean
        }
      >({
        query: ({ projectTag, negotiationEventId, withAudit, withDeleted }) =>
          `price-list/${projectTag}/negotiation-events/${negotiationEventId}?withAudit=${withAudit}&withDeleted=${withDeleted}`,
        providesTags: ['negotiation-event-details'],
      }),
      supplierLineItemsList: build.query<
        NegotiationEventSupplierLineItemResultsDto,
        {
          projectTag: string
          negotiationEventId: number
          supplierId: number
          pageSize: number
          page: number
          withDeleted?: boolean
        }
      >({
        query: ({ projectTag, negotiationEventId, supplierId, page, pageSize, withDeleted }) =>
          `price-list/${projectTag}/negotiation-events/${negotiationEventId}/suppliers/${supplierId}/line-items?page=${page}&pageSize=${pageSize}&withDeleted=${withDeleted}`,
        providesTags: ['negotiation-event-line-items'],
      }),
      deleteSupplierLineItem: build.mutation<
        void,
        {
          projectTag: string
          negotiationEventId: number
          supplierId: number
          lineItemId: number
        }
      >({
        query: ({ projectTag, negotiationEventId, supplierId, lineItemId }) => ({
          url: `price-list/${projectTag}/negotiation-events/${negotiationEventId}/suppliers/${supplierId}/line-items/${lineItemId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['negotiation-event-line-items'],
      }),
      createNegotiationEvent: build.mutation<
        NegotiationEventInputDto,
        {
          projectTag: string
          negotiationEventInput: NegotiationEventInputDto
        }
      >({
        query: ({ projectTag, negotiationEventInput }) => ({
          url: `price-list/${projectTag}/negotiation-events`,
          method: 'POST',
          body: negotiationEventInput,
        }),
        invalidatesTags: [
          'negotiation-events',
          'negotiation-event-line-items',
          'negotiation-preparations',
        ],
      }),
      updateNegotiationEvent: build.mutation<
        NegotiationEventInputDto,
        {
          projectTag: string
          negotiationEventId: number
          negotiationEventInput: NegotiationEventInputDto
        }
      >({
        query: ({ projectTag, negotiationEventId, negotiationEventInput }) => ({
          url: `price-list/${projectTag}/negotiation-events/${negotiationEventId}`,
          method: 'PUT',
          body: negotiationEventInput,
        }),
        invalidatesTags: [
          'negotiation-events',
          'negotiation-event-details',
          'negotiation-event-line-items',
          'negotiation-preparations',
        ],
      }),
      createNegotiationPreviewLink: build.mutation<
        { chatLink: string },
        { projectTag: string; negotiationEventId: number }
      >({
        query: ({ projectTag, negotiationEventId }) => ({
          url: `price-list/${projectTag}/negotiation-events/${negotiationEventId}/negotiation-preview`,
          method: 'POST',
        }),
      }),
      startNegotiations: build.mutation<void, { projectTag: string; ids: number[] }>({
        query: ({ projectTag, ids }) => ({
          url: `price-list/${projectTag}/negotiation-events/start`,
          method: 'POST',
          body: {
            ids,
          },
        }),
        invalidatesTags: ['negotiation-event-details', 'negotiation-events'],
      }),
      downloadNegotiationEventTemplate: build.mutation<string, string>({
        query: (projectTag) => ({
          url: `price-list/${projectTag}/negotiation-events/template`,
          responseHandler: responseAsObjectUrl,
        }),
      }),
      importNegotiationEvents: build.mutation<void, { projectTag: string; file: Blob }>({
        query: ({ projectTag, file }) => ({
          method: 'POST',
          body: fileToFormData(file),
          url: `price-list/${projectTag}/negotiation-events/import`,
        }),
        invalidatesTags: ['negotiation-events'],
      }),
      negotiationPreparationsList: build.query<
        NegotiationPreparationsListResultsDto,
        {
          projectTag: string
          supplierId: number
          pageSize: number
          page: number
          withDeleted?: boolean
        }
      >({
        query: ({ projectTag, supplierId, page, pageSize, withDeleted }) =>
          `${projectTag}/negotiation-preparations/${supplierId}?page=${page}&pageSize=${pageSize}&withDeleted=${withDeleted}`,
        providesTags: ['negotiation-preparations'],
      }),
      createNegotiationPreparation: build.mutation<
        void,
        {
          projectTag: string
          negotiationEventId: number
        }
      >({
        query: ({ projectTag, negotiationEventId }) => ({
          url: `${projectTag}/negotiation-preparations/${negotiationEventId}`,
          method: 'POST',
        }),
        invalidatesTags: ['negotiation-preparations'],
      }),
      deleteNegotiationPreparation: build.mutation<
        void,
        {
          projectTag: string
          negotiationEventId: number
          preparationId: number
        }
      >({
        query: ({ projectTag, negotiationEventId, preparationId }) => ({
          url: `${projectTag}/negotiation-preparations/${negotiationEventId}/${preparationId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['negotiation-preparations'],
      }),
    }),
  })

export const {
  useNegotiationEventsListQuery,
  useNegotiationEventDetailsQuery,
  useSupplierLineItemsListQuery,
  useDeleteSupplierLineItemMutation,
  useCreateNegotiationEventMutation,
  useUpdateNegotiationEventMutation,
  useCreateNegotiationPreviewLinkMutation,
  useStartNegotiationsMutation,
  useDownloadNegotiationEventTemplateMutation,
  useImportNegotiationEventsMutation,
  useNegotiationPreparationsListQuery,
  useCreateNegotiationPreparationMutation,
  useDeleteNegotiationPreparationMutation,
} = priceListApi

import { Box, Tooltip } from '@mui/material'
import { PropsWithChildren, useEffect, useRef, useState } from 'react'

export const Ellipsis = ({ children }: PropsWithChildren) => {
  const textRef = useRef<HTMLDivElement>(null)
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth)
    }
  }, [children])

  return (
    <Tooltip title={isTruncated ? children : ''} disableHoverListener={!isTruncated}>
      <Box
        ref={textRef}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'block',
        }}
      >
        {children}
      </Box>
    </Tooltip>
  )
}

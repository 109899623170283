import { useState } from 'react'
import { GreetingHeader } from '@components/Layout/GreetingHeader'
import { PrimaryButton } from '@components/Layout/Statistics'
import { CreateNegotiationEvent } from '../CreateNegotiationEvent/CreateNegotiationEvent'
import { Box, Stack } from '@mui/material'

export const PriceListNegotiationsHeader = () => {
  const [showCreateNewNegotiationEventForm, setShowCreateNewNegotiationEventForm] = useState(false)

  return (
    <>
      <GreetingHeader>
        <Box sx={{ mt: 1, ml: 'auto' }}>
          <Stack direction='row' spacing={2}>
            <PrimaryButton onClick={() => setShowCreateNewNegotiationEventForm(true)}>
              Create new negotiation
            </PrimaryButton>
          </Stack>
        </Box>
      </GreetingHeader>
      <CreateNegotiationEvent
        isOpen={showCreateNewNegotiationEventForm}
        handleClose={() => setShowCreateNewNegotiationEventForm(false)}
      />
    </>
  )
}

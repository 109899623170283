import { type ReactElement } from 'react'
import { Grid } from '@mui/material'
import { useFormConfig } from './Root'

type FieldProps = {
  columns: number
  configId?: string
  label?: string
  name: string
  render: (params: {
    'data-testid': string
    disabled: boolean
    label: string
    name: string
  }) => ReactElement<{
    'data-testid': string
    disabled: boolean
    label: string
    name: string
  }>
}

export const Field = ({ columns, configId, label, name, render }: FieldProps) => {
  const formConfig = useFormConfig()
  const fieldConfig = configId ? formConfig.get(configId) : null

  if (fieldConfig && !fieldConfig.visible) {
    return null
  }

  return (
    <Grid item xs={columns}>
      {render({
        'data-testid': name,
        disabled: !!fieldConfig?.disabled,
        label: label ? (fieldConfig?.required ? label : `${label} (Optional)`) : '',
        name,
      })}
    </Grid>
  )
}

import { useWatch } from 'react-hook-form'
import { InputAdornment } from '@mui/material'
import { getCurrencySymbol } from '@procurement/utils/currency'

export const CurrencyInputAdornment = ({ name }: { name: string }) => {
  const currency = useWatch<Record<string, unknown>>({ name })

  return (
    <InputAdornment position='start'>
      {getCurrencySymbol(currency ? String(currency) : undefined)}
    </InputAdornment>
  )
}

import { useDeleteNegotiationEventAttachments } from '@procurement/hooks/useDeleteNegotiationEventAttachments'
import { useUploadNegotiationEventAttachments } from '@procurement/hooks/useUploadNegotiationEventAttachments'
import { Attachment, LocalAttachment } from '@procurement/store/schema'
import { NegotiationEvent, NegotiationEventSupplier } from '@procurement/store/types'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

type UseSyncNegotiationEventAttachments = () => [
  /** Updates (uploads and deletes) negotiation event attachments to match local form state. */
  (params: {
    attachments: (Attachment | LocalAttachment)[]
    negotiationEventId: NegotiationEvent['id']
    supplierId?: NegotiationEventSupplier['id']
  }) => Promise<void>,
  {
    error: FetchBaseQueryError | SerializedError | undefined
    isError: boolean
    isLoading: boolean
  },
]

export const useSyncNegotiationEventAttachments: UseSyncNegotiationEventAttachments = () => {
  const [
    deleteNegotiationEventAttachments,
    { error: deleteError, isError: isDeleteError, isLoading: isDeleteLoading },
  ] = useDeleteNegotiationEventAttachments()
  const [
    uploadNegotiationEventAttachments,
    { error: uploadError, isError: isUploadError, isLoading: isUploadLoading },
  ] = useUploadNegotiationEventAttachments()

  return [
    async ({ attachments, negotiationEventId, supplierId }) => {
      const savedAttachments = attachments.filter(isAttachment)
      const localAttachments = attachments.filter(isLocalAttachment)

      const removedAttachments = savedAttachments.filter((a) => {
        return localAttachments.every((da) => !hasSameFileName(a, da))
      })

      const addedAttachments = localAttachments.filter((da) => {
        return savedAttachments.every((a) => !hasSameFileName(a, da))
      })

      if (removedAttachments.length) {
        await deleteNegotiationEventAttachments({
          attachments: removedAttachments,
          negotiationEventId,
        })
      }

      if (addedAttachments.length) {
        await uploadNegotiationEventAttachments({
          attachments: addedAttachments,
          negotiationEventId,
          supplierId,
        })
      }
    },
    {
      error: deleteError || uploadError,
      isError: isDeleteError || isUploadError,
      isLoading: isDeleteLoading || isUploadLoading,
    },
  ]
}

function hasSameFileName(attachment: Attachment, localAttachment: LocalAttachment) {
  return attachment.fileName === localAttachment.file.name
}

function isAttachment(attachment: Attachment | LocalAttachment): attachment is Attachment {
  return 'fileName' in attachment
}

function isLocalAttachment(
  attachment: Attachment | LocalAttachment,
): attachment is LocalAttachment {
  return 'file' in attachment
}

import z from 'zod'
import { AttachmentTagEnum, TermsBasis } from './types'

export type Attachment = z.infer<typeof attachmentSchema>
export type ContractModel = z.infer<typeof contractModelSchema>
export type DateField = z.Schema<Date | string | null | undefined>
export type ImportCoupaEntityFormData = z.infer<typeof coupaEntityFormDataSchema>
export type ImportCoupaSourcingEventEntityFormData = z.infer<
  typeof coupaSourcingEventEntityFormDataSchema
>
export type Incentive = z.infer<typeof incentiveSchema>
export type LocalAttachment = z.infer<typeof localAttachmentSchema>
export type DetachedAttachment = LocalAttachment
export type NegotiationSettings = z.infer<typeof negotiationSettingsSchema>

export const nullishNanNumberSchema = z
  .union([z.number(), z.nan()])
  .nullish()
  .transform((value) => (Number.isNaN(value) ? null : value))

const nullishNanNonNegativeNumberSchema = z
  .union([z.number().min(0), z.nan()])
  .nullish()
  .transform((value) => (Number.isNaN(value) ? null : value))

export const validateSingleDecimalPlaceIfNumber = (x: number | null | undefined) => {
  if (typeof x !== 'number') {
    return true
  }
  return x * 10 - Math.trunc(x * 10) < Number.EPSILON
}

export const attachmentSchema = z.object({
  id: z.number(),
  contentLength: z.number(),
  contentType: z.string(),
  date: z.string().datetime(),
  externalId: z.string().nullish(),
  fileName: z.string(),
  isVisibleToSupplier: z.boolean(),
  supplierId: z.number().nullish(),
  tag: z.nativeEnum(AttachmentTagEnum),
})

export const contractIncotermsSchema = z.object({
  benchmark: z.string().nullish(),
  acceptable: z.array(z.string()).nullish(),
})

export const contractLengthAlternativeSchema = z.object({
  id: z.number(),
  contractLengthMonths: z.number(),
  priceChangePercentage: z.number(),
})

export const contractModelSchema = z.object({
  id: z.string().nullish(),
  properties: z
    .object({
      buyerOrganisationLegalAddress: z.string().nullish(),
      buyerOrganisationLegalName: z.string().nullish(),
      buyerSignerEmail: z.string().email().or(z.literal('')).nullish(),
      buyerSignerName: z.string().nullish(),
      invoiceDates: z.record(z.string(), z.string().nullish()).nullish(),
      itamRegistrationDone: z.boolean().nullish(),
      orderFormNumber: z.string().nullish(),
      previousContractDate: z
        .union([z.date(), z.string()])
        .nullish()
        .transform((val) => (val ? new Date(val) : undefined)) as DateField,
      riskAssessmentDone: z.boolean().nullish(),
      scopeOfServices: z.string().nullish(),
    })
    .nullish(),
})

export const coupaEntityFormDataSchema = z
  .object({
    id: z.number().nullish(),
  })
  .transform((data) => ({
    ...data,
  }))

export const coupaSourcingEventEntityFormDataSchema = z
  .object({
    id: z.string().nullish(),
  })
  .transform((data) => ({
    ...data,
  }))

export const localAttachmentSchema = z.object({
  file: z.instanceof(File),
  isVisibleToSupplier: z.boolean(),
})

export const detachedAttachmentSchema = localAttachmentSchema

export const incentiveSchema = z.object({
  id: z.string(),
  primaryWording: z.string(),
  secondaryWording: z.string().nullish(),
})

export const rebatesSchema = z.object({
  benchmark: nullishNanNonNegativeNumberSchema,
  minimum: nullishNanNonNegativeNumberSchema,
  target: nullishNanNonNegativeNumberSchema,
})

export const warrantySchema = z.object({
  benchmark: nullishNanNonNegativeNumberSchema,
  minLength: nullishNanNonNegativeNumberSchema,
  maxLength: nullishNanNonNegativeNumberSchema,
})

export const paymentDayObjectSchema = z.union([
  z.object({
    type: z.literal('net'),
    netDays: z.number(),
    externalId: z.string().nullish(),
  }),
  z.object({
    type: z.literal('eom'),
    netDays: z.number(),
    averageDays: z.number(),
    eomDays: z.number(),
    externalId: z.string().nullish(),
  }),
  z.object({
    type: z.literal('eow'),
    netDays: z.number(),
    averageDays: z.number(),
    eowDays: z.number(),
    externalId: z.string().nullish(),
  }),
])

export const negotiationSettingsSchema = z.object({
  contractIncoterms: contractIncotermsSchema.optional().nullish(),
  previousContractLength: z.number().nullish(),
  contractLength: z.number().nullish(),
  contractLengthAlternatives: z.array(contractLengthAlternativeSchema).nullish().default([]),
  contractStartDate: z
    .union([z.date(), z.string()])
    .nullish()
    .transform((val) => (val ? new Date(val) : null)) as DateField,
  incentives: z.array(z.string()).default([]),
  maxPaymentDays: z.union([nullishNanNumberSchema, paymentDayObjectSchema]),
  maxTotalPrice: nullishNanNumberSchema,
  maxTotalPriceIncreasePercent: nullishNanNumberSchema,
  maxTotalThresholdType: z
    .union([z.literal('absolute'), z.literal('discount'), z.literal('increase')])
    .default('absolute')
    .nullish(),
  minPaymentDays: z.union([nullishNanNumberSchema, paymentDayObjectSchema]),
  minTotalPriceDiscountPercent: nullishNanNumberSchema,
  negotiateInPercentages: z.boolean().nullish(),
  rebates: rebatesSchema.optional().nullish(),
  shouldOfferMembership: z.boolean().nullish(),
  termsBasis: z.nativeEnum(TermsBasis).nullish(),
  totalPriceTarget: nullishNanNumberSchema,
  warranty: warrantySchema.optional().nullish(),
})

import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormDialog, FormDialogButtonConfig, FormDialogProps } from '@components/FormDialog'
import { NegotiationEvent } from '@procurement/store/types'
import { capitalizeFirstLetter } from '@utils'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { useNegotiationEventMutations } from '@procurement/components/SourcingEventForm/hooks/useNegotiationEventMutations'
import { SourcingEventForm } from '@procurement/components/SourcingEventForm/SourcingEventForm'
// import { createRequiredFieldsValidationErrorCallback } from '@procurement/components/SourcingEventForm/validation'
import { SourcingEventFormData, sourcingEventFormSchema } from './SourcingEventForm.schema'
import { useUser } from '@hooks'
import { pick } from 'lodash'

interface SourcingEventFormDialogProps {
  negotiationEvent?: NegotiationEvent | null
  onClose: () => void
  open: boolean
}

export const SourcingEventFormDialog = ({
  open,
  negotiationEvent,
  onClose,
}: SourcingEventFormDialogProps) => {
  const { createNegotiationEvent, updateNegotiationEvent } = useNegotiationEventMutations()
  const [loading, setLoading] = useState(false)
  const [generalFormError, setGeneralFormError] = useState('')
  const errorRef = useRef<HTMLDivElement>(null)
  const { user } = useUser()

  const defaultValues = useMemo<SourcingEventFormData>(() => {
    if (negotiationEvent) {
      return sourcingEventFormSchema.parse(
        negotiationEventToSourcingEventFormData(negotiationEvent),
      )
    }

    return {
      buyerContactEmail: user?.email ?? '',
      buyerContactName: user?.name ?? '',
      deliveryDate: null,
      externalId: '',
      incentivesEnabled: true,
      isEveryCompetingQuoteComparable: true,
      lineItems: [],
      paymentDaysEnabled: true,
      quoteId: '',
      suppliers: [],
      title: '',
      uniqueName: '',
    }
  }, [negotiationEvent, user])

  const form = useForm<SourcingEventFormData>({
    defaultValues,
    resolver: zodResolver(sourcingEventFormSchema),
  })
  const { reset } = form

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  useEffect(() => {
    if (generalFormError) {
      errorRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [generalFormError, errorRef])

  const handleFormErrors = (error: unknown, form: UseFormReturn<SourcingEventFormData>) => {
    if (isBackendApiErrorResponse(error)) {
      if (Array.isArray(error.data.message)) {
        const formErrors: string[] = error.data.message

        formErrors.forEach((error, index) => {
          const [field, ...messageParts] = error.split(' ')
          const message = capitalizeFirstLetter(messageParts.join(' '))

          form.setError(
            field as keyof SourcingEventFormData,
            { message },
            { shouldFocus: index === 0 },
          )
        })
      } else {
        setGeneralFormError(error.data.message)
      }
    } else if (error instanceof Error) {
      setGeneralFormError(error.message)
    } else {
      console.error('Unknown error:', error)
    }
  }

  const onSubmit = async (
    formData: SourcingEventFormData,
    form: UseFormReturn<SourcingEventFormData>,
  ) => {
    setLoading(true)

    try {
      if (negotiationEvent) {
        await updateNegotiationEvent({ negotiationEvent, formData })
      } else {
        await createNegotiationEvent({ negotiationEvent: null, formData })
      }
      resetAndClose()
    } catch (error) {
      handleFormErrors(error, form)
    } finally {
      setLoading(false)
    }
  }

  const onErrors: FormDialogProps<SourcingEventFormData>['onErrors'] = (errors) => {
    if (errors) {
      console.error('Form errors:', errors)
    }
  }

  const resetAndClose = () => {
    form.reset()
    setGeneralFormError('')
    onClose()
  }

  const formSubmitButtons: FormDialogButtonConfig[] = [
    { type: 'cancel', label: 'Cancel' },
    {
      type: 'submit',
      label: negotiationEvent ? 'Save' : 'Create sourcing event',
    },
  ]

  return (
    <FormDialog
      buttons={formSubmitButtons}
      error={generalFormError}
      errorRef={errorRef}
      form={form}
      fullWidth
      loading={loading}
      maxWidth='md'
      onCancel={resetAndClose}
      onErrors={onErrors}
      onSubmit={onSubmit}
      open={open}
      title={negotiationEvent ? 'Edit sourcing event' : 'New sourcing event'}
    >
      <SourcingEventForm />
    </FormDialog>
  )
}

const negotiationEventToSourcingEventFormData = (
  negotiationEvent: NegotiationEvent,
): SourcingEventFormData => ({
  ...negotiationEvent,
  deliveryDate: new Date(negotiationEvent.deliveryDate),
  incentivesEnabled: true,
  isEveryCompetingQuoteComparable: true,
  paymentDaysEnabled: true,
  lineItems: negotiationEvent.lineItems.map((lineItem, index) => ({
    ...pick(lineItem, [
      'category',
      'currency',
      'description',
      'externalId',
      'id',
      'name',
      'numberInCollection',
      'quantity',
      'unit',
    ]),
    unitPrice: null,
    rowId: index + 1,
  })),
  suppliers: negotiationEvent.suppliers.map((supplier, supplierIndex) => ({
    ...supplier,
    rowId: supplierIndex + 1,
    lineItems: supplier.lineItems.map((lineItem, supplierLineItemIndex) => ({
      ...pick(lineItem, ['numberInCollection', 'quantity', 'unit']),
      rowId: supplierLineItemIndex + 1,
      unitPrice: lineItem.quotedUnitPrice ?? 1000,
    })),
    negotiationSettings: supplier.negotiationSettings
      ? {
          ...supplier.negotiationSettings,
          totalPriceTarget: supplier.negotiationSettings.totalPriceTarget ?? null,
        }
      : null,
  })),
})

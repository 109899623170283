import { Grid, Typography } from '@mui/material'
import { ProductType } from '@pactum/core-backend-types'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { NumberFieldCtrl } from '@shared/components/Form'
import { useGetConfigurationQuery } from '@procurement/store/purchasing'
import { PaymentDayObject } from '@procurement/store/types'
import { getPaymentDaysLabel, transformPaymentDaysOptions } from '@procurement/utils/paymentDays'
import { SupplierContactInformation } from '@procurement/components/NegotiationEventSupplierForm/SupplierContactInformation'
import { ExistingPaymentDaysFieldCtrl } from '@procurement/components/ExistingPaymentDaysFieldCtrl/ExistingPaymentDaysFieldCtrl'
import { Attachments } from '@procurement/components/FormSections/Attachments'

export const NegotiationEventSupplierForm = () => {
  const { activeProjectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({ projectTag: activeProjectTag })
  const localeOptions = configuration?.suite?.localeOptions ?? []
  const productType = configuration?.suite?.productType
  const isPurchasingProduct = productType === ProductType.Purchasing
  const paymentDayObjects: PaymentDayObject[] = transformPaymentDaysOptions(
    configuration?.suite.paymentDaysOptions ?? [],
  )

  return (
    <Grid container spacing={2}>
      <SupplierContactInformation
        localeOptions={localeOptions}
        showPhoneField={isPurchasingProduct}
      />
      <Grid item xs={12} md={12}>
        <Typography variant='subtitle2' gutterBottom>
          Existing payment terms (optional)
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <NumberFieldCtrl name='discount' label='Early discount (%)' variant='outlined' fullWidth />
      </Grid>
      <Grid item xs={12} md={4}>
        <ExistingPaymentDaysFieldCtrl
          name='discountDays'
          label='Early discount days'
          // TODO: provide early discount days options from procurement backend
          options={[]}
          getOptionLabel={getPaymentDaysLabel}
          variant='outlined'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ExistingPaymentDaysFieldCtrl
          name='paymentDays'
          label='Payment days'
          options={paymentDayObjects}
          getOptionLabel={getPaymentDaysLabel}
          variant='outlined'
          freeSolo={true}
          fullWidth
        />
      </Grid>
      <Attachments description='You can attach files for the supplier to use as background material, such as quotes and SOWs. They will be shown at the start of the negotiation for them to download.' />
    </Grid>
  )
}

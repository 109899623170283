import { z } from 'zod'
import {
  attachmentSchema,
  detachedAttachmentSchema,
  negotiationSettingsSchema,
  paymentDayObjectSchema,
  validateSingleDecimalPlaceIfNumber,
} from '@procurement/store/schema'

export type NegotiationEventSupplierFormData = z.infer<
  typeof negotiationEventSupplierFormDataSchema
>

const negotiationEventSupplierLineItemSchema = z.object({
  category: z.string().nullish(),
  currency: z.string().nullish(),
  deliveryLocation: z.string().nullish(),
  description: z.string().nullish(),
  incoterm: z.string().nullish(),
  name: z.string().nullish(),
  numberInCollection: z.number(),
  quantity: z.number(),
  quotedUnitPrice: z.number().nullish(),
  unit: z.string().nullish(),
})

export const negotiationEventSupplierFormDataSchema = z.object({
  id: z.number().nullish(),
  attachments: z.array(attachmentSchema).default([]),
  contactName: z
    .string()
    .nullable()
    .default(null)
    .transform((value) => (value?.trim() === '' ? null : value)),
  currency: z.string().default('USD'),
  detachedAttachments: z.array(detachedAttachmentSchema).default([]),
  discount: z
    .union([
      z
        .number()
        .min(0)
        .max(100)
        .refine(validateSingleDecimalPlaceIfNumber, 'Up to one decimal place allowed'),
      z.nan(),
      z.null(),
    ])
    .optional(),
  discountDays: paymentDayObjectSchema.nullish().default(null),
  email: z.string().email(),
  externalId: z
    .string()
    .nullable()
    .default(null)
    .transform((value) => (value?.trim() === '' ? null : value)),
  locale: z.string().nullish(),
  lineItems: z.array(negotiationEventSupplierLineItemSchema).default([]),
  name: z.string().min(1),
  negotiationSettings: negotiationSettingsSchema.nullable().default({
    incentives: [],
    maxPaymentDays: null,
    maxTotalPrice: null,
    minPaymentDays: null,
    totalPriceTarget: null,
  }),
  paymentDays: paymentDayObjectSchema.nullish().default(null),
  phone: z.string().nullish(),
  requisitionId: z.number().nullish(),
})

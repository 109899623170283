import { TimeField } from '@mui/x-date-pickers-pro'
import { useController } from 'react-hook-form'
import { styleProps, errorProps } from './sharedProps'

export type TimeFieldCtrlProps = {
  name: string
  label: React.ReactNode
  required?: boolean
  disablePast?: boolean
  fullWidth?: boolean
}

/** MUI TimeField controlled by react-hook-form */
export const TimeFieldCtrl = ({ name, required, fullWidth, ...rest }: TimeFieldCtrlProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules: { required } })

  return (
    <TimeField
      value={field.value as Date | undefined}
      {...rest}
      slotProps={{
        textField: {
          ...styleProps,
          ...errorProps(error),
          name: field.name,
          inputRef: field.ref,
          onChange: field.onChange,
          onBlur: field.onBlur,
          required,
          fullWidth,
        },
      }}
    />
  )
}

import { NegotiationEvent } from '@procurement/store/types'
import {
  useCreateNegotiationEventMutation,
  useLazyGetSingleNegotiationEventQuery,
  useUpdateNegotiationEventMutation,
} from '@procurement/store/purchasing'
import { getPaymentDaysNumericValue } from '@procurement/utils/paymentDays'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { SourcingEventFormData } from '../SourcingEventForm.schema'
import { validateNegotiationEventForm } from '../validation'
import { useSyncNegotiationEventAttachments } from './useSyncNegotiationEventAttachments'
import { omit } from 'lodash'

export type UpsertNegotiationEventParams = {
  formData: SourcingEventFormData
  negotiationEvent: NegotiationEvent | null
}

export const useNegotiationEventMutations = () => {
  const { activeProjectTag: projectTag } = useActiveProject()
  const [updateNegotiationEventRequest] = useUpdateNegotiationEventMutation()
  const [createNegotiationEventRequest] = useCreateNegotiationEventMutation()
  const [syncNegotiationEventAttachments] = useSyncNegotiationEventAttachments()
  const [getSingleNegotiationEvent] = useLazyGetSingleNegotiationEventQuery()

  const upsertNegotiationEvent = async ({
    negotiationEvent,
    formData: negotiationEventData,
  }: UpsertNegotiationEventParams) => {
    const prepared = prepareNegotiationEvent(negotiationEventData)

    validateNegotiationEventForm(prepared)

    let negotiationEventId = negotiationEvent?.id

    if (negotiationEventId) {
      await updateNegotiationEventRequest({
        negotiationEventData: prepared,
        negotiationEventId,
        projectTag,
      })
    } else {
      const createdNegotiationEvent = await createNegotiationEventRequest({
        negotiationEventData: prepared,
        projectTag,
      }).unwrap()
      negotiationEventId = createdNegotiationEvent.id
    }

    if (negotiationEventId) {
      const { data: latestNegotiationEvent } = await getSingleNegotiationEvent({
        negotiationEventId,
        projectTag,
      })

      if (latestNegotiationEvent) {
        await Promise.all(
          latestNegotiationEvent.suppliers.map((supplier, index) => {
            const formDataSupplierByIndex = negotiationEventData.suppliers[index]
            if (formDataSupplierByIndex.attachments?.length) {
              return syncNegotiationEventAttachments({
                attachments: formDataSupplierByIndex.attachments,
                negotiationEventId,
                supplierId: supplier.id,
              })
            }
          }),
        )
      }
    }
  }

  return {
    createNegotiationEvent: upsertNegotiationEvent,
    updateNegotiationEvent: upsertNegotiationEvent,
  }
}

const prepareNegotiationEvent = (formData: SourcingEventFormData): SourcingEventFormData => ({
  ...formData,
  suppliers: formData.suppliers.map((supplier) => ({
    ...omit(supplier, 'attachments'),
    attachments: [],
    negotiationSettings: supplier.negotiationSettings
      ? {
          ...supplier.negotiationSettings,
          minPaymentDays: supplier.negotiationSettings.minPaymentDays
            ? getPaymentDaysNumericValue(supplier.negotiationSettings.minPaymentDays)
            : undefined,
          maxPaymentDays: supplier.negotiationSettings.maxPaymentDays
            ? getPaymentDaysNumericValue(supplier.negotiationSettings.maxPaymentDays)
            : undefined,
        }
      : null,
  })),
})

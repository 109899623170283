import z, { ZodError, ZodIssueCode } from 'zod'
import get from 'lodash.get'
import { SourcingEventFormData } from './SourcingEventForm.schema'
import { isNullish } from '@procurement/utils/isNullish'
import { MAX_TOTAL_FILE_SIZE } from '@procurement/constants'
import { FormField } from '@procurement/store/types'
import { localAttachmentSchema } from '@procurement/store/schema'

const validatePaymentTerms = (formData: SourcingEventFormData) => {
  if (!formData.paymentDaysEnabled) {
    return []
  }

  const invalidSuppliers: string[] = []

  for (const supplier of formData.suppliers) {
    const existingDays = supplier?.initialPaymentTerms?.paymentDaysObject
    const maxDays = supplier?.negotiationSettings?.maxPaymentDays
    const minDays = supplier?.negotiationSettings?.minPaymentDays

    const params = [existingDays, maxDays, minDays]
    const all = params.every(Boolean)
    const none = params.every((param) => !param)

    const valid = all || none

    if (!valid) invalidSuppliers.push(supplier.name)
  }

  return invalidSuppliers
}

const validateAttachments = (formData: SourcingEventFormData): string[] => {
  return formData.suppliers
    .map((supplier) => {
      const localAttachments = z.array(localAttachmentSchema).parse(supplier.attachments)
      const totalSize = localAttachments.reduce(
        (size, attachment) => size + attachment.file.size,
        0,
      )
      return [supplier.name, totalSize <= MAX_TOTAL_FILE_SIZE]
    })
    .filter((result): result is [string, boolean] => !result[1])
    .map(([name]) => name)
}

export const validateNegotiationEventForm = (formData: SourcingEventFormData) => {
  const invalidSuppliers = validatePaymentTerms(formData)

  if (invalidSuppliers.length > 0) {
    throw new Error(
      `${invalidSuppliers[0]}: Either existing, minimum and maximum payment days must all be specified or none of them`,
    )
  }

  const invalidAttachments = validateAttachments(formData)

  if (invalidAttachments.length > 0) {
    throw new Error(
      `${invalidAttachments[0]}: You can only upload files with a total size of up to 50Mb at a time`,
    )
  }
}

const getValidationErrors = (formData: SourcingEventFormData, requiredFields: string[]) =>
  requiredFields
    .map((field) => ({
      code: ZodIssueCode.custom,
      path: [field],
      message: isNullish(get(formData, field)) ? 'Required' : '',
    }))
    .filter((issue) => issue.message)

export const createRequiredFieldsValidationErrorCallback =
  (formFieldsConfig: FormField[]) => (formData: SourcingEventFormData) => {
    const requiredFields = formFieldsConfig.flatMap((field) => (field.required ? field.id : []))
    const visibleFields = formFieldsConfig.flatMap((field) => (field.visible ? field.id : []))

    let filteredRequiredFields = requiredFields.filter((field) => visibleFields.includes(field))

    const paymentDaysFields = [
      'suppliers.0.initialPaymentTerms.paymentDays',
      'suppliers.0.negotiationSettings.minPaymentDays',
      'suppliers.0.negotiationSettings.maxPaymentDays',
    ]

    filteredRequiredFields = [
      ...filteredRequiredFields.filter((field) => !paymentDaysFields.includes(field)),
      ...(formData.paymentDaysEnabled ? paymentDaysFields : []),
    ]

    const validationErrors = getValidationErrors(formData, filteredRequiredFields)

    if (validationErrors.length) {
      throw new ZodError(validationErrors)
    }

    return true
  }

import z from 'zod'
import { CurrencyPicker, type CurrencyPickerProps } from '@components/CurrencyPicker'
import { useProjects } from '@hooks'
import { CurrencyCode } from '@pactum/common'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useController } from 'react-hook-form'
import { useGetCurrenciesQuery } from 'src/main/store/configuration'

export interface CurrencyPickerCtrlProps
  extends Omit<
    CurrencyPickerProps,
    | 'currencies'
    | 'displayCurrencies'
    | 'error'
    | 'onBlur'
    | 'onChange'
    | 'popularCurrencies'
    | 'selectedCurrency'
  > {
  name: string
}

export const CurrencyPickerCtrl = ({ name, helperText, ...rest }: CurrencyPickerCtrlProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name })
  const { activeOrgTag: orgTag, activeProjectTag: projectTag } = useProjects()
  const { data: currencyConfig } = useGetCurrenciesQuery(
    orgTag && projectTag ? { orgTag, projectTag } : skipToken,
  )

  const selectedCurrency = z.nativeEnum(CurrencyCode).nullish().default(null).parse(field.value)

  return (
    <CurrencyPicker
      {...rest}
      currencies={currencyConfig?.availableCurrencies}
      displayCurrencies={currencyConfig?.displayCurrencies}
      error={!!error}
      helperText={error?.message ?? helperText}
      onBlur={field.onBlur}
      onChange={field.onChange}
      popularCurrencies={currencyConfig?.popularCurrencies}
      selectedCurrency={selectedCurrency}
    />
  )
}

import { CancelOutlined } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { styleProps, errorProps } from './sharedProps'
import { ChangeEventHandler } from 'react'

export type NumberFieldCtrlProps = TextFieldProps & {
  inputTransformer?: (value: unknown) => unknown
  max?: number
  min?: number
  name: FieldPath<FieldValues>
  outputTransformer?: (value: unknown) => unknown
}

/**
 * MUI TextField for numeric input controlled by react-hook-form.
 * Includes a clear button to reset the field and min/max props for validation.
 */
export const NumberFieldCtrl = ({
  InputProps,
  inputTransformer = (value) => (value === null ? '' : value),
  max,
  min,
  name,
  outputTransformer = (value) => Number(value),
  required,
  ...rest
}: NumberFieldCtrlProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules: { required, min, max } })

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    field.onChange({
      ...event,
      target: {
        ...event.target,
        value: outputTransformer(event.target.value),
      },
    })
  }

  return (
    <TextField
      {...styleProps}
      variant='filled'
      type='number'
      required={required}
      inputProps={{ min, max }}
      InputProps={{
        endAdornment: <ClearButton onClick={() => field.onChange({ target: { value: null } })} />,
        ...InputProps,
      }}
      inputRef={field.ref}
      name={field.name}
      onChange={handleChange}
      onBlur={field.onBlur}
      value={inputTransformer(field.value)}
      {...rest}
      {...errorProps(error)}
    />
  )
}

const ClearButton = ({ onClick }: { onClick: () => void }) => (
  <InputAdornment position='end'>
    <IconButton edge='end' color='primary' onClick={onClick}>
      <CancelOutlined titleAccess='Clear' />
    </IconButton>
  </InputAdornment>
)

import { createAction, Priority, useRegisterActions } from 'kbar'
import { getAccessToken } from '#src/main/hooks/auth/useAuthInternals'
import { useSnackbar } from 'notistack'

export function useJwtCopyAction(isAdmin: boolean): void {
  const { enqueueSnackbar } = useSnackbar()

  useRegisterActions(
    [
      createAction({
        name: 'Copy JWT token',
        section: 'Development',
        keywords: 'jwt token',
        priority: Priority.LOW,
        perform: async () => {
          try {
            const token = await getAccessToken()
            await navigator.clipboard.writeText(token)
            enqueueSnackbar('JWT token copied to clipboard', { variant: 'success' })
          } catch (e) {
            enqueueSnackbar('Failed to copy JWT token to clipboard', { variant: 'error' })
          }
        },
      }),
    ],
    [isAdmin],
  )
}

import { ChartType, LegendItem, Plugin } from 'chart.js'

export const legendExtractorPlugin: Plugin<ChartType, LegendExtractorOptions> = {
  id: 'legendExtractor',

  afterUpdate: (chart, _args, options) => {
    if (typeof chart.options?.plugins?.legend?.labels?.generateLabels === 'undefined') {
      console.warn('Unable to extract legend, check if Legend plugin is installed')
      return
    }
    const items = chart.options.plugins.legend.labels.generateLabels(chart)
    options.setLegendItems(items)
  },
}
export interface LegendExtractorOptions extends Record<string, unknown> {
  setLegendItems: (items: LegendItem[]) => void
}

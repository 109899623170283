import { useEffect, useMemo, useState } from 'react'
import { useProjects } from 'src/main/hooks/useProjects'
import { useGetUserOrganizationsQuery } from 'src/main/store/userOrganizations'
import { AuthenticationStatus, getAuthenticationStatus } from './getAuthenticationStatus'
import { useAuthInternals } from '#src/main/hooks/auth/useAuthInternals'
import { useIdleDetection } from './useIdleDetection'

interface HttpExceptionData {
  message?: string
  statusCode?: string
  path?: string
  timestamp?: string
}

interface HttpException {
  status: number
  data?: HttpExceptionData
}

export const useAuthEntrypoint = () => {
  const {
    isUserLoaded,
    user,
    error: authError,
    loadUserOrHandleLoginError,
    startAuthentication,
  } = useAuthInternals()
  const [authenticationError, setAuthenticationError] = useState<string | null>(authError)
  const { activeProjectTag } = useProjects()

  const { data: organizations, error: organizationsLoadError } = useGetUserOrganizationsQuery(
    undefined,
    {
      skip: !isUserLoaded || !user,
    },
  )

  const authenticationStatus: AuthenticationStatus | null = useMemo(
    () =>
      organizationsLoadError
        ? AuthenticationStatus.ERROR_NO_ORGS_AVAILABLE
        : getAuthenticationStatus(isUserLoaded, user, organizations, authenticationError),
    [isUserLoaded, user, organizations, authenticationError, organizationsLoadError],
  )

  useIdleDetection()

  useEffect(() => {
    loadUserOrHandleLoginError()
  }, [loadUserOrHandleLoginError])

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.USER_NOT_LOGGED_IN) {
      startAuthentication()
    }
  }, [organizations, authenticationStatus, startAuthentication, activeProjectTag])

  useEffect(() => {
    if (organizationsLoadError) {
      setAuthenticationError(
        (organizationsLoadError as HttpException).data?.message ?? 'Unknown error',
      )
    }
  }, [organizationsLoadError])

  return {
    error: getErrorMessage(authenticationStatus, authenticationError),
    isLoading: authenticationStatus !== AuthenticationStatus.USER_LOGIN_FINISHED,
  }
}

const getErrorMessage = (
  status: AuthenticationStatus | null,
  authenticationError: string | null,
): string | null => {
  if (status === AuthenticationStatus.ERROR_NO_ORGS_AVAILABLE) {
    return 'No organizations available'
  }
  if (status === AuthenticationStatus.ERROR_AUTHENTICATING) {
    return authenticationError ?? null
  }

  return null
}

import { ChangeLog } from '@common/dto/platform/types'
import { PactumLoader } from '@components/PactumLoader'
import { ProductType } from '@pactum/core-backend-types'
import { useGetChangelogQuery } from 'src/main/store/changelog'
import { useGetProjectsQuery } from 'src/main/store/projects'
import { ChangelogList } from './ChangelogList'

export interface ChangeLogWithWorkspaces extends ChangeLog {
  affectedWorkspacesName: string[]
}

export const ChangelogPage = () => {
  const { data: changelog, isLoading: loadingChangelog } = useGetChangelogQuery()
  const { data: projects, isLoading: loadingProjects } = useGetProjectsQuery()

  const showLoader = loadingChangelog || loadingProjects || !changelog || !projects

  if (showLoader) {
    return <PactumLoader />
  }

  const items = changelog
    .map((item) => {
      const itemScope = new Set(item.scope)
      if (itemScope.has('ALL_SUITE')) {
        return {
          ...item,
          affectedWorkspacesName: ['All'],
        }
      }
      const suiteCompatibleWorkspaces = projects.filter(
        (project) => project.compatibleWithNegotiationSuite,
      )
      const affectedWorkspaces = suiteCompatibleWorkspaces.filter((project) =>
        itemScope.has(project.productType ?? ProductType.Bespoke),
      )
      const affectedWorkspacesName = affectedWorkspaces.map((workspace) => workspace.name)

      if (affectedWorkspaces.length === 0) {
        return null
      }

      return {
        ...item,
        affectedWorkspacesName: affectedWorkspacesName,
      }
    })
    .filter((item): item is ChangeLogWithWorkspaces => item !== null)

  return <ChangelogList items={items} />
}

import { ChipProps } from '@mui/material'
import { NegotiationSummaryStatus, NegotiationStatus } from './store/types'

export type StatusConfig = Readonly<{
  label: string | React.ReactNode
  color: ChipProps['color']
  variant?: ChipProps['variant']
}>

type NegotiationEventStatusMap = Readonly<Record<NonNullable<NegotiationStatus>, StatusConfig>>

export type NegotiationSummaryStatusMap = Readonly<
  Record<NonNullable<NegotiationSummaryStatus | 'READY'>, StatusConfig>
>

export const NEGOTIATION_EVENT_STATUS_CONFIG: NegotiationEventStatusMap = {
  READY: { label: 'Ready', color: 'stoneBlue' },
  WITHDRAWN: { label: 'Withdrawn', color: 'stoneBlue' },
  IN_PROGRESS: { label: 'In progress', color: 'lightGrey' },
  AGREEMENT_REACHED: { label: 'Agreement reached', color: 'success' },
  SIGNED: { label: 'Signed', color: 'success' },
  EXPIRED: { label: 'Expired', color: 'darkGrey' },
  DECLINED: { label: 'Declined', color: 'error' },
  REJECTED: { label: 'Rejected', color: 'error' },
}

export const NEGOTIATION_SUMMARY_STATUS_CONFIG: NegotiationSummaryStatusMap = {
  READY: { label: 'Ready', color: 'stoneBlue' },
  IN_PROGRESS: { label: 'In progress', color: 'lightGrey' },
  AGREEMENT_REACHED: { label: 'Agreement reached', color: 'success' },
  EXPIRED: { label: 'Expired', color: 'darkGrey' },
  DECLINED: { label: 'Declined', color: 'error' },
  REJECTED: { label: 'Rejected', color: 'error' },
}

export const FINAL_NEGOTIATION_STATUSES: NegotiationStatus[] = [
  'IN_PROGRESS',
  'EXPIRED',
  'DECLINED',
  'AGREEMENT_REACHED',
  'SIGNED',
  'REJECTED',
]

export const FINAL_COMPLETED_NEGOTIATION_STATUSES: NegotiationStatus[] = [
  'AGREEMENT_REACHED',
  'SIGNED',
]

export const MAX_FILE_SIZE = 1048576 * 10
export const MAX_TOTAL_FILE_SIZE = 5 * MAX_FILE_SIZE // Matches backend expectations

import { NegotiationEventListItem } from '@procurement/store/types'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useNegotiationEventActions } from '@procurement/hooks/useNegotiationEventActions'
import { useGetConfigurationQuery } from '@procurement/store/purchasing'
import { ChangeEvent, useState } from 'react'
import { labelForProduct } from '@procurement/utils/labelForProduct'
import { FormControl, TextField } from '@mui/material'
import { ConfirmationModal } from '@components/ConfirmationModal'
import { NegotiationEventCommonModalProps } from '../types'

type Props = Omit<NegotiationEventCommonModalProps, 'onSubmit'> & {
  negotiationEventListItem: NegotiationEventListItem
}

export const WithdrawConfirmationModal = (props: Props) => {
  const { activeProjectTag } = useActiveProject()
  const { handleNegotiationEventWithdrawAction } = useNegotiationEventActions([
    props.negotiationEventListItem,
  ])
  const { data: configuration } = useGetConfigurationQuery({ projectTag: activeProjectTag })
  const [commentForSupplier, setCommentForSupplier] = useState('')
  const [withdrawConfirmationModalLoading, setWithdrawConfirmationModalLoading] = useState(false)

  const itemName = labelForProduct(configuration?.suite.productType, 'actionItemName')
  const title = `Withdraw ${itemName}?`
  const content = (
    <>
      <p>This requisition will be returned to "Ready" status.</p>
      <p>
        You may optionally provide a comment to suppliers about this withdrawal below. This comment
        will only be shown if the supplier negotiation is ongoing.
      </p>
      <FormControl fullWidth>
        <TextField
          label='Comment'
          variant='outlined'
          multiline={true}
          rows={4}
          fullWidth
          margin='normal'
          value={commentForSupplier}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setCommentForSupplier(e.currentTarget.value)
          }
        />
      </FormControl>
    </>
  )

  const { onCancel: _, ...propsWithoutOnCancel } = props

  const onCancel = () => {
    setCommentForSupplier('')
    props.onCancel()
  }

  const onSubmit = async () => {
    try {
      setWithdrawConfirmationModalLoading(true)
      await handleNegotiationEventWithdrawAction(commentForSupplier)
    } finally {
      onCancel()
      setWithdrawConfirmationModalLoading(false)
    }
  }

  return (
    <ConfirmationModal
      confirmType='negative'
      title={title}
      content={content}
      confirmButtonText='Withdraw'
      onSubmit={onSubmit}
      onCancel={onCancel}
      loading={withdrawConfirmationModalLoading}
      {...propsWithoutOnCancel}
    />
  )
}

import { Box, Link, Typography } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { CommercialTermsNegotiation, FileUpload } from '@campaigns/types/negotiation'
import { DataCell } from './DataCell'
import {
  isBackendApiErrorResponse,
  useLazyGetNegotiationTestLinkQuery,
} from 'src/commercial-terms/store'
import { useSnackbar } from 'notistack'
import { CloudDownload } from '@mui/icons-material'
import {
  getFileSupplierIdentifier,
  useDownloadAttachment,
} from 'src/commercial-terms/utils/attachments'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useBrandedChatLink } from '@shared/hooks/useBrandedChatLink'
import { Ellipsis } from '../../components/Ellipsis'

interface Props {
  negotiation: CommercialTermsNegotiation
}

export const SupplierDetails = ({ negotiation }: Props) => {
  const { activeProjectTag: projectTag } = useActiveProject()
  const [getTestLink] = useLazyGetNegotiationTestLinkQuery()
  const { enqueueSnackbar } = useSnackbar()

  const getNegotiationTestLink = async () => {
    try {
      const { link } = await getTestLink({ projectTag, negotiationId: negotiation.id }).unwrap()
      return link
    } catch (error) {
      const message = isBackendApiErrorResponse(error)
        ? error.data.message
        : (error as Error).message

      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <>
      <Typography variant='h2'>Supplier details</Typography>
      {negotiation.supplierId && (
        <DataCell label='Supplier ID'>
          <Ellipsis>{negotiation.supplierId}</Ellipsis>
        </DataCell>
      )}
      <DataCell label='Supplier name'>
        <Ellipsis>{negotiation.supplierCompanyName}</Ellipsis>
      </DataCell>
      {negotiation.businessUnit && (
        <DataCell label='Business unit'>
          <Ellipsis>{negotiation.businessUnit}</Ellipsis>
        </DataCell>
      )}
      <DataCell label='Supplier email'>
        <Ellipsis>{negotiation.supplierEmail}</Ellipsis>
      </DataCell>
      <DataCell label='Supplier phone'>
        <Ellipsis>{negotiation.supplierPhone}</Ellipsis>
      </DataCell>
      {negotiation.status !== 'MISSING_DATA' && (
        <NegotiationLink negotiation={negotiation} getTestLink={getNegotiationTestLink} />
      )}
      {negotiation.signatures.docusignEnvelopeUrl && (
        <DataCell label='Docusign'>
          <DocusignLink envelopeUrl={negotiation.signatures.docusignEnvelopeUrl} />
        </DataCell>
      )}
      {negotiation.signatures.manualSigner && (
        <>
          <DataCell label='Signer name'>{negotiation.signatures.manualSigner.name}</DataCell>
          <DataCell label='Signer email'>{negotiation.signatures.manualSigner.email}</DataCell>
        </>
      )}
      <Attachments negotiation={negotiation} />
    </>
  )
}

const Attachments = ({ negotiation }: { negotiation: CommercialTermsNegotiation }) => {
  if (!negotiation?.fileUploads?.length) {
    return null
  }

  return (
    <Box mt={3}>
      <Typography variant='h3'>Attachments</Typography>
      {negotiation.fileUploads?.map((fileUpload) => (
        <Attachment key={fileUpload.label} fileUpload={fileUpload} negotiation={negotiation} />
      ))}
    </Box>
  )
}

const Attachment = ({
  fileUpload,
  negotiation,
}: {
  fileUpload: FileUpload
  negotiation: CommercialTermsNegotiation
}) => {
  const downloadAttachment = useDownloadAttachment()
  const { enqueueSnackbar } = useSnackbar()

  const download = async () => {
    if (!fileUpload.fileUploaded || !fileUpload.negotiationFileId) {
      return
    }

    try {
      downloadAttachment(fileUpload, getFileSupplierIdentifier(negotiation) as string)
    } catch (error) {
      if (isBackendApiErrorResponse(error)) {
        enqueueSnackbar(error.data.message, { variant: 'error' })
      }
    }
  }

  if (!fileUpload.fileUploaded) {
    if (fileUpload.failReason.length === 0) {
      return <DataCell label={fileUpload.label}>Not uploaded</DataCell>
    }

    return (
      <DataCell label={fileUpload.label}>
        Not uploaded with feedback:
        <Box sx={{ fontStyle: 'italic' }}>"{fileUpload.failReason}"</Box>
      </DataCell>
    )
  }

  return (
    <DataCell label={fileUpload.label}>
      <Link
        href='#'
        underline='hover'
        onClick={(e) => {
          e.preventDefault()
          download()
        }}
      >
        <CloudDownload sx={{ mr: 0.5, mb: '-1px', color: 'inherit', fontSize: 'inherit' }} />
        Download
      </Link>
    </DataCell>
  )
}

interface NegotiationLinkProps {
  negotiation: CommercialTermsNegotiation
  getTestLink: () => Promise<string | undefined>
}
const NegotiationLink = ({ negotiation, getTestLink }: NegotiationLinkProps) => {
  const { wrapChatLink } = useBrandedChatLink()
  const { label, href } = getLinkLabelAndUrl(negotiation)
  const { activeProjectTag } = useActiveProject()

  if (!href && ['wm_us_gfr', 'wm_us_gfr_ct'].includes(activeProjectTag)) {
    return null
  }

  return (
    <DataCell label='Negotiation'>
      <Link
        onClick={async () => {
          const link = href ?? (await getTestLink())

          if (link) {
            window.open(wrapChatLink(link), '_blank')
          }
        }}
        target='_blank'
        underline='hover'
        sx={{ cursor: 'pointer' }}
      >
        <OpenInNewIcon sx={{ mr: 0.5, color: 'inherit', fontSize: 'inherit' }} />
        {label}
      </Link>
    </DataCell>
  )
}

const DocusignLink = ({ envelopeUrl }: { envelopeUrl: string }) => {
  return (
    <Link href={envelopeUrl} target='_blank' underline='hover' sx={{ cursor: 'pointer' }}>
      <OpenInNewIcon sx={{ mr: 0.5, color: 'inherit', fontSize: 'inherit' }} />
      DocuSign envelope
    </Link>
  )
}

const getLinkLabelAndUrl = (negotiation: CommercialTermsNegotiation) => {
  if (['STARTED', 'NOT_COMPLETED'].includes(negotiation.status)) {
    return {
      label: 'View negotiation progress',
      href: negotiation.readOnlyChatLink,
    }
  } else if (['DEAL_REACHED', 'NO_AGREEMENT', 'SIGNED'].includes(negotiation.status)) {
    return {
      label: 'View finished negotiation',
      href: negotiation.readOnlyChatLink,
    }
  }
  return {
    label: 'Try out negotiation',
    href: undefined,
  }
}

// nego-lib does not export this type explicitly
import { TDateISODate } from '@pactum/negotiation-sdk/dist/types/TDateISODate'

export const getTodayAsDate = (): Date => new Date()

export const getYesterdayAsDate = (): Date => {
  const date = getTodayAsDate()
  date.setDate(date.getDate() - 1)
  return date
}

export const toModelIsoDate = (date: Date): TDateISODate =>
  new Date(date).toISOString().slice(0, 10) as TDateISODate

export const dateOrNull = (date: string | null): Date | null => (date ? new Date(date) : null)

export const getLastYear = (): number => new Date().getFullYear() - 1

import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useGetConfigurationQuery } from '@procurement/store/purchasing'
import { labelForProduct } from '@procurement/utils/labelForProduct'
import pluralize from 'pluralize'
import { ConfirmationModal } from '@components/ConfirmationModal'
import { NegotiationEventCommonModalProps } from '../types'

export const DeleteConfirmationModal = ({
  selectedNegotiationEvents = 1,
  actionableNegotiationEvents,
  ...props
}: NegotiationEventCommonModalProps) => {
  const { activeProjectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({ projectTag: activeProjectTag })
  const itemName = labelForProduct(configuration?.suite.productType, 'actionItemName')
  const title = `Delete ${pluralize(itemName, selectedNegotiationEvents)}?`
  const content =
    selectedNegotiationEvents > 1
      ? selectedNegotiationEvents === actionableNegotiationEvents
        ? `${selectedNegotiationEvents} selected ${pluralize(itemName, selectedNegotiationEvents)} will be deleted. `
        : `${actionableNegotiationEvents} out of ${selectedNegotiationEvents} selected ${pluralize(
            itemName,
            selectedNegotiationEvents,
          )} will be deleted. `
      : ''

  return (
    <ConfirmationModal
      confirmType='negative'
      title={title}
      content={content + 'This action can not be undone.'}
      confirmButtonText='Delete'
      {...props}
    />
  )
}

import { Link, Typography } from '@mui/material'
import { DataCell } from '../DataCell/DataCell'
import { NegotiationEventDto } from '@pactum/price-list-types'

interface Props {
  negotiationEvent: NegotiationEventDto
}

export const BasicAttributes = ({ negotiationEvent }: Props) => {
  return (
    <>
      <Typography variant='h2'>Negotiation details</Typography>
      <DataCell label='Title'>{negotiationEvent.title}</DataCell>
      <DataCell label='Negotiation external ID'>{negotiationEvent.externalId}</DataCell>
      <DataCell label='Buyer'>
        <Typography display='block' variant='normal'>
          {negotiationEvent.buyerContactName}
        </Typography>
        <Typography display='block' variant='normal'>
          {negotiationEvent.buyerBusinessUnitName}
        </Typography>
        <Link display='block' href={`mailto:${negotiationEvent.buyerContactEmail}`}>
          <Typography variant='normal'>{negotiationEvent.buyerContactEmail}</Typography>
        </Link>
      </DataCell>
    </>
  )
}

import { useGetChangelogQuery } from '@store/changelog'
import { differenceInDays } from 'date-fns/esm'

const CHANGELOG_READ_TIME_KEY = 'lastChangeLogReadTime'
const CHANGELOG_MAX_DAYS = 45

interface HasNewChangelogs {
  hasNewChangelogs: boolean
  updateReadTime: () => void
}

export const useHasNewChangelogs = (): HasNewChangelogs => {
  const lastChangeLogReadTime = localStorage.getItem(CHANGELOG_READ_TIME_KEY)
  const lastChangeLogReadTimeDiff = differenceInDays(
    new Date(),
    lastChangeLogReadTime ? new Date(lastChangeLogReadTime) : new Date(),
  )

  const changelog = useGetChangelogQuery(undefined, {
    skip: lastChangeLogReadTime !== null && lastChangeLogReadTimeDiff < CHANGELOG_MAX_DAYS,
  })

  const beforeDate = changelog?.data?.[0]?.date
    ? new Date(changelog?.data?.[0]?.date)
    : lastChangeLogReadTime
      ? new Date(lastChangeLogReadTime)
      : new Date()

  const hasNewChangelogs =
    lastChangeLogReadTime === null
      ? differenceInDays(new Date(), beforeDate) < CHANGELOG_MAX_DAYS
      : false

  const updateReadTime = () => {
    localStorage.setItem(CHANGELOG_READ_TIME_KEY, new Date().toString())
  }

  return {
    hasNewChangelogs,
    updateReadTime,
  }
}

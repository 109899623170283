import { Field } from './Field'
import { Root, useFormConfig } from './Root'
import { Section, SectionContent, SectionDescription, SectionTitle } from './Section'

export const Form = {
  Field,
  Root,
  Section,
  SectionContent,
  SectionDescription,
  SectionTitle,
}

export { useFormConfig }

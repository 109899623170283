import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useGetConfigurationQuery } from '@procurement/store/purchasing'
import { labelForProduct } from '@procurement/utils/labelForProduct'
import pluralize from 'pluralize'
import { ConfirmationModal } from '@components/ConfirmationModal'
import { NegotiationEventCommonModalProps } from '../types'

export const DownloadConfirmationModal = ({
  selectedNegotiationEvents = 1,
  ...props
}: NegotiationEventCommonModalProps) => {
  const { activeProjectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({ projectTag: activeProjectTag })
  const itemName = labelForProduct(configuration?.suite.productType, 'actionItemName')
  const title = `Download results`
  const content = `Download results for ${selectedNegotiationEvents} selected ${pluralize(itemName, selectedNegotiationEvents)}`

  return (
    <ConfirmationModal
      confirmType='positive'
      title={title}
      content={content}
      confirmButtonText='Download'
      {...props}
    />
  )
}

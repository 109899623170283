import React, { useState } from 'react'
import { HeaderPrimaryButton, useLayoutContext } from '@components/Layout'
import {
  NegotiationEventAction,
  useNegotiationEventActions,
} from '@price-list/hooks/useNegotiationEventActions'
import { APP_HEADER_HEIGHT } from '@constants'
import { InnerContainer } from '@components/Layout/Containers'
import { Grid, Stack, styled, Typography } from '@mui/material'
import { NegotiationEventStatusChip } from '@price-list/components/NegotiationEventStatusChip/NegotiationEventStatusChip'
import { StartNegotiationConfirmationModal } from '@price-list/components/StartNegotiationConfirmationModal/StartNegotiationConfirmationModal'

export const PriceListNegotiationDetailsHeader = () => {
  const { priceListNegotiationEvent: negotiationEvent } = useLayoutContext()

  const [startConfirmationModalOpen, setStartConfirmationModalOpen] = useState(false)
  const [isStartNegotiationsLoading, setIsStartNegotiationsLoading] = useState(false)

  const { availableActions, handleNegotiationEventStartAction } = useNegotiationEventActions(
    negotiationEvent ? [negotiationEvent] : [],
  )

  if (!negotiationEvent) {
    return null
  }

  const handleStartConfirmation = async () => {
    if (negotiationEvent) {
      try {
        setIsStartNegotiationsLoading(true)
        await handleNegotiationEventStartAction()
      } finally {
        setIsStartNegotiationsLoading(false)
      }
    }
    setStartConfirmationModalOpen(false)
  }

  const hasStartAction =
    availableActions.includes(NegotiationEventAction.START) && !negotiationEvent.startedAt

  return (
    <>
      <OuterContainer container>
        <InnerContainer>
          <Stack alignItems='center' direction='row' gap={2}>
            <Stack
              alignItems='inherit'
              direction='row'
              flexGrow={1}
              gap='inherit'
              overflow='hidden'
            >
              <Title lineHeight='2.5rem' noWrap variant='h1'>
                {negotiationEvent.title}
              </Title>
              <NegotiationEventStatusChip
                status={negotiationEvent.status}
                size='large'
                variant='inverted'
              />
            </Stack>
            {hasStartAction && (
              <HeaderPrimaryButton onClick={() => setStartConfirmationModalOpen(true)}>
                Start negotiation
              </HeaderPrimaryButton>
            )}
          </Stack>
        </InnerContainer>
      </OuterContainer>
      <StartNegotiationConfirmationModal
        open={startConfirmationModalOpen}
        onSubmit={handleStartConfirmation}
        loading={isStartNegotiationsLoading}
        onCancel={() => setStartConfirmationModalOpen(false)}
      />
    </>
  )
}

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
}))

const OuterContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(4),
  backgroundColor: theme.palette.primary.main,
  marginTop: `calc(${APP_HEADER_HEIGHT}px - 0.5px)`,
  zIndex: theme.zIndex.secondaryHeader,
  position: 'sticky',
}))

import { useWatch } from 'react-hook-form'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid-pro'
import { Checkbox, Typography } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import z from 'zod'
import { DataGrid, dataGridProps } from '@components/table/DataGrid'
import { LocalAttachment, localAttachmentSchema } from '@procurement/store/schema'

interface AttachmentListProps {
  name: string
  onRemove: (index: number) => void
  onUpdate: (index: number, attachment: LocalAttachment) => void
}

export function AttachmentList({ name, onRemove, onUpdate }: AttachmentListProps) {
  const value = useWatch<Record<string, unknown>>({ name })
  const attachments = z.array(localAttachmentSchema).parse(value)

  const toggleSupplierVisibility = (fileName: string) => {
    const attachmentIndexToUpdate = getAttachmentIndexByFileName(attachments, fileName)
    const attachmentToUpdate = attachments[attachmentIndexToUpdate]

    if (attachmentToUpdate) {
      onUpdate(attachmentIndexToUpdate, {
        ...attachmentToUpdate,
        isVisibleToSupplier: !attachmentToUpdate.isVisibleToSupplier,
      })
    }
  }

  const handleFileDelete = (fileName: string) => {
    onRemove(getAttachmentIndexByFileName(attachments, fileName))
  }

  const commonColumnProps = {
    disableColumnMenu: true,
    editable: false,
    sortable: false,
  }

  const columns: GridColDef<LocalAttachment>[] = [
    {
      ...commonColumnProps,
      field: 'isVisibleToSupplier',
      type: 'boolean',
      headerName: 'Show to supplier(s)',
      flex: 1.25,
      align: 'center',
      renderCell: ({ row }) => (
        <Checkbox
          checked={row.isVisibleToSupplier}
          onChange={() => toggleSupplierVisibility(row.file.name)}
          onClick={(event) => event.stopPropagation()}
        />
      ),
    },
    {
      ...commonColumnProps,
      field: 'fileName',
      type: 'string',
      headerName: 'Available documents',
      flex: 3,
      renderCell: ({ row }) => <Typography noWrap>{row.file.name}</Typography>,
    },
    {
      ...commonColumnProps,
      field: '__actions__',
      type: 'actions',
      flex: 1,
      align: 'right',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label='Delete'
          icon={<DeleteOutlineIcon />}
          onClick={() => handleFileDelete(row.file.name)}
        />,
      ],
    },
  ]

  return (
    <DataGrid
      {...dataGridProps}
      columns={columns}
      rows={[...attachments]}
      getRowId={(row: LocalAttachment) => row.file.name}
      localeText={{
        noRowsLabel: 'No attachments',
      }}
      onRowClick={({ row }: GridRowParams<LocalAttachment>) => {
        toggleSupplierVisibility(row.file.name)
      }}
    />
  )
}

const getAttachmentIndexByFileName = (attachments: LocalAttachment[], fileName: string) => {
  return attachments.findIndex((attachment) => attachment.file.name === fileName)
}

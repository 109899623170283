import { assertNever } from '@common/utils/validation'
import { ProductType } from '@pactum/core-backend-types'

export const changelogProductType = [
  'CAMPAIGNS',
  'PROCUREMENT',
  'DIRECT_MATERIALS_PROCUREMENT',
  'LOGISTICS',
  'RETAIL',
  'BESPOKE',
] as const

export type ChangelogProductType = (typeof changelogProductType)[number]

export const changeLogProducts: Record<ChangelogProductType, string> = {
  CAMPAIGNS: 'Campaigns',
  PROCUREMENT: 'Procurement',
  DIRECT_MATERIALS_PROCUREMENT: 'Direct Materials Procurement',
  LOGISTICS: 'Logistics',
  RETAIL: 'Retail',
  BESPOKE: 'Bespoke',
}

export const mapToChangelogProduct = (t: ProductType) => {
  switch (t) {
    case ProductType.Purchasing:
      return changeLogProducts.PROCUREMENT
    case ProductType.Logistics:
      return changeLogProducts.LOGISTICS
    case ProductType.CommercialTermsPaymentTerms:
      return changeLogProducts.CAMPAIGNS
    case ProductType.ContractCost:
      return changeLogProducts.PROCUREMENT
    case ProductType.DirectMaterialsPriceList:
      return changeLogProducts.DIRECT_MATERIALS_PROCUREMENT
    case ProductType.RetailCostAvoidance:
      return changeLogProducts.RETAIL
    case ProductType.RetailCostDecrease:
      return changeLogProducts.RETAIL
    case ProductType.Bespoke:
      return changeLogProducts.BESPOKE
    default:
      assertNever(t)
  }
}

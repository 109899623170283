import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useSupplierLineItemsListQuery } from '../../store/price-list'
import { useSuiteDataGrid } from '@components/SuiteDataGrid/SuiteDataGridProvider'
import { PactumLoader } from '@components/PactumLoader'
import { Alert, styled, Typography } from '@mui/material'
import { DataGridPro, GridColDef, GridFeatureMode } from '@mui/x-data-grid-pro'
import { DataGrid } from '@components/table'
import { NegotiationEventSupplierLineItemDto } from '@pactum/price-list-types'

type Props = {
  negotiationEventId: number
  supplierId: number
}

const LineItemGridColumns: GridColDef<NegotiationEventSupplierLineItemDto>[] = [
  {
    field: 'id',
    headerName: '#',
    flex: 0.1,
  },
  {
    field: 'externalId',
    headerName: 'External id',
    flex: 0.3,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 0.3,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 0.5,
  },
  {
    field: 'unitOfMeasure',
    headerName: 'Unit',
    flex: 0.2,
  },
  {
    field: 'price.current',
    headerName: 'Current Price',
    flex: 0.2,
    valueGetter: (params: { row: NegotiationEventSupplierLineItemDto }) =>
      params.row.price?.current,
  },
  {
    field: 'price.agreed',
    headerName: 'Agreed Price',
    flex: 0.2,
    valueGetter: (params: { row: NegotiationEventSupplierLineItemDto }) =>
      params.row.price?.agreed ?? '-',
  },
  {
    field: 'currency',
    headerName: 'Currency',
    flex: 0.2,
  },
  {
    field: 'price.type',
    headerName: 'Price type',
    flex: 0.2,
    valueGetter: (params: { row: NegotiationEventSupplierLineItemDto }) => params.row.price?.type,
  },
]

export const NegotiationEventLineItemsPage = ({ negotiationEventId, supplierId }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const gridState = useSuiteDataGrid({
    defaultPageSize: 10,
    sort: [{ field: 'createdAt', sort: 'desc' }],
  })

  const {
    data: supplierLineItemsResults,
    isLoading,
    isError,
    error,
  } = useSupplierLineItemsListQuery({
    projectTag: activeProjectTag,
    negotiationEventId: negotiationEventId,
    supplierId: supplierId,
    page: gridState.pagination.page,
    pageSize: gridState.pagination.pageSize,
    withDeleted: true,
  })

  if (isLoading) {
    return <PactumLoader />
  }

  if (isError) {
    return <Alert severity='error'>Error: {JSON.stringify(error)}</Alert>
  }

  const lineItems = supplierLineItemsResults?.items || []
  const totalCount = supplierLineItemsResults?.total || 0

  const paginationOptions = {
    pagination: true,
    paginationMode: 'server' as GridFeatureMode,
    page: gridState.pagination.page,
    pageSize: gridState.pagination.pageSize,
    onPageChange: (page: number) => gridState.setPagination({ ...gridState.pagination, page }),
    onPageSizeChange: (pageSize: number) =>
      gridState.setPagination({ ...gridState.pagination, pageSize }),
    rowsPerPageOptions: [10, 25, 50, 100],
  }
  return (
    <>
      <Typography variant='h3' sx={{ mt: 4 }}>
        Materials price list
      </Typography>
      <StyledDataGrid
        autoHeight
        rows={lineItems}
        disableColumnFilter
        disableDensitySelector
        disableRowSelectionOnClick
        rowCount={totalCount}
        columns={LineItemGridColumns}
        getRowId={(row) => row.id}
        sx={{
          '.MuiDataGrid-row': {
            cursor: 'pointer',
          },
        }}
        {...paginationOptions}
      />
    </>
  )
}

const StyledDataGrid = styled(DataGrid)(() => ({
  ' .MuiDataGrid-columnHeader .MuiBadge-badge': {
    display: 'none',
  },
  ' .select-cell, .select-header': {
    opacity: 0,
  },
  ' .MuiDataGrid-row:hover .select-cell': {
    opacity: 1,
  },
  ' .MuiDataGrid-columnHeaders:hover .select-header': {
    opacity: 1,
  },
  '&.show-selection': {
    ' .select-cell, .select-header': {
      opacity: 1,
    },
  },
})) as typeof DataGridPro

import React from 'react'
import { Stack, TextareaAutosize } from '@mui/material'
import { useCreateNegotiationEventMutation } from '../../store/price-list'
import { useSnackbar } from 'notistack'
import { NegotiationEventJsonFormInput, negotiationEventJsonFormSchema } from './schema'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { FormDialog } from '@components/FormDialog'
import { NegotiationEventInputDto } from '@pactum/price-list-types'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const CreateNegotiationEvent = ({ isOpen, handleClose }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const [createNegotiationEvent, { isLoading: isCreatingNegotiationEvent }] =
    useCreateNegotiationEventMutation()

  const { enqueueSnackbar } = useSnackbar()

  const form = useForm<NegotiationEventJsonFormInput>({
    resolver: zodResolver(negotiationEventJsonFormSchema),
  })

  const { reset, control } = form

  const createEvent = async (input: NegotiationEventJsonFormInput) => {
    try {
      await createNegotiationEvent({
        projectTag: activeProjectTag,
        negotiationEventInput: JSON.parse(input.data) as NegotiationEventInputDto,
      }).unwrap()

      enqueueSnackbar('Negotiation created', { variant: 'success', persist: false })

      reset()
      handleClose()
    } catch (error: unknown) {
      enqueueSnackbar(
        `Error creating negotiation: ${(error as { data: { message: string } })?.data?.message}`,
        {
          variant: 'error',
          persist: false,
        },
      )
    }
  }

  return (
    <FormDialog
      form={form}
      open={isOpen}
      loading={isCreatingNegotiationEvent}
      title='Create new negotiation'
      fullWidth
      maxWidth='sm'
      buttons={[
        { type: 'submit', label: 'Create' },
        { type: 'cancel', label: 'Cancel' },
      ]}
      onSubmit={createEvent}
      onCancel={handleClose}
    >
      <FormProvider {...form}>
        <Stack direction='column' gap={3} ml={1}>
          <Controller
            name='data'
            control={control}
            rules={{ required: true }}
            render={({ field }) => <TextareaAutosize minRows={10} {...field} />}
          />
        </Stack>
      </FormProvider>
    </FormDialog>
  )
}

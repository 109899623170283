import { Box, Link, styled, Typography } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid-pro'
import { QuickFilter } from '@components/QuickFilter'
import FileUploadIcon from '@mui/icons-material/FileUpload'

interface Props {
  onOpenUploadDialog?: () => void
}

export const Toolbar = ({ onOpenUploadDialog }: Props) => {
  return (
    <ToolbarContainer>
      <Typography variant='subtitle1'>Negotiations</Typography>
      <Separator />
      {onOpenUploadDialog && (
        <UploadLink onClick={onOpenUploadDialog} href='#' variant='normal'>
          <FileUploadIcon fontSize='small' /> Upload
        </UploadLink>
      )}
      <QuickFilter placeholder={'Search negotiations'} />
    </ToolbarContainer>
  )
}

const UploadLink = styled(Link)(({ theme }) => ({
  '.MuiSvgIcon-root': {
    verticalAlign: 'middle',
  },
  fontSize: '12px',
  fontWeight: 500,
  paddingRight: theme.spacing(1),
  color: theme.palette.darkGrey.main,
}))

const ToolbarContainer = styled(GridToolbarContainer)(({ theme }) => ({
  marginBottom: theme.spacing(3.5),
  marginLeft: '2px',
}))

const Separator = styled(Box)({ flex: 1 })

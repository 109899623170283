import { NavigationItem } from 'src/main/types/suite-module'
import DashboardIcon from '@mui/icons-material/Dashboard'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import { SuitePermission } from '@common/types'

export enum PriceListRoutes {
  DASHBOARD = '/:orgTag/price-list/:projectTag/dashboard',
  NEGOTIATIONS = '/:orgTag/price-list/:projectTag/negotiations',
  NEGOTIATION_DETAILS = '/:orgTag/price-list/:projectTag/negotiations/:negotiationEventId',
}

export const priceListNavigationItems: NavigationItem[] = [
  {
    name: 'Negotiations',
    icon: () => <MenuBookIcon />,
    path: (activeOrgTag, activeProjectTag) =>
      `/${activeOrgTag}/price-list/${activeProjectTag}/negotiations`,
    requiredPermission: SuitePermission.MODIFY,
  },
  {
    name: 'Dashboard',
    icon: () => <DashboardIcon />,
    path: (activeOrgTag, activeProjectTag) =>
      `/${activeOrgTag}/price-list/${activeProjectTag}/dashboard`,
    requiredPermission: SuitePermission.READ,
  },
]

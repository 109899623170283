import React from 'react'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useGetPactNegotiationUnitsQuery } from '@store/pact'
import { isBackendHttpError } from '../../types/http-errors'
import { ErrorPage } from '@pages/ErrorPage'
import { LoadingPage } from '@pages/LoadingPage'
import { Box } from '@mui/material'
import { LAYOUT_CONTAINER_X_PADDING } from '@constants'
import { CONTENT_TITLE_PADDING } from '../../constants/layout'
import { useParams } from 'react-router-dom'
import { NegotiationUnits } from '@pages/NegotiationUnitsPage/NegotiationUnits'
import { Routes } from '../../../suite/routes'

export const NegotiationUnitsPage = ({
  parentRoute = Routes.NEGOTIATION_LIST,
}: {
  parentRoute?: string
}) => {
  const { activeProjectTag: projectTag } = useActiveProject()
  const { negotiationId } = useParams()

  const { data, isLoading, error } = useGetPactNegotiationUnitsQuery({
    projectTag,
    negotiationId: Number(negotiationId),
  })

  if (isBackendHttpError(error)) {
    return (
      <ErrorPage title='Error loading negotiation units'>
        <pre>{error.data.message}</pre>
      </ErrorPage>
    )
  }
  if (isLoading || !data) {
    return <LoadingPage />
  }

  return (
    <Box pr={LAYOUT_CONTAINER_X_PADDING} pt={CONTENT_TITLE_PADDING} pb={4}>
      <NegotiationUnits negotiationUnits={data} parentRoute={parentRoute} />
    </Box>
  )
}

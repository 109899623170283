import {
  useDeleteNegotiationEventMutation,
  useGetConfigurationQuery,
  useLazyExportNegotiationEventsQuery,
  useStartNegotiationsMutation,
  useWithdrawNegotiationEventMutation,
} from '../store/purchasing'

import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { downloadFile } from '@utils'
import { useSnackbar } from 'notistack'
import pluralize from 'pluralize'
import { PurchasingUIConfig, NegotiationEventListItem, NegotiationStatus } from '../store/types'
import { labelForProduct } from '../utils/labelForProduct'

export const enum NegotiationEventAction {
  START = 'START',
  DELETE = 'DELETE',
  WITHDRAW = 'WITHDRAW',
  DOWNLOAD = 'DOWNLOAD',
  EDIT = 'EDIT',
}

export const useNegotiationEventActions = (
  negotiationEventListItems: NegotiationEventListItem[],
) => {
  const [startNegotiations] = useStartNegotiationsMutation()
  const [deleteNegotiationEvent] = useDeleteNegotiationEventMutation()
  const [withdrawNegotiationEvent] = useWithdrawNegotiationEventMutation()
  const [downloadNegotiationEvents] = useLazyExportNegotiationEventsQuery()
  const { enqueueSnackbar } = useSnackbar()
  const { activeProjectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({
    projectTag: activeProjectTag,
  })
  const itemName = labelForProduct(configuration?.suite.productType, 'actionItemName')
  const projectTag = negotiationEventListItems[0]?.projectTag

  const availableActions: NegotiationEventAction[] = [
    ...new Set(negotiationEventListItems.flatMap((it) => getAvailableActions(it, configuration))),
  ]

  const getNegotiationEventsForAction = (action: NegotiationEventAction) =>
    negotiationEventListItems.filter((it) =>
      getAvailableActions(it, configuration).includes(action),
    )

  const handleNegotiationEventDeleteAction = async () => {
    const negotiationEventIds = getNegotiationEventsForAction(NegotiationEventAction.DELETE).map(
      ({ id }) => id,
    )
    try {
      await deleteNegotiationEvent({
        projectTag,
        requisitionIds: negotiationEventIds,
      }).unwrap()
      enqueueSnackbar(`${pluralize(itemName, negotiationEventIds.length, true)} deleted`, {
        variant: 'success',
      })
    } catch (error) {
      setError(error, `Error occurred while deleting ${itemName}`)
    }
  }

  const handleNegotiationEventWithdrawAction = async (commentForSupplier: string) => {
    const negotiationEventIds = getNegotiationEventsForAction(NegotiationEventAction.WITHDRAW).map(
      ({ id }) => id,
    )
    if (negotiationEventIds.length > 1) {
      throw new Error('Patch withdrawing not supported')
    }
    const [requisitionId] = negotiationEventIds
    try {
      await withdrawNegotiationEvent({
        projectTag,
        requisitionId,
        commentForSupplier,
      }).unwrap()
      enqueueSnackbar(`${pluralize(itemName, negotiationEventIds.length, true)} withdrawn`, {
        variant: 'success',
      })
    } catch (error) {
      setError(error, `Error occurred while withdrawing ${itemName}`)
    }
  }

  const handleNegotiationEventStartAction = async () => {
    const negotiationEventIds = getNegotiationEventsForAction(NegotiationEventAction.START).map(
      ({ id }) => id,
    )
    try {
      await startNegotiations({
        projectTag,
        requisitionIds: negotiationEventIds,
      }).unwrap()
      enqueueSnackbar(
        `Negotiations started for ${pluralize(itemName, negotiationEventIds.length, true)}`,
        {
          variant: 'success',
        },
      )
    } catch (error) {
      setError(error, 'Error occurred while starting negotiations')
    }
  }

  const handleNegotiationEventsDownloadAction = async () => {
    const negotiationEventIds = getNegotiationEventsForAction(NegotiationEventAction.DOWNLOAD).map(
      ({ id }) => id,
    )

    try {
      const exportResult = await downloadNegotiationEvents({
        projectTag,
        requisitionIds: negotiationEventIds,
      }).unwrap()

      downloadFile(exportResult.filename, exportResult.fileContent)
    } catch (error) {
      setError(error, 'Error occurred while downloading results')
    }
  }

  const setError = (error: unknown, message: string) => {
    const errorMessage = isBackendApiErrorResponse(error) ? error.data.message : message
    enqueueSnackbar(errorMessage, { variant: 'error' })
  }

  return {
    availableActions,
    getNegotiationEventsForAction: getNegotiationEventsForAction,
    handleNegotiationEventDeleteAction: handleNegotiationEventDeleteAction,
    handleNegotiationEventWithdrawAction: handleNegotiationEventWithdrawAction,
    handleNegotiationEventStartAction: handleNegotiationEventStartAction,
    handleNegotiationEventsDownloadAction: handleNegotiationEventsDownloadAction,
  }
}

const getAvailableActions = (
  { negotiationStatus, negotiationsStartedAt }: NegotiationEventListItem,
  configuration?: PurchasingUIConfig,
) => {
  const actions: NegotiationEventAction[] = []
  const isReadonly = configuration?.suite.isReadonly

  if (isReadonly) {
    return [NegotiationEventAction.DOWNLOAD]
  }

  if (negotiationStatus === 'READY' || negotiationStatus === 'WITHDRAWN') {
    actions.push(NegotiationEventAction.START)
  }

  const withdrawableStatuses: NegotiationStatus[] = ['IN_PROGRESS', 'EXPIRED', 'DECLINED']

  if (
    withdrawableStatuses.includes(negotiationStatus) &&
    configuration?.suite?.showWithdrawRequisitionButton
  ) {
    actions.push(NegotiationEventAction.WITHDRAW)
  }

  if (!negotiationsStartedAt) {
    if (configuration?.suite?.showEditRequisitionButton) {
      actions.push(NegotiationEventAction.EDIT)
    }

    if (!configuration?.suite?.hideDeleteRequisitionButton) {
      actions.push(NegotiationEventAction.DELETE)
    }
  }

  actions.push(NegotiationEventAction.DOWNLOAD)

  return actions
}

import { ProjectTag } from '@common/types'
import { purchasingBaseApi } from '@shared/api/purchasing-base-api'
import {
  CreateNegotiationEventSupplierDto,
  PurchasingUIConfig,
  NegotiationEvent,
  NegotiationEventAttachment,
  NegotiationEventListItem,
  NegotiationEventSupplier,
  UpdateNegotiationEventSupplierDto,
} from './types'
import {
  filesToFormData,
  fileToFormData,
  getFilenameFromHeaders,
  responseAsObjectUrl,
} from '../../main/utils/file'
import { ProductType } from '@pactum/core-backend-types'

interface DownloadAttachmentResponse {
  fileContent: string
  filename: string
}

const productTypePathSegmentMap = {
  [ProductType.ContractCost]: 'contract-cost',
  [ProductType.Purchasing]: 'purchasing',
}

export const purchasingApi = purchasingBaseApi('purchasing-api')
  .enhanceEndpoints({ addTagTypes: ['NegotiationEvent', 'NegotiationEventListItems'] })
  .injectEndpoints({
    endpoints: (build) => ({
      downloadNegotiationEventTemplate: build.query<
        DownloadAttachmentResponse,
        { productType: ProductType.ContractCost | ProductType.Purchasing; projectTag: ProjectTag }
      >({
        query: ({ productType, projectTag }) => ({
          url: `/requisitions/${projectTag}/${productTypePathSegmentMap[productType]}/template`,
          responseHandler: async (response): Promise<DownloadAttachmentResponse> => ({
            fileContent: (await responseAsObjectUrl(response)) as string,
            filename: getFilenameFromHeaders(response),
          }),
          cache: 'no-cache',
        }),
      }),
      exportNegotiationEvents: build.query<
        DownloadAttachmentResponse,
        { projectTag: ProjectTag; requisitionIds: number[] }
      >({
        query: ({ projectTag, requisitionIds }) => ({
          url: `/requisitions/${projectTag}/export`,
          method: 'POST',
          body: {
            requisitionIds,
          },
          responseHandler: async (response): Promise<DownloadAttachmentResponse> => ({
            fileContent: (await responseAsObjectUrl(response)) as string,
            filename: getFilenameFromHeaders(response),
          }),
          cache: 'no-cache',
        }),
      }),
      importNegotiationEvents: build.mutation<
        void,
        {
          file: Blob
          productType: ProductType.ContractCost | ProductType.Purchasing
          projectTag: ProjectTag
        }
      >({
        query: ({ file, productType, projectTag }) => {
          return {
            url: `/requisitions/${projectTag}/${productTypePathSegmentMap[productType]}/import`,
            method: 'POST',
            body: fileToFormData(file),
          }
        },
        invalidatesTags: ['NegotiationEventListItems'],
      }),
      importCoupaRequisition: build.mutation<
        void,
        {
          projectTag: ProjectTag
          requisitionId: number
        }
      >({
        query: ({ projectTag, requisitionId }) => ({
          url: `coupa/import/${projectTag}/requisition/${requisitionId}`,
          method: 'POST',
        }),
        invalidatesTags: (_, error) => (error ? [] : ['NegotiationEventListItems']),
      }),
      importCoupaSourcingEvent: build.mutation<
        void,
        {
          projectTag: ProjectTag
          sourcingEventId: string
        }
      >({
        query: ({ projectTag, sourcingEventId }) => ({
          url: `coupa/import/${projectTag}/sourcing-event/${sourcingEventId}`,
          method: 'POST',
        }),
        invalidatesTags: (_, error) => (error ? [] : ['NegotiationEventListItems']),
      }),
      startNegotiations: build.mutation<void, { projectTag: ProjectTag; requisitionIds: number[] }>(
        {
          query: ({ projectTag, requisitionIds }) => ({
            url: `/requisitions/${projectTag}/start`,
            method: 'POST',
            body: {
              requisitionIds,
            },
          }),
          invalidatesTags: ['NegotiationEvent', 'NegotiationEventListItems'],
        },
      ),
      deleteNegotiationEvent: build.mutation<
        void,
        { projectTag: ProjectTag; requisitionIds: number[] }
      >({
        query: ({ projectTag, requisitionIds }) => ({
          url: `/requisitions/${projectTag}`,
          method: 'DELETE',
          body: {
            requisitionIds,
          },
        }),
        invalidatesTags: ['NegotiationEventListItems'],
      }),
      withdrawNegotiationEvent: build.mutation<
        void,
        { projectTag: ProjectTag; requisitionId: number; commentForSupplier: string }
      >({
        query: ({ projectTag, requisitionId, commentForSupplier }) => ({
          url: `/requisitions/${projectTag}/${requisitionId}/withdraw`,
          method: 'POST',
          body: {
            commentForSupplier,
          },
        }),
        invalidatesTags: ['NegotiationEvent', 'NegotiationEventListItems'],
      }),
      getNegotiationEventListItems: build.query<
        { data: NegotiationEventListItem[] },
        { projectTag: ProjectTag }
      >({
        query: ({ projectTag }) => ({
          url: `/requisitions/${projectTag}/list-items`,
          method: 'GET',
        }),
        providesTags: ['NegotiationEventListItems'],
      }),
      getSingleNegotiationEvent: build.query<
        NegotiationEvent,
        { projectTag: ProjectTag; negotiationEventId: number }
      >({
        query: ({ projectTag, negotiationEventId }) => ({
          url: `/requisitions/${projectTag}/${negotiationEventId}`,
          method: 'GET',
          cache: 'reload',
        }),
        providesTags: (_, _error, args) => [
          { type: 'NegotiationEvent', id: args.negotiationEventId },
        ],
        transformResponse: (response: { data: NegotiationEvent }) => response.data,
      }),
      createNegotiationEventSupplier: build.mutation<
        NegotiationEventSupplier,
        {
          createNegotiationEventSupplierDto: CreateNegotiationEventSupplierDto
          negotiationEventId: number
          projectTag: ProjectTag
        }
      >({
        query: ({ createNegotiationEventSupplierDto, negotiationEventId, projectTag }) => ({
          url: `/requisitions/${projectTag}/${negotiationEventId}/suppliers`,
          method: 'POST',
          body: createNegotiationEventSupplierDto,
        }),
        invalidatesTags: (_, error, args) => {
          if (error) return []
          return [
            { type: 'NegotiationEvent', id: args.negotiationEventId },
            'NegotiationEventListItems',
          ]
        },
      }),
      updateNegotiationEventSupplier: build.mutation<
        NegotiationEventSupplier,
        {
          negotiationEventId: number
          projectTag: ProjectTag
          supplierId: number
          updateNegotiationEventSupplierDto: UpdateNegotiationEventSupplierDto
        }
      >({
        query: ({
          negotiationEventId,
          projectTag,
          supplierId,
          updateNegotiationEventSupplierDto,
        }) => ({
          url: `/requisitions/${projectTag}/${negotiationEventId}/suppliers/${supplierId}`,
          method: 'PUT',
          body: updateNegotiationEventSupplierDto,
        }),
        invalidatesTags: (_, error, args) => {
          if (error) return []
          return [
            {
              type: 'NegotiationEvent',
              id: args.negotiationEventId,
            },
            'NegotiationEventListItems',
          ]
        },
      }),
      deleteNegotiationEventSupplier: build.mutation<
        void,
        {
          projectTag: ProjectTag
          requisitionId: number
          supplierId: number
        }
      >({
        query: ({ projectTag, requisitionId, supplierId }) => ({
          url: `/requisitions/${projectTag}/${requisitionId}/suppliers/${supplierId}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, error, args) => {
          if (error) return []
          return [{ type: 'NegotiationEvent', id: args.requisitionId }, 'NegotiationEventListItems']
        },
      }),
      createNegotiationEvent: build.mutation<
        NegotiationEvent,
        { negotiationEventData: unknown; projectTag: ProjectTag }
      >({
        query: ({ negotiationEventData, projectTag }) => ({
          url: `/requisitions/${projectTag}`,
          method: 'POST',
          body: negotiationEventData,
        }),
        invalidatesTags: (_, error) =>
          error ? [] : ['NegotiationEvent', 'NegotiationEventListItems'],
        transformResponse: (response: { data: NegotiationEvent }) => response.data,
      }),
      updateNegotiationEvent: build.mutation<
        void,
        { projectTag: ProjectTag; negotiationEventId: number; negotiationEventData: unknown }
      >({
        query: ({ projectTag, negotiationEventId, negotiationEventData }) => ({
          url: `/requisitions/${projectTag}/${negotiationEventId}`,
          method: 'PUT',
          body: negotiationEventData,
        }),
        invalidatesTags: (_, error, args) => {
          if (error) return []
          return [
            { type: 'NegotiationEvent', id: args.negotiationEventId },
            'NegotiationEventListItems',
          ]
        },
      }),
      getConfiguration: build.query<PurchasingUIConfig, { projectTag: ProjectTag }>({
        query: ({ projectTag }) => ({
          url: `/purchasing-config/${projectTag}`,
          method: 'GET',
        }),
        transformResponse: (response: { data: PurchasingUIConfig }) => response.data,
      }),
      selectWinner: build.mutation<
        void,
        {
          projectTag: ProjectTag
          requisitionId: number
          requisitionSupplierId: number
        }
      >({
        query: ({ projectTag, requisitionId, requisitionSupplierId }) => ({
          url: `/requisitions/${projectTag}/${requisitionId}/select-winner/${requisitionSupplierId}`,
          method: 'Post',
        }),
        invalidatesTags: (_, error) =>
          error ? [] : ['NegotiationEvent', 'NegotiationEventListItems'],
      }),
      downloadNegotiationEventAttachment: build.query<
        DownloadAttachmentResponse,
        {
          projectTag: ProjectTag
          requisitionId: number
          attachmentId: number
        }
      >({
        query: ({ projectTag, requisitionId, attachmentId }) => ({
          url: `/requisitions/${projectTag}/${requisitionId}/attachments/${attachmentId}`,
          responseHandler: async (response): Promise<DownloadAttachmentResponse> => ({
            fileContent: (await responseAsObjectUrl(response)) as string,
            filename: getFilenameFromHeaders(response),
          }),
        }),
      }),
      uploadAttachments: build.mutation<
        NegotiationEventAttachment[],
        {
          files: File[]
          negotiationEventId: number
          projectTag: ProjectTag
          supplierId?: number
        }
      >({
        query: ({ files, negotiationEventId, projectTag, supplierId }) => ({
          url: `/requisitions/${projectTag}/${negotiationEventId}/attachments`,
          params: {
            requisitionSupplierId: supplierId,
          },
          method: 'POST',
          body: filesToFormData(files),
        }),
        invalidatesTags: (_, error, args) => {
          if (error) return []
          return [
            { type: 'NegotiationEvent', id: args.negotiationEventId },
            'NegotiationEventListItems',
          ]
        },
      }),
      deleteAttachment: build.mutation<
        void,
        { projectTag: ProjectTag; negotiationEventId: number; attachmentId: number }
      >({
        query: ({ projectTag, negotiationEventId, attachmentId }) => ({
          url: `/requisitions/${projectTag}/${negotiationEventId}/attachments/${attachmentId}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, error, args) => {
          if (error) return []
          return [
            { type: 'NegotiationEvent', id: args.negotiationEventId },
            'NegotiationEventListItems',
          ]
        },
      }),
      createNegotiationPreviewLink: build.mutation<
        { chatLink: string },
        { projectTag: ProjectTag; requisitionId: number; supplierId: number }
      >({
        query: ({ projectTag, requisitionId, supplierId }) => ({
          url: `/requisitions/${projectTag}/${requisitionId}/suppliers/${supplierId}/negotiation-preview`,
          method: 'POST',
        }),
        // No need to invalidate tags since we're not altering Requisition entity
        // invalidatesTags: (_, error) => (error ? [] : ['NegotiationEvent', 'NegotiationEventListItems']),
      }),
      getContractPreview: build.query<
        DownloadAttachmentResponse,
        {
          projectTag: ProjectTag
          requisitionId: number
          supplierId: number
        }
      >({
        query: ({ projectTag, requisitionId, supplierId }) => ({
          url: `/requisitions/${projectTag}/${requisitionId}/suppliers/${supplierId}/contract-preview`,
          cache: 'no-cache',
          responseHandler: async (response): Promise<DownloadAttachmentResponse> => ({
            fileContent: (await responseAsObjectUrl(response)) as string,
            filename: getFilenameFromHeaders(response),
          }),
        }),
      }),
      getDocusignContract: build.query<
        DownloadAttachmentResponse,
        { projectTag: ProjectTag; requisitionId: number }
      >({
        query: ({ projectTag, requisitionId }) => ({
          url: `/requisitions/${projectTag}/${requisitionId}/docusign-contract`,
          cache: 'no-cache',
          responseHandler: async (response): Promise<DownloadAttachmentResponse> => ({
            fileContent: (await responseAsObjectUrl(response)) as string,
            filename: getFilenameFromHeaders(response),
          }),
        }),
      }),
    }),
  })

export const {
  useCreateNegotiationEventMutation,
  useCreateNegotiationEventSupplierMutation,
  useCreateNegotiationPreviewLinkMutation,
  useDeleteAttachmentMutation,
  useDeleteNegotiationEventMutation,
  useDeleteNegotiationEventSupplierMutation,
  useGetConfigurationQuery,
  useGetNegotiationEventListItemsQuery,
  useGetSingleNegotiationEventQuery,
  useImportNegotiationEventsMutation,
  useImportCoupaRequisitionMutation,
  useImportCoupaSourcingEventMutation,
  useLazyDownloadNegotiationEventAttachmentQuery,
  useLazyExportNegotiationEventsQuery,
  useLazyDownloadNegotiationEventTemplateQuery,
  useLazyGetContractPreviewQuery,
  useLazyGetDocusignContractQuery,
  useLazyGetSingleNegotiationEventQuery,
  useSelectWinnerMutation,
  useStartNegotiationsMutation,
  useUpdateNegotiationEventMutation,
  useUpdateNegotiationEventSupplierMutation,
  useUploadAttachmentsMutation,
  useWithdrawNegotiationEventMutation,
} = purchasingApi

import { DataGrid, dataGridProps } from '@components/table/DataGrid'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Button, Stack } from '@mui/material'
import {
  GridActionsCellItem,
  GridRenderCellParams,
  type GridColDef,
  type GridRowModel,
} from '@mui/x-data-grid-pro'
import { useWatch } from 'react-hook-form'
import { useFormConfig } from '@procurement/components/Form'
import { SupplierSchema, type SourcingEventFormData } from './SourcingEventForm.schema'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useGetConfigurationQuery } from '@procurement/store/purchasing'
import { TooltipCell } from '@components/TooltipCell'
import { negotiationEventLineItemToSupplierLineItem } from './utils'
import { useMemo } from 'react'

type RowModel = SupplierSchema

interface SuppliersTableProps {
  fields: RowModel[]
  onAppend: (row: RowModel) => void
  onRemove: (index: number) => void
  onUpdate: (index: number, row: GridRowModel<RowModel>) => void
}

export const SuppliersTable = ({ fields, onAppend, onRemove, onUpdate }: SuppliersTableProps) => {
  const { activeProjectTag: projectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({ projectTag })
  const languageOptions = configuration?.suite.languageOptions ?? []
  const formConfig = useFormConfig()
  const disabled = !!formConfig.get('suppliers')?.disabled
  const rows = useMemo(() => [...fields], [fields])
  const negotiationEventLineItems = useWatch<SourcingEventFormData, 'lineItems'>({
    name: 'lineItems',
  })

  const addSupplier = () => {
    const lastSupplier = fields.at(-1)
    const rowId = (lastSupplier?.rowId ?? 0) + 1
    onAppend({
      rowId,
      attachments: [],
      contactName: null,
      email: '',
      externalId: null,
      isOriginalSupplier: true,
      locale: 'en-US',
      name: 'Supplier Name',
      phone: null,
      lineItems: negotiationEventLineItems.map(negotiationEventLineItemToSupplierLineItem),
      negotiationSettings: {
        incentives: [],
        maxPaymentDays: null,
        minPaymentDays: null,
        totalPriceTarget: null,
      },
    })
  }

  const updateRow = (row: GridRowModel<RowModel>) => {
    const index = fields.findIndex((supplier) => supplier.rowId === row.rowId)
    onUpdate(index, row)
    return row
  }

  const deleteRow = (rowId: number) => {
    const index = fields.findIndex((supplier) => supplier.rowId === rowId)
    onRemove(index)
  }

  const commonColumnProps = {
    disableColumnMenu: true,
    editable: !disabled,
    flex: 1,
  }
  const withTooltip = {
    renderCell: ({ value }: GridRenderCellParams<RowModel, string>) => (
      <TooltipCell tooltip={value}>{value}</TooltipCell>
    ),
  }

  const columns: GridColDef<RowModel>[] = [
    {
      ...commonColumnProps,
      ...withTooltip,
      field: 'externalId',
      headerName: 'ID',
      flex: 1,
    },
    {
      ...commonColumnProps,
      ...withTooltip,
      field: 'name',
      headerName: 'Supplier',
      flex: 2,
    },
    {
      ...commonColumnProps,
      ...withTooltip,
      field: 'contactName',
      headerName: 'Contact name',
      flex: 2,
    },
    {
      ...commonColumnProps,
      ...withTooltip,
      field: 'email',
      headerName: 'Email',
      flex: 2,
    },
    {
      ...commonColumnProps,
      ...withTooltip,
      field: 'phone',
      headerName: 'Telephone',
      flex: 1.5,
    },
    {
      ...commonColumnProps,
      field: 'locale',
      headerName: 'Language',
      flex: 1,
      type: 'singleSelect',
      getOptionLabel: (value) => languageOptions.find(({ locale }) => locale === value)?.name ?? '',
      valueOptions: languageOptions.map(({ locale }) => locale),
      cellClassName: 'select-cell',
    },
    {
      ...commonColumnProps,
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 0.5,
      getActions: ({ row }) => {
        if (disabled) return []
        return [
          <GridActionsCellItem
            icon={<DeleteOutlineIcon />}
            label='Delete'
            onClick={() => row.id && deleteRow(row.id)}
          />,
        ]
      },
    },
  ]

  return (
    <Stack spacing={1}>
      <DataGrid
        {...dataGridProps}
        autoHeight={fields.length > 0}
        columns={columns}
        getRowId={(row) => row.rowId}
        processRowUpdate={updateRow}
        // Redux state is frozen for some reason, making a array copy fixes updates of the form
        // Related issue: https://github.com/orgs/react-hook-form/discussions/3715
        rows={rows}
        sx={{
          height: 'auto',
          minHeight: '76px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 1,
          },
        }}
      />
      <Stack direction='row' justifyContent='space-between'>
        <Button
          color='tertiary'
          disabled={disabled}
          onClick={addSupplier}
          size='small'
          variant='outlined'
        >
          + Add supplier
        </Button>
      </Stack>
    </Stack>
  )
}

import { ChipProps } from '@mui/material'
import React from 'react'
import { NegotiationEventStatus } from '@pactum/price-list-types'

export type StatusConfig = Readonly<{
  label: string | React.ReactNode
  color: ChipProps['color']
  variant?: ChipProps['variant']
}>

type NegotiationEventStatusMap = Readonly<Record<NonNullable<NegotiationEventStatus>, StatusConfig>>

export const NEGOTIATION_EVENT_STATUS_CONFIG: NegotiationEventStatusMap = {
  READY: { label: 'Ready', color: 'stoneBlue' },
  IN_PROGRESS: { label: 'In progress', color: 'lightGrey' },
  AGREEMENT_REACHED: { label: 'Agreement reached', color: 'success' },
  EXPIRED: { label: 'Expired', color: 'darkGrey' },
  DECLINED: { label: 'Declined', color: 'error' },
  WITHDRAWN: { label: 'Withdrawn', color: 'darkGrey' },
  SIGNED: { label: 'Signed', color: 'success' },
}

export const FINAL_NEGOTIATION_STATUSES: NegotiationEventStatus[] = [
  'EXPIRED',
  'DECLINED',
  'AGREEMENT_REACHED',
]

import styled from '@emotion/styled'
import { Autocomplete, AutocompleteProps, Box, TextField } from '@mui/material'
import { CurrencyCode, currencyNames } from '@pactum/common'

export interface CurrencyPickerProps
  extends Pick<
    AutocompleteProps<CurrencyCode, false, boolean | undefined, undefined>,
    'autoFocus' | 'onBlur' | 'disableClearable' | 'disabled'
  > {
  currencies?: CurrencyCode[]
  displayCurrencies?: CurrencyCode[] | null
  error?: boolean
  helperText?: string
  label?: string
  onChange: (value: CurrencyCode | null) => void
  popularCurrencies?: CurrencyCode[]
  required?: boolean
  selectedCurrency: CurrencyCode | null
}

interface SelectOption {
  label: string
  value: CurrencyCode
}

const CurrencyName = styled.span`
  font-style: italic;
  margin-left: 16px;
  font-size: 12px;
`

const names = new Map(Object.entries(currencyNames))

export const CurrencyPicker = ({
  currencies,
  displayCurrencies,
  error,
  helperText,
  label,
  onChange,
  popularCurrencies,
  required = false,
  selectedCurrency,
  ...props
}: CurrencyPickerProps) => {
  const allCurrencies = [
    ...(popularCurrencies ?? []),
    ...(displayCurrencies ?? []),
    ...(currencies === undefined || currencies.length === 0
      ? Object.keys(CurrencyCode).map((c) => c as CurrencyCode) // remap explicitly to CurrencyCode since Object.keys returns string[]
      : currencies),
  ]
  const uniq = [...new Set(allCurrencies)]
  const opts = uniq.map((value: CurrencyCode) => ({
    label: names.get(value),
    value: value as string,
  })) as SelectOption[]

  return (
    <Autocomplete
      {...props}
      options={opts}
      autoHighlight
      getOptionLabel={(option: SelectOption) => option.value}
      onChange={(_, val) => onChange(val?.value as CurrencyCode)}
      value={opts.find((opt) => opt.value === selectedCurrency) ?? null}
      renderOption={(props, option) => (
        <Box component='li' {...props}>
          <span>{option.value}</span>
          <CurrencyName>{option.label}</CurrencyName>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label ?? 'Currency'}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  )
}

import { GridContainer } from '../GridContainer/GridContainer'
import { GridItem } from '../GridItem/GridItem'
import { DataCell } from '../DataCell/DataCell'
import { NoValueCell } from '@components/table'
import { NegotiationLink } from '../NegotiationLink/NegotiationLink'
import { NegotiationEventDto } from '@pactum/price-list-types'
import capitalize from 'lodash/capitalize'

type Props = {
  negotiationEvent: NegotiationEventDto
}
export const SupplierSummary = ({ negotiationEvent }: Props) => {
  const { supplier } = negotiationEvent
  return (
    <GridContainer>
      <GridItem>
        <DataCell label='Supplier name' size='small'>
          {supplier.representativeFullName}
        </DataCell>
      </GridItem>
      <GridItem>
        <DataCell label='Supplier company name' size='small'>
          {supplier.companyName}
        </DataCell>
      </GridItem>
      <GridItem>
        <DataCell label='Supplier e-mail' size='small'>
          {supplier?.email}
        </DataCell>
      </GridItem>
      <GridItem>
        <DataCell label='Supplier phone' size='small'>
          {supplier?.phone ?? '-'}
        </DataCell>
      </GridItem>
      <GridItem>
        <DataCell label='Negotiation trigger' size='small'>
          {negotiationEvent.trigger.split('_').map(capitalize).join(' ')}
        </DataCell>
      </GridItem>
      <GridItem>
        <DataCell label='Supplier portal' size='small'>
          <NegotiationLink
            negotiationEvent={negotiationEvent}
            negotiation={supplier?.negotiation}
          />
        </DataCell>
      </GridItem>
      <GridItem>
        <DataCell label='Overall feedback' size='small'>
          {supplier?.negotiation?.cesFeedback.comment ?? <NoValueCell />}
        </DataCell>
      </GridItem>
      <GridItem>
        <DataCell label='Rating' size='small'>
          {supplier?.negotiation?.cesFeedback.score ?? <NoValueCell />}
        </DataCell>
      </GridItem>
    </GridContainer>
  )
}

import { DataGrid, dataGridProps } from '@components/table/DataGrid'
import { TooltipCell } from '@components/TooltipCell'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Button, Stack, TextField } from '@mui/material'
import {
  GridActionsCellItem,
  GridRowParams,
  type GridColDef,
  type GridRenderCellParams,
  type GridRowId,
} from '@mui/x-data-grid-pro'
import { useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useFormConfig } from '@procurement/components/Form'
import type {
  NegotiationEventLineItemSchema,
  SourcingEventFormData,
} from './SourcingEventForm.schema'
import { negotiationEventLineItemToSupplierLineItem } from './utils'

type RowModel = NegotiationEventLineItemSchema

export const NegotiationEventLineItemsTable = () => {
  const formConfig = useFormConfig()
  const disabled = !!formConfig.get('lineItems')?.disabled
  const { getValues, setValue } = useFormContext<SourcingEventFormData>()
  const { append, fields, remove, update } = useFieldArray<SourcingEventFormData, 'lineItems'>({
    name: 'lineItems',
  })
  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([])
  const rows = useMemo(() => [...fields], [fields])

  const addItem = () => {
    const lastLineItem = fields.at(-1)
    const rowId = (lastLineItem?.rowId ?? 0) + 1
    const numberInCollection = (lastLineItem?.numberInCollection ?? 0) + 1
    const newLineItem: RowModel = {
      rowId,
      category: null,
      currency: 'USD',
      description: null,
      externalId: null,
      name: 'Name',
      numberInCollection,
      quantity: 1,
      unit: 'each',
      unitPrice: null,
    }

    append(newLineItem)

    // Updating individual supplier line items causes an error, see more here:
    // https://github.com/react-hook-form/react-hook-form/issues/6121
    const suppliers = getValues('suppliers')
    const suppliersToUpdate = suppliers.map((supplier) => ({
      ...supplier,
      lineItems: [...supplier.lineItems, negotiationEventLineItemToSupplierLineItem(newLineItem)],
    }))

    setValue('suppliers', suppliersToUpdate)
  }

  const updateRow = (newRow: RowModel): RowModel => {
    const rowIndex = fields.findIndex((field) => field.rowId === newRow.rowId)
    update(rowIndex, newRow)

    // Updating individual supplier line items causes an error, see more here:
    // https://github.com/react-hook-form/react-hook-form/issues/6121
    const suppliers = getValues('suppliers')
    const suppliersToUpdate = suppliers.map((supplier) => ({
      ...supplier,
      lineItems: supplier.lineItems.map((lineItem) => {
        if (lineItem.numberInCollection === newRow.numberInCollection) {
          return negotiationEventLineItemToSupplierLineItem(newRow)
        }
        return lineItem
      }),
    }))

    setValue('suppliers', suppliersToUpdate)

    return newRow
  }

  const deleteRow = (rowId: number) => {
    const rowIndex = fields.findIndex((field) => field.rowId === rowId)
    remove(rowIndex)

    const suppliers = getValues('suppliers')

    for (const [idx, supplier] of suppliers.entries()) {
      const lineItems = supplier.lineItems.filter((lineItem) => lineItem.rowId !== rowId)

      setValue(`suppliers.${idx}.lineItems`, lineItems)
    }
  }

  const commonColumnProps = {
    disableColumnMenu: true,
    editable: !disabled,
    flex: 1,
  }

  const tableColumns: GridColDef<RowModel>[] = [
    {
      ...commonColumnProps,
      field: 'numberInCollection',
      headerName: '#',
      type: 'number',
      flex: 0.5,
      editable: false,
    },
    {
      ...commonColumnProps,
      field: 'name',
      headerName: 'Name',
      flex: 2,
      renderCell: ({ value }: GridRenderCellParams<RowModel, string>) => (
        <TooltipCell tooltip={value}>{value}</TooltipCell>
      ),
    },
    {
      ...commonColumnProps,
      field: 'category',
      headerName: 'Category',
      flex: 1,
    },
    {
      ...commonColumnProps,
      field: 'quantity',
      headerName: 'Quantity',
      type: 'number',
      flex: 1,
    },
    {
      ...commonColumnProps,
      field: 'unit',
      headerName: 'Unit of measure',
      flex: 1,
    },
    {
      ...commonColumnProps,
      field: 'actions',
      type: 'actions',
      align: 'right',
      getActions: ({ row }: { row: RowModel }) => {
        if (disabled) return []
        return [
          <GridActionsCellItem
            icon={<DeleteOutlineIcon />}
            label='Delete'
            onClick={() => {
              if (row.id) deleteRow(row.id)
            }}
          />,
        ]
      },
    },
  ]

  const expandedPanel = ({ row }: GridRowParams<RowModel>) => (
    <Stack direction='row' pl={14.5} py={1}>
      <TextField
        color='accent'
        fullWidth
        label='Description'
        onChange={(event) => updateRow({ ...row, description: event.target.value })}
        value={row.description ?? ''}
        variant='outlined'
      />
    </Stack>
  )

  return (
    <Stack spacing={1}>
      <DataGrid
        {...dataGridProps}
        autoHeight={fields.length > 0}
        columns={tableColumns}
        detailPanelExpandedRowIds={expandedRowIds}
        getDetailPanelContent={expandedPanel}
        getDetailPanelHeight={() => 'auto'}
        getRowId={(row) => row.rowId}
        onDetailPanelExpandedRowIdsChange={setExpandedRowIds}
        processRowUpdate={updateRow}
        onProcessRowUpdateError={(error) => console.error(error)}
        // Redux state is frozen for some reason, making a array copy fixes updates of the form
        // Related issue: https://github.com/orgs/react-hook-form/discussions/3715
        rows={rows}
        sx={{
          height: 'auto',
          minHeight: '76px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 1,
          },
        }}
      />
      <Stack direction='row' justifyContent='space-between'>
        <Button
          color='tertiary'
          disabled={disabled}
          onClick={addItem}
          size='small'
          variant='outlined'
        >
          + Add item
        </Button>
      </Stack>
    </Stack>
  )
}

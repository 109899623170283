import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { environment } from 'src/environments/environment'
/** See https://storybook.js.org/docs/writing-stories/mocking-data-and-modules/mocking-modules#subpath-imports */
import { getAccessToken } from '#src/main/hooks/auth/useAuthInternals'

export const baseUrl = environment.REACT_APP_PURCHASING_BACKEND_URL

/**
 * Creates a new base API for queries to purchasing server.
 *
 * This prevents the users of this sharing the same namespace,
 * which is prone to conflicts (which would be the case when we
 * simply shared a variable - as was done in the past) and leads
 * to excessively long names to avoid these conflicts.
 *
 * The downside is that we can't share info between the APIs,
 * but at least for now this is not needed.
 */
export const purchasingBaseApi = <T extends string>(reducerPath: T) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl,
      prepareHeaders: async (headers) => {
        const token = await getAccessToken()
        headers.set('Authorization', `Bearer ${token}`)

        return headers
      },
    }),
    endpoints: () => ({}),
    tagTypes: [],
  })

import { Grid, Tab, Tabs } from '@mui/material'
import { Form } from '../Form'
import { NegotiationEventLineItemsTable } from './NegotiationEventLineItemsTable'
import { CurrencyInputAdornment } from './CurrencyInputAdornment'
import { Incentives } from './Incentives'
import { Attachments } from './Attachments'
import { DatePickerCtrl, NumberFieldCtrl, TextFieldCtrl } from '@components/Form'
import { CurrencyPickerCtrl } from '@components/Form/CurrencyPickerCtrl'
import { useEffect, useState } from 'react'
import { SourcingEventFormData } from './SourcingEventForm.schema'
import { useFieldArray, useWatch } from 'react-hook-form'
import { PaymentDaysFieldCtrl } from '@procurement/components/PaymentDaysFieldCtrl'
import { ExistingPaymentDaysFieldCtrl } from '@procurement/components/ExistingPaymentDaysFieldCtrl/ExistingPaymentDaysFieldCtrl'
import {
  getMinMaxPaymentDaysLabel,
  getPaymentDaysLabel,
  transformPaymentDaysOptions,
} from '@procurement/utils/paymentDays'
import { SuppliersTable } from './SuppliersTable'
import { useGetConfigurationQuery } from '@procurement/store/purchasing'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { SupplierLineItemsTable } from './SupplierLineItemsTable'

export const SourcingEventForm = () => {
  const [selectedSupplierIndex, setSelectedSupplierIndex] = useState(-1)

  const suppliers = useWatch<SourcingEventFormData, 'suppliers'>({
    name: 'suppliers',
  })

  const suppliersFieldArray = useFieldArray<SourcingEventFormData, 'suppliers'>({
    name: 'suppliers',
  })

  useEffect(() => {
    if (suppliers.length && selectedSupplierIndex >= suppliers.length) {
      setSelectedSupplierIndex(suppliers.length - 1)
    }
  }, [selectedSupplierIndex, suppliers.length])

  return (
    <Form.Root>
      <Form.Section>
        <Form.SectionTitle>Sourcing event details</Form.SectionTitle>
        <Form.Field
          columns={12}
          configId='title'
          label='Description'
          name='title'
          render={(params) => (
            <TextFieldCtrl {...params} fullWidth helperText='Sourcing event name' />
          )}
        />
        <Form.Field
          columns={6}
          configId='externalId'
          label='Reference ID'
          name='externalId'
          render={(params) => (
            <TextFieldCtrl {...params} fullWidth helperText='Reference ID of the sourcing event' />
          )}
        />
        <Form.Field
          columns={6}
          configId='buyer'
          label='Buyer name'
          name='buyerContactName'
          render={(params) => (
            <TextFieldCtrl
              {...params}
              fullWidth
              helperText='Name of the person managing the negotiation'
            />
          )}
        />
        <Form.Field
          columns={6}
          configId='buyer'
          label='Buyer email'
          name='buyerContactEmail'
          render={(params) => (
            <TextFieldCtrl
              {...params}
              fullWidth
              helperText='The email of the person managing the negotiation'
            />
          )}
        />
        <Form.Field
          columns={6}
          configId='deliveryDate'
          label='Delivery date'
          name='deliveryDate'
          render={(params) => (
            <DatePickerCtrl
              {...params}
              fullWidth
              inputProps={{ helperText: 'The items are needed on this date' }}
              timezone='UTC'
            />
          )}
        />
      </Form.Section>

      <Form.Section>
        <Form.SectionTitle>Suppliers</Form.SectionTitle>
        <Grid item xs={12}>
          <SuppliersTable
            fields={suppliersFieldArray.fields}
            onAppend={suppliersFieldArray.append}
            onRemove={suppliersFieldArray.remove}
            onUpdate={suppliersFieldArray.update}
          />
        </Grid>
      </Form.Section>

      <Form.Section>
        <Grid item xs={12}>
          <Tabs
            onChange={(_event, value) => setSelectedSupplierIndex(value)}
            value={selectedSupplierIndex}
            variant='scrollable'
            scrollButtons='auto'
            aria-label='scrollable auto tabs example'
            sx={{
              '.Mui-disabled': {
                display: 'none',
              },
            }}
          >
            {<Tab key='-1' label='All suppliers' value={-1} />}
            {suppliers.map((supplier, index) => (
              <Tab key={supplier.id} label={supplier.name} value={index} />
            ))}
          </Tabs>
        </Grid>
      </Form.Section>

      {selectedSupplierIndex === -1 && <AllSuppliersFormSections />}

      {suppliersFieldArray.fields.map((field, index) => {
        if (index === selectedSupplierIndex) {
          return <SupplierFormSections key={field.id} supplierIndex={index} />
        }
      })}
    </Form.Root>
  )
}

const AllSuppliersFormSections = () => (
  <Form.Section>
    <Form.SectionDescription>
      These negotiation settings affect all the suppliers if they have not been manually edited.
      Supplier sections allow making overrides per supplier basis.
    </Form.SectionDescription>
    <Form.Field
      columns={4}
      configId='currency'
      label='Currency'
      name='currency'
      render={(params) => <CurrencyPickerCtrl {...params} disableClearable />}
    />
    <Form.Section>
      <Form.SectionTitle>Line items</Form.SectionTitle>
      <Grid item xs={12}>
        <NegotiationEventLineItemsTable />
      </Grid>
    </Form.Section>
  </Form.Section>
)

const SupplierFormSections = ({ supplierIndex }: { supplierIndex: number }) => {
  const { activeProjectTag: projectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({ projectTag })
  const incentivesOptions = configuration?.suite.incentivesOptions ?? []
  const paymentDaysOptions = configuration?.suite.paymentDaysOptions ?? []
  const namePrefix = supplierIndex !== null ? (`suppliers.${supplierIndex}.` as const) : ''
  const paymentDays = transformPaymentDaysOptions(paymentDaysOptions)
  const formatMinMaxPaymentDays = getMinMaxPaymentDaysLabel(paymentDays)

  return (
    <>
      <Form.Section>
        <Form.SectionTitle>Price and limits</Form.SectionTitle>

        <Form.Section>
          <Form.SectionTitle>Line items</Form.SectionTitle>
          <Grid item xs={12}>
            <SupplierLineItemsTable supplierIndex={supplierIndex} />
          </Grid>
        </Form.Section>

        <Form.Section>
          <Form.SectionTitle>Desired price</Form.SectionTitle>
          <Form.Field
            columns={4}
            configId='suppliers.0.negotiationSettings.totalPriceTarget'
            name={`${namePrefix}negotiationSettings.totalPriceTarget`}
            render={(params) => (
              <NumberFieldCtrl
                {...params}
                helperText='We will try to get down to this price'
                InputProps={{ startAdornment: <CurrencyInputAdornment name='currency' /> }}
                min={0}
                variant='outlined'
              />
            )}
          />
        </Form.Section>
      </Form.Section>

      <Form.Section configId='paymentDaysEnabled' toggleName='paymentDaysEnabled'>
        <Form.SectionTitle>Payment days</Form.SectionTitle>
        <Form.SectionDescription>
          To negotiate better deals, enter the current payment terms that are in effect with this
          supplier.
        </Form.SectionDescription>
        <Form.SectionContent>
          <Form.Field
            columns={4}
            label='Existing payment days'
            name={`${namePrefix}initialPaymentTerms.paymentDaysObject`}
            render={(params) => (
              <ExistingPaymentDaysFieldCtrl
                {...params}
                freeSolo={true}
                fullWidth
                getOptionLabel={getPaymentDaysLabel}
                helperText='Current agreed payment days'
                options={paymentDays}
                variant='outlined'
              />
            )}
          />
          <Form.Field
            columns={4}
            label='Minimum payment days'
            name={`${namePrefix}negotiationSettings.minPaymentDays`}
            render={(params) => (
              <PaymentDaysFieldCtrl
                {...params}
                freeSolo={false}
                fullWidth
                getOptionLabel={formatMinMaxPaymentDays}
                helperText='Do not accept anything shorter'
                options={paymentDays}
                variant='outlined'
              />
            )}
          />
          <Form.Field
            columns={4}
            label='Maximum payment days'
            name={`${namePrefix}negotiationSettings.maxPaymentDays`}
            render={(params) => (
              <PaymentDaysFieldCtrl
                {...params}
                freeSolo={false}
                fullWidth
                getOptionLabel={formatMinMaxPaymentDays}
                helperText='Do not accept anything longer'
                options={paymentDays}
                variant='outlined'
              />
            )}
          />
        </Form.SectionContent>
      </Form.Section>

      <Form.Section
        configId='suppliers.0.negotiationSettings.incentives'
        toggleName='incentivesEnabled'
      >
        <Form.SectionTitle>Incentives</Form.SectionTitle>
        <Form.SectionDescription>
          By choosing to include any of the below incentives in the negotiation, the supplier will
          be given the opportunity to select the ones they are interested in, motivating them to
          make concessions on the negotiable terms.
        </Form.SectionDescription>
        <Form.SectionContent>
          <Incentives
            incentives={incentivesOptions ?? []}
            name={
              `${namePrefix}negotiationSettings.incentives` as `suppliers.${number}.negotiationSettings.incentives`
            }
          />
        </Form.SectionContent>
      </Form.Section>

      <Form.Section configId='attachments'>
        <Form.SectionTitle>File attachments</Form.SectionTitle>
        <Form.SectionDescription>
          You can attach files to use as background material, such as quotes and SOWs. Attachments
          will be made visible for all suppliers in the requisition.
        </Form.SectionDescription>
        <Form.Field
          columns={12}
          name={`${namePrefix}attachments`}
          render={(params) => (
            <Attachments canUpload={!params.disabled} name={`${namePrefix}attachments`} />
          )}
        />
      </Form.Section>
    </>
  )
}

import { User } from '@common/types/suite/User'
import { ActionsButton } from '@components/ActionsButton'
import { captureException } from '@sentry/react'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useOrgTag } from '../../../hooks/useOrgTag'
import {
  useRemoveOrganizationUserMutation,
  useResendOrganizationUserInvitationMutation,
} from '@store/userManagement'
import { EditUserDialog } from './dialogs/EditUserModalDialog'
import { RemoveUserModal } from './dialogs/RemoveUserModal'

type UserAction = 'RESEND_INVITE' | 'REMOVE' | 'EDIT'

type ListItemAction = {
  label: string
  onClick: () => unknown
}

type Actions = Record<UserAction, ListItemAction>

interface Props {
  user: User
  actionsMenuMinWidth: number
  refetchUsers: () => void
}

export const ListItemActions = ({ user, actionsMenuMinWidth, refetchUsers }: Props) => {
  const orgTag = useOrgTag()

  const [removeUserDialogOpen, setRemoveUserDialogOpen] = useState(false)
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const [removeOrganizationUser] = useRemoveOrganizationUserMutation()
  const [resendOrganizationUserInvitation] = useResendOrganizationUserInvitationMutation()

  const handleRemoveUser = async (user: User) => {
    try {
      await removeOrganizationUser({
        orgTag: orgTag,
        user: user,
      }).unwrap()
      enqueueSnackbar(`Access removed successfully for user ${user.email}`, { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(`Error removing user ${user.email}`, { variant: 'error' })
    }
  }

  const allActions: Actions = {
    RESEND_INVITE: {
      label: 'Re-send invite',
      onClick: async () => {
        try {
          await resendOrganizationUserInvitation({ orgTag: orgTag, userId: user.userId })
          enqueueSnackbar(`Invite resent successfully for user ${user.email}`, {
            variant: 'success',
          })
        } catch (error) {
          enqueueSnackbar(`Error resending invite for user ${user.email}`, { variant: 'error' })
        }
      },
    },
    REMOVE: {
      label: 'Remove',
      onClick: () => {
        setRemoveUserDialogOpen(true)
      },
    },
    EDIT: {
      label: 'Edit',
      onClick: () => {
        setEditUserDialogOpen(true)
      },
    },
  }

  let userActions: UserAction[]

  switch (user.status) {
    case 'invited':
      userActions = ['EDIT', 'RESEND_INVITE', 'REMOVE']
      break
    case 'expired':
      userActions = ['RESEND_INVITE', 'EDIT', 'REMOVE']
      break
    case 'active':
      userActions = ['EDIT', 'REMOVE']
      break
    default: {
      const errorMessage = `Manage users: unknown user status ${user.status}`
      console.error(errorMessage)
      captureException(new Error(errorMessage))
      userActions = []
      break
    }
  }

  return (
    <>
      <ActionsButton
        isCustomButtonWidth
        id={user.email}
        allowedActions={userActions}
        actionConfig={allActions}
        size='medium'
        menuMinWidth={actionsMenuMinWidth}
      />
      <RemoveUserModal
        open={removeUserDialogOpen}
        onCancel={() => setRemoveUserDialogOpen(false)}
        onSubmit={async () => {
          setRemoveUserDialogOpen(false)
          await handleRemoveUser(user)
          refetchUsers()
        }}
        user={user}
      />
      <EditUserDialog
        user={user}
        isOpen={editUserDialogOpen}
        onClose={() => setEditUserDialogOpen(false)}
        refetchUsers={refetchUsers}
      />
    </>
  )
}

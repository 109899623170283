import { useCallback, useEffect, useRef } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { checkSession } from '#src/main/hooks/auth/useAuthInternals'
import { millisecondsInMinute } from 'date-fns'

const INACTIVITY_DURATION = millisecondsInMinute * 5
const SESSION_CHECK_INTERVAL = millisecondsInMinute * 10

/**
 * The `useIdleDetection` hook is responsible for managing session checks based on user activity and inactivity.
 * It ensures that the user's session is refreshed periodically while they are active and stops the session check
 * when the user becomes idle. If the user remains idle for an extended period, the session is allowed to expire naturally.
 */

export const useIdleDetection = () => {
  const sessionCheckInterval = useRef<NodeJS.Timeout | null>(null)

  const startSessionCheckInterval = useCallback(() => {
    if (sessionCheckInterval.current) return

    sessionCheckInterval.current = setInterval(async () => {
      await checkSession()
    }, SESSION_CHECK_INTERVAL)
  }, [])

  const stopSessionCheckInterval = useCallback(() => {
    if (sessionCheckInterval.current) {
      clearInterval(sessionCheckInterval.current)
      sessionCheckInterval.current = null
    }
  }, [])

  const onActive = useCallback(async () => {
    // Perform an immediate session check when the user becomes active
    await checkSession()

    startSessionCheckInterval()
  }, [startSessionCheckInterval])

  const onIdle = useCallback(() => {
    stopSessionCheckInterval()
  }, [stopSessionCheckInterval])

  // Setup idle detection
  useIdleTimer({
    timeout: INACTIVITY_DURATION,
    onIdle: onIdle,
    onActive: onActive,
  })

  useEffect(() => {
    // Start the session check interval when the component mounts
    startSessionCheckInterval()

    return () => {
      stopSessionCheckInterval() // Cleanup the interval on unmount
    }
  }, [startSessionCheckInterval, stopSessionCheckInterval])
}

import { Checkbox, FormControlLabel, Typography, TypographyProps } from '@mui/material'
import { useController } from 'react-hook-form'

export type CheckboxCtrlProps = {
  name: string
  label?: string
  required?: boolean
  disabled?: boolean
  labelProps?: TypographyProps
}

/** MUI Checkbox controlled by react-hook-form */
export const CheckboxCtrl = ({
  name,
  label,
  required,
  disabled,
  labelProps,
}: CheckboxCtrlProps) => {
  const { field, fieldState } = useController({ name, rules: { required } })

  return (
    <FormControlLabel
      checked={!!field.value}
      name={field.name}
      onChange={field.onChange}
      onBlur={field.onBlur}
      ref={field.ref}
      disabled={disabled}
      control={<Checkbox />}
      label={
        <Typography color={fieldState.error && 'error.main'} variant='body2' {...labelProps}>
          {label}
        </Typography>
      }
    />
  )
}

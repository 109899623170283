import { Auth0Client, createAuth0Client, User } from '@auth0/auth0-spa-js'
import * as Sentry from '@sentry/react'
import { captureException } from '@sentry/react'
import posthog from 'posthog-js'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { environment } from 'src/environments/environment'
import { FreshChatAwareWindow } from 'src/main/utils/freshchat'
import { isLoginRequiredError } from './utils'

// used to prevent double renders – such as from strictmode react – from causing race conditions
let handlingRedirectCallback = false

declare let window: FreshChatAwareWindow

interface FreshChatUser {
  email: string
  firstName: string
  lastName: string
}

let auth0: Auth0Client | null = null

export const getAuth0Client = async (): Promise<Auth0Client | null> => {
  if (auth0 === null) {
    try {
      auth0 = await createAuth0Client({
        domain: environment.REACT_APP_AUTH0_DOMAIN,
        clientId: environment.REACT_APP_AUTH0_CLIENT,
        authorizationParams: {
          redirect_uri: window.location.origin,
          audience: environment.REACT_APP_AUTH0_BACKEND_API,
        },
        useRefreshTokens: true,
        useFormData: false, //we use application/json
        useRefreshTokensFallback: true,
      })
    } catch (error) {
      console.error('Failed to create Auth0 client', error)
      captureException(error)
    }
  }

  return auth0
}

export const useAuthInternals = () => {
  const [isUserLoaded, setIsUserLoaded] = useState(false)
  const [user, setUser] = useState<User | null>(null)
  const [error, setError] = useState<string | null>(null)

  const navigate = useNavigate()

  const fetchAndSetUser = async () => {
    const auth0Client = await getAuth0Client()
    const user = await auth0Client?.getUser()

    if (user) {
      if (environment.REACT_APP_ENV !== 'development') {
        posthog.identify(user.email, {
          email: user.email,
          name: user.name,
          environment: environment.REACT_APP_ENV,
        })

        const freshChatUser: Partial<FreshChatUser> = {
          email: user.email,
          firstName: user.given_name,
          lastName: user.family_name,
        }

        window.fcWidget?.user?.setProperties({
          ...freshChatUser,
        })
      }
      Sentry.setUser({ email: user.email, username: user.name })
      await getAccessToken()
      setUser(user)
    }

    // if we've tried to load the user but there is none - we haven't logged in at all yet
    setIsUserLoaded(true)
    handlingRedirectCallback = false
  }

  const loadUserOrHandleLoginError = async () => {
    if (handlingRedirectCallback) {
      return
    }
    handlingRedirectCallback = true
    if (errorOccurredOnLogin()) {
      const params = new URLSearchParams(window.location.search)
      const errorMessage = params.get('error_description') ?? params.get('error')
      setError(errorMessage)
    }

    if (needToHandleRedirectCallback()) {
      // redirect callback stores new user info, if we can then we need to handle it
      const auth0Client = await getAuth0Client()
      // need to handle the redirect callback - this function is invoked after we were redirected back here from auth0
      // after it is handled, user and token will be available (when authenticating with org, the user and token will be updated to be org specific)
      try {
        const result: { appState?: { returnTo: string } } | undefined =
          await auth0Client?.handleRedirectCallback()
        navigate(result?.appState?.returnTo || window.location.pathname, { replace: true })
      } catch (error) {
        console.error('Failed to handle auth0 redirect callback', error)
        return window.location.replace(window.location.origin)
      }
    }
    void fetchAndSetUser()
  }

  return { isUserLoaded, user, error, loadUserOrHandleLoginError, startAuthentication }
}

const needToHandleRedirectCallback = (): boolean => {
  const query = window.location.search
  return query.includes('code=') && query.includes('state=')
}

const errorOccurredOnLogin = (): boolean => {
  const params = new URLSearchParams(window.location.search)
  return Boolean(params.get('error') ?? params.get('error_description'))
}

export const getAccessToken = async (): Promise<string> => {
  try {
    const auth0Client = await getAuth0Client()
    return (await auth0Client?.getTokenSilently()) as string
  } catch (error) {
    if (isLoginRequiredError(error)) {
      await startAuthentication()
    } else {
      console.error('Failed silent access token refresh', error)
      captureException(error)
      throw error
    }
    //TODO: fix this by rejecting a promise when isLoginRequiredError is true. Update the usage of this function to handle the promise rejection
    return window.location.reload() as unknown as string
  }
}

export const startAuthentication = async () => {
  if (needToHandleRedirectCallback()) {
    // refuse to log in when redirect callback needs to be handled
    return
  }

  const auth0Client = await getAuth0Client()

  return auth0Client?.loginWithRedirect({
    appState: {
      returnTo: `${window.location.pathname}${window.location.search}`,
    },
  })
}

/**
 * Check if the user is logged in using getTokenSilently. The difference with getAccessToken
 * is that this doesn't return a token, but it will pre-fill the token cache.
 *
 * cacheMode: 'off' is used as a workaround to force the refresh token -> access token exchange when the refresh token
 * expiration is set to a lower value than the access token expiration
 */

export const checkSession = async (): Promise<void> => {
  try {
    const auth0Client = await getAuth0Client()
    if (auth0Client) {
      try {
        await auth0Client.getTokenSilently({ cacheMode: 'off' })
      } catch (error) {
        await startAuthentication()
      }
    }
  } catch (error) {
    console.error('Failed to check session:', error)
  }
}

export const logout = async () => {
  const auth0Client = await getAuth0Client()
  if (auth0Client) {
    void auth0Client.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
    if (environment.REACT_APP_ENV !== 'development') {
      // Clears super properties and generates a new random distinct_id for
      // this instance. Useful for clearing data when a user logs out.
      posthog.reset()
    }
  }
}

import { MouseEvent, useEffect, useMemo, useState } from 'react'
import {
  NegotiationEvent,
  NegotiationEventLineItem,
  NegotiationEventSupplierLineItem,
  PurchasingUIConfig,
} from '@procurement/store/types'
import { ProductType } from '@pactum/core-backend-types'
import { useFormatter } from '@shared/hooks'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import {
  getUpdatedQuoteAttachment,
  useDownloadNegotiationEventAttachmentQuery,
} from '@procurement/hooks/useDownloadNegotiationEventAttachmentQuery'
import { buildConfigurableFields } from '@procurement/components/NegotiationEventForm/NegotiationEventFormContext'
import { StyledTab } from '@components/StyledTab'
import { DataGrid, dataGridProps, NoValueCell } from '@components/table'
import { InsertDriveFileRounded } from '@mui/icons-material'
import { Button, Grid, Link, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro'
import { DataCell } from '@procurement/components/DataCell/DataCell'
import { GridItem } from '@procurement/components/GridItem/GridItem'
import { SavingsBreakdown } from '@procurement/components/SavingsBreakdown/SavingsBreakdown'
import { StyledTabs } from '@procurement/components/StyledTab/StyledTab'
import { AddSupplierDialog } from '@procurement/pages/NegotiationEventDetails/AddSupplierDialog/AddSupplierDialog'
import { FilesVisibleToSupplier } from '../FilesVisibleToSupplier/FilesVisibleToSupplier'
import { NegotiationLink } from '@procurement/components/NegotiationLink/NegotiationLink'
import { OfferedIncentives } from '../OfferedIncentives/OfferedIncentives'
import { SuppliersList } from '../SuppliersList/SuppliersList'
import { AmountCell } from '../AmountCell/AmountCell'
import { MinMaxPaymentDaysCell } from '../MinMaxPaymentDaysCell/MinMaxPaymentDaysCell'
import { PaymentTermsCell } from '../PaymentTermsCell/PaymentTermsCell'
import { ProgressCaption } from '../ProgressCaption/ProgressCaption'
import { SavingsCell } from '../SavingsCell/SavingsCell'
import {
  renderAcceptableIncotermsCell,
  renderConsignmentInventoryCell,
  renderInvoiceDateCell,
  renderTooltipCell,
  renderTooltipUnitCell,
  renderUnitPriceCell,
} from '@procurement/utils/eventSummary'
import { RebatesCell } from '../RebatesCell/RebatesCell'
import { MaximumAcceptablePriceCell } from '../MaximumAcceptablePriceCell/MaximumAcceptablePriceCell'
import { Terms } from '@procurement/components/Terms/Terms'
import { GridContainer } from '@procurement/components/GridContainer/GridContainer'
import { TargetPriceCell } from '@procurement/pages/NegotiationEventDetails/TargetPriceCell/TargetPriceCell'
import { isSpendNegotiation } from '@procurement/components/NegotiationEventForm/utils/spend'

export type LineItem = NegotiationEventSupplierLineItem | NegotiationEventLineItem

type Props = {
  negotiationEvent: NegotiationEvent
  productType?: ProductType
  configuration: PurchasingUIConfig
}

export const NegotiationEventSummary = ({
  negotiationEvent,
  productType,
  configuration,
}: Props) => {
  const formatter = useFormatter()
  const { activeProjectTag } = useActiveProject()
  const [dialogVisible, setDialogVisible] = useState(false)
  const [showLatestAddedSupplier, setLatestAddedSupplier] = useState(false)
  const suppliers = useMemo(
    () => [...negotiationEvent.suppliers].sort((first, second) => first.id - second.id),
    [negotiationEvent],
  )
  const [supplier, setSelectedSupplier] = useState(suppliers[0])

  useEffect(() => {
    setSelectedSupplier(suppliers[0])
  }, [suppliers])

  const downloadHandler = useDownloadNegotiationEventAttachmentQuery()

  // TODO: should avoid requisition-form specific methods?
  const { visibleFields } = buildConfigurableFields(configuration?.suite.requisitionFormFields)

  const useCase = configuration?.suite.useCase
  const paymentDaysOptions = configuration?.suite.paymentDaysOptions

  const isMultiSupplier = suppliers.length > 1 || useCase === 'integratedPostBid'
  const supplierLineItems = supplier.lineItems?.length
    ? supplier.lineItems
    : negotiationEvent.lineItems

  const onDownload = (attachmentId: number) => (event: MouseEvent) => {
    event.preventDefault()
    downloadHandler({
      attachmentId,
      negotiationEventId: negotiationEvent.id,
      projectTag: activeProjectTag,
    })
  }
  const selectSupplier = (index: number) => {
    setSelectedSupplier(suppliers[index])
  }

  useEffect(() => {
    if (showLatestAddedSupplier) {
      setSelectedSupplier(suppliers.at(-1) ?? suppliers[0])
      setLatestAddedSupplier(false)
    } else {
      const currentlySelectedSupplier = suppliers.find(({ id }) => id === supplier.id)
      setSelectedSupplier(currentlySelectedSupplier ?? suppliers[0])
    }
  }, [suppliers, supplier.id, showLatestAddedSupplier])

  const canShowAddSupplierButton =
    configuration &&
    !configuration.suite.hideAddSupplierButton &&
    !negotiationEvent.negotiationsStartedAt &&
    !configuration.suite.isReadonly
  const isAgreementReached = supplier.negotiation?.status === 'AGREEMENT_REACHED'
  const isContractCost = productType === ProductType.ContractCost
  const isPurchasing = productType === ProductType.Purchasing
  const quoteAttachment = getUpdatedQuoteAttachment(supplier.attachments)
  const showMinMaxPaymentDays = !!supplier.negotiationSettings?.maxPaymentDays
  const showDeliveryLocationColumn = visibleFields.includes('lineItems.deliveryLocation')
  const showIncotermsColumns = visibleFields.includes('lineItems.incoterm')
  const showInvoiceDateColumn = !!negotiationEvent.contractModel?.properties?.invoiceDates
  const showMinimumOrderQuantityColumns = !!configuration.suite.moqEnabled
  const showConsignmentInventoryColumn = visibleFields.includes(
    'suppliers.0.negotiationSettings.consignmentAgreementStatuses',
  )
  const isRebatesNegotiation = visibleFields.includes('rebatesEnabled')
  // This is specific to Contract Cost workspaces where rebate is negotiated through applicable spend (not line items).
  // Thus no reason for Max Total Price or Items to be shown.
  const isRebatesNegotiationWithApplicableSpend = isSpendNegotiation(negotiationEvent.lineItems[0])
  const showMaxTotalPrice = !isRebatesNegotiationWithApplicableSpend
  const showItems = !isRebatesNegotiationWithApplicableSpend
  const isPostBid = useCase === 'integratedPostBid'

  const lineItemQuantityField = configuration?.suite.requisitionFormFields?.find(
    (field: { id: string }) => field.id === 'lineItems.quantity',
  ) ?? { label: 'Quantity' }

  const lineItemLocationField = configuration?.suite.requisitionFormFields?.find(
    (field: { id: string }) => field.id === 'lineItems.deliveryLocation',
  ) ?? { label: 'Incoterm location' }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Typography variant='h1'>{isMultiSupplier ? 'Suppliers' : supplier?.name}</Typography>
        </Grid>
        <Grid item>
          {canShowAddSupplierButton && (
            <Button color='accent' variant='contained' onClick={() => setDialogVisible(true)}>
              + Add supplier
            </Button>
          )}
        </Grid>
      </Grid>
      <ProgressCaption negotiationEvent={negotiationEvent} formatter={formatter} />
      <SavingsBreakdown negotiationEvent={negotiationEvent} />
      {isMultiSupplier && (
        <>
          <SuppliersList negotiationEvent={negotiationEvent} selectSupplier={selectSupplier} />
          <Typography variant='h2'>Offer details</Typography>
          <StyledTabs
            value={suppliers.indexOf(supplier) ?? 0}
            TabIndicatorProps={{ sx: { backgroundColor: 'accent.main' } }}
            onChange={(e, newTab) => selectSupplier(newTab)}
          >
            {suppliers.map((supplier, key) => {
              const styles = supplier.isEnabledForNegotiation
                ? {}
                : {
                    color: 'text.disabled',
                    '&.Mui-selected': {
                      color: 'text.disabled',
                    },
                  }

              return <StyledTab label={supplier.name} value={key} sx={styles} key={key} />
            })}
          </StyledTabs>
        </>
      )}
      <GridContainer>
        <GridItem>
          <AmountCell
            negotiationEvent={negotiationEvent}
            size='large'
            formatter={formatter}
            negotiationSummary={supplier.negotiation}
            supplier={supplier}
            useCase={useCase}
          />
        </GridItem>
        <GridItem>
          <PaymentTermsCell
            event={negotiationEvent}
            supplier={supplier}
            formatter={formatter}
            size={'large'}
          />
        </GridItem>
        {isContractCost && isRebatesNegotiation && (
          <GridItem>
            <RebatesCell
              negotiationEvent={negotiationEvent}
              supplier={supplier}
              size={'large'}
              formatter={formatter}
            />
          </GridItem>
        )}
        <GridItem>
          <SavingsCell
            negotiationEvent={negotiationEvent}
            size='large'
            formatter={formatter}
            negotiation={supplier?.negotiation}
          />
        </GridItem>
        {isContractCost && showMaxTotalPrice && (
          <GridItem>
            <MaximumAcceptablePriceCell
              negotiationEvent={negotiationEvent}
              supplier={supplier}
              size='large'
              formatter={formatter}
            />
          </GridItem>
        )}
        {isPostBid && (
          <GridItem>
            <TargetPriceCell
              negotiationEvent={negotiationEvent}
              supplier={supplier}
              size='large'
              formatter={formatter}
            />
          </GridItem>
        )}
      </GridContainer>
      {showMinMaxPaymentDays && (
        <GridContainer>
          <GridItem>
            <MinMaxPaymentDaysCell
              supplier={supplier}
              size='medium'
              paymentDays={paymentDaysOptions ?? []}
            />
          </GridItem>
          <GridItem>
            <DataCell label='Language' size='medium'>
              {supplier?.locale}
            </DataCell>
          </GridItem>
          <Grid item xs={4}></Grid>
        </GridContainer>
      )}
      <GridContainer>
        <GridItem>
          <DataCell
            label='Supplier name'
            size='medium'
            footnote={
              supplier?.previousContactInformation?.contactName
                ? `Previously: ${supplier.previousContactInformation.contactName}`
                : undefined
            }
          >
            {supplier?.contactName}
          </DataCell>
        </GridItem>
        <GridItem>
          <DataCell
            label='Supplier e-mail'
            size='medium'
            footnote={
              supplier?.previousContactInformation
                ? `Previously: ${supplier.previousContactInformation.email}`
                : undefined
            }
          >
            {supplier?.email}
          </DataCell>
        </GridItem>
        <GridItem>
          <DataCell label='Supplier ID' size='medium'>
            {supplier?.externalId}
          </DataCell>
        </GridItem>
        {isPurchasing && (
          <GridItem>
            <DataCell label='Quote ID' size='medium'>
              {negotiationEvent.quoteId}
            </DataCell>
          </GridItem>
        )}
      </GridContainer>
      {supplier?.phone && (
        <GridContainer>
          <GridItem>
            <DataCell label='Supplier phone' size='small'>
              {supplier?.phone}
            </DataCell>
          </GridItem>
        </GridContainer>
      )}
      <GridContainer>
        <GridItem>
          <DataCell label='Overall feedback' size='small'>
            {supplier?.negotiation?.cesFeedback.comment ?? <NoValueCell />}
          </DataCell>
        </GridItem>
        <GridItem>
          <DataCell label='Rating' size='small'>
            {supplier?.negotiation?.cesFeedback.score ?? <NoValueCell />}
          </DataCell>
        </GridItem>
        {supplier?.isEnabledForNegotiation && (
          <GridItem>
            <DataCell label='Negotiation' size='small'>
              <NegotiationLink
                negotiationEvent={negotiationEvent}
                negotiation={supplier?.negotiation}
                supplier={supplier}
              />
            </DataCell>
          </GridItem>
        )}
      </GridContainer>
      {quoteAttachment && isMultiSupplier && (
        <GridContainer>
          <GridItem>
            <DataCell label='Updated quote' size='small'>
              <Typography
                whiteSpace='nowrap'
                textOverflow='ellipsis'
                overflow='hidden'
                variant='inherit'
                fontSize={(theme) => theme.typography.body2.fontSize}
              >
                <InsertDriveFileRounded fontSize='extra-small' />
                <Link sx={{ ml: 0.5, cursor: 'pointer' }} onClick={onDownload(quoteAttachment.id)}>
                  {quoteAttachment.fileName}
                </Link>
              </Typography>
            </DataCell>
          </GridItem>
        </GridContainer>
      )}
      {(isPurchasing || (isContractCost && showItems)) && (
        <>
          <Typography variant='h3' sx={{ mt: 4 }}>
            Items
          </Typography>
          <DataGrid
            {...dataGridProps}
            sx={{ mt: 2, ml: 1 }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'numberInCollection', sort: 'asc' }],
              },
              columns: {
                columnVisibilityModel: {
                  ...(showMinimumOrderQuantityColumns
                    ? {
                        minimumOrderQuantity: false,
                        maximumAcceptableMoq: !isAgreementReached,
                      }
                    : {}),
                },
              },
            }}
            getRowId={(row: LineItem) => row.numberInCollection}
            columns={
              [
                {
                  field: 'numberInCollection',
                  headerName: 'Order',
                  flex: 0.5,
                  align: 'center',
                  renderCell: renderTooltipCell,
                },
                {
                  field: 'name',
                  headerName: 'Name',
                  flex: 1.25,
                  renderCell: renderTooltipCell,
                },
                {
                  field: 'description',
                  headerName: 'Description',
                  flex: 1,
                  renderCell: renderTooltipCell,
                },
                {
                  field: 'category',
                  headerName: 'Category',
                  flex: 0.75,
                  renderCell: renderTooltipCell,
                },
                ...(isContractCost && showInvoiceDateColumn
                  ? [
                      {
                        field: 'invoiceDate',
                        headerName: 'Invoice date',
                        flex: 1,
                        renderCell: ({ row }: GridRenderCellParams<LineItem>) =>
                          renderInvoiceDateCell(row, negotiationEvent, formatter),
                      },
                    ]
                  : []),
                {
                  field: 'quantity',
                  headerName: isContractCost ? lineItemQuantityField.label : 'Quantity',
                  type: 'number',
                  flex: 1,
                  renderCell: renderTooltipCell,
                },
                {
                  field: 'unit',
                  headerName: 'Unit of measure',
                  flex: 0.75,
                  renderCell: renderTooltipCell,
                },
                ...(showMinimumOrderQuantityColumns
                  ? [
                      {
                        field: 'minimumOrderQuantity',
                        headerName: 'Baseline MOQ',
                        description: 'Baseline minimum order quantity',
                        type: 'number',
                        flex: 1,
                        renderCell: renderTooltipUnitCell,
                      },
                      {
                        field: 'maximumAcceptableMoq',
                        headerName: 'Max MOQ',
                        description: 'Maximum acceptable MOQ',
                        type: 'number',
                        flex: 1,
                        renderCell: renderTooltipUnitCell,
                        valueGetter: ({
                          row,
                        }: GridValueGetterParams<NegotiationEventSupplierLineItem>) =>
                          row.negotiationSettings?.maximumAcceptableMoq,
                      },
                    ]
                  : []),
                ...(showMinimumOrderQuantityColumns && isAgreementReached
                  ? [
                      {
                        field: 'negotiatedMinimumOrderQuantity',
                        headerName: 'MOQ',
                        description: 'Agreed minimum order quantity',
                        type: 'number',
                        flex: 1,
                        renderCell: renderTooltipUnitCell,
                      },
                    ]
                  : []),
                ...(showIncotermsColumns
                  ? [
                      {
                        field: 'incoterm',
                        headerName: 'Current incoterm',
                        flex: 0.75,
                        renderCell: renderTooltipCell,
                      },
                      {
                        field: 'acceptableIncoterms',
                        headerName: 'Acceptable incoterms',
                        flex: 0.75,
                        renderCell: (params: GridRenderCellParams) =>
                          renderAcceptableIncotermsCell(params),
                      },
                    ]
                  : []),
                {
                  field: 'quotedUnitPrice',
                  headerName: 'Quoted unit price',
                  flex: 1,
                  renderCell: (params) => renderUnitPriceCell(params, formatter),
                },
                {
                  field: 'negotiatedUnitPrice',
                  headerName: 'Agreed unit price',
                  flex: 1,
                  renderCell: (params) => renderUnitPriceCell(params, formatter),
                },
                ...(isContractCost && showConsignmentInventoryColumn
                  ? [
                      {
                        field: 'negotiatedConsignmentInventory',
                        headerName: 'Consignment inventory',
                        flex: 1,
                        renderCell: (params: GridRenderCellParams) =>
                          renderConsignmentInventoryCell(params),
                      },
                    ]
                  : []),
                ...(showDeliveryLocationColumn
                  ? [
                      {
                        field: 'deliveryLocation',
                        headerName: isContractCost
                          ? lineItemLocationField.label
                          : 'Incoterm location',
                        flex: 1,
                        renderCell: renderTooltipCell,
                      },
                    ]
                  : []),
              ] as GridColDef<LineItem>[]
            }
            rows={supplierLineItems}
          />
        </>
      )}
      {isContractCost && <Terms event={negotiationEvent} supplier={supplier} />}
      <OfferedIncentives
        supplier={supplier}
        incentives={configuration?.suite.incentivesOptions ?? []}
      />
      <FilesVisibleToSupplier negotiationEvent={negotiationEvent} supplier={supplier} />
      <AddSupplierDialog
        open={dialogVisible}
        onClose={(addedNew = false) => {
          if (addedNew) {
            setLatestAddedSupplier(true)
          }
          setDialogVisible(false)
        }}
        negotiationEventId={negotiationEvent.id}
      />
    </>
  )
}

import React from 'react'
import { FormDialog } from '@components/FormDialog'
import { useDownloadTemplateData } from '../../hooks/useDownloadTemplateData'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { Grid, Link, Stack } from '@mui/material'
import { NegotiationEventsImport } from '../NegotiationEventImport/NegotiationEventsImport'
import { Download } from '@mui/icons-material'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const UploadNegotiationEvent = ({ isOpen, handleClose }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const startDownload = useDownloadTemplateData(activeProjectTag)

  return (
    <FormDialog
      fullWidth
      open={isOpen}
      maxWidth='sm'
      loading={false}
      title='Upload negotiations'
      buttons={[{ type: 'submit', label: 'Close' }]}
      onSubmit={handleClose}
      onCancel={handleClose}
    >
      <Grid container py={1} spacing={1}>
        <Grid item xs={12} my={1}>
          <NegotiationEventsImport onFinishUpload={handleClose} />
        </Grid>
        <Grid item xs={12}>
          <Link variant='normal' href='#' onClick={startDownload}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Download /> Download the excel template
            </Stack>
          </Link>
        </Grid>
      </Grid>
    </FormDialog>
  )
}

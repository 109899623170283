import z from 'zod'
import { emptyStringToNull } from '@procurement/utils/transformers'
import {
  attachmentSchema,
  contractModelSchema,
  DateField,
  detachedAttachmentSchema,
  negotiationSettingsSchema,
  nullishNanNumberSchema,
  paymentDayObjectSchema,
  validateSingleDecimalPlaceIfNumber,
} from '@procurement/store/schema'

export type NegotiationEventFormData = z.infer<typeof negotiationEventFormDataSchema>

const lineItemSchema = z.object({
  id: z.number().nullish(),
  acceptableIncoterms: z.union([z.string(), z.array(z.string())]).nullish(),
  category: z.string().nullish(),
  consignmentAgreementStatus: z.string().nullish(),
  currency: z.string().nullish(),
  deliveryLocation: z.string().nullish(),
  description: z.string().nullish(),
  externalId: z.string().nullish(),
  incoterm: z.string().nullish(),
  initialUnitPrice: z.number().nullish(),
  invoiceDate: z.string().nullish(),
  maximumAcceptableMoq: z.number().nullish(),
  minimumOrderQuantity: z.number().nullish(),
  name: z.string().nullish(),
  numberInCollection: z.number().nullish(),
  quantity: z.number().nullish(),
  unit: z.string().nullish(),
})

const supplierSchema = z.object({
  id: z.number().nullish(),
  contactName: z.string().nullish(),
  email: z.string().email().or(z.literal('')).nullish(),
  externalId: z.string().nullish(),
  // TODO: can we move initialPaymentTerms to paymentDayObjectSchema in the future?
  // This will require the refactor of suite requisitionFormFields configuration
  initialPaymentTerms: z.object({
    discount: nullishNanNumberSchema.refine(
      validateSingleDecimalPlaceIfNumber,
      'Up to one decimal place allowed',
    ),
    discountDays: z.union([nullishNanNumberSchema, paymentDayObjectSchema]),
    paymentDays: z.union([nullishNanNumberSchema, paymentDayObjectSchema]),
  }),
  locale: z.string().nullish(),
  name: z.string().nullish(),
  negotiationSettings: negotiationSettingsSchema.nullable().default({
    previousContractLength: null,
    contractLength: null,
    contractLengthAlternatives: [],
    contractStartDate: null,
    incentives: [],
    maxPaymentDays: null,
    maxTotalPrice: null,
    minPaymentDays: null,
    rebates: null,
    shouldOfferMembership: null,
    warranty: null,
  }),
  phone: z.string().nullish(),
})

export enum NegotiationMode {
  Prices = 'prices',
  Spend = 'spend',
}

export const negotiationEventFormDataSchema = z
  .object({
    id: z.number().nullish(),
    attachments: z.array(attachmentSchema).default([]),
    buyerContactEmail: z.string().nullish(),
    buyerContactName: z.string().nullish(),
    category: z.string().nullish(),
    contractDatesEnabled: z.boolean().nullish().default(true),
    contractIncotermsEnabled: z.boolean().nullish().default(false),
    contractModel: contractModelSchema.nullish(),
    currency: z.string().optional(),
    deliveryDate: z
      .union([z.date(), z.string()])
      .nullish()
      .transform((val) => (val ? new Date(val) : undefined)) as DateField,
    deliveryLocation: z.string().nullish(),
    detachedAttachments: z.array(detachedAttachmentSchema).default([]),
    externalId: z.string().transform(emptyStringToNull).nullish(),
    initialAmount: z.number().nullish(),
    lineItems: z.array(lineItemSchema).default([]),
    negotiationsExpireDays: nullishNanNumberSchema,
    paymentDaysEnabled: z.boolean().nullish().default(true),
    quoteId: z.string().nullish(),
    suppliers: z.array(supplierSchema).default([
      {
        initialPaymentTerms: {
          discount: null,
          discountDays: null,
          paymentDays: null,
        },
        negotiationSettings: {
          contractLengthAlternatives: [],
          incentives: [],
        },
      },
    ]),
    title: z.string().nullish(),
    uniqueName: z.string().transform(emptyStringToNull).nullish(),
    warrantyEnabled: z.boolean().nullish().default(false),
    // NOTE: This is a hack to bypass an issue with react-hook-form that produces an error
    // when value of lineItems.0.initialUnitPrice is empty in RebatesWithApplicableSpend component:
    // "TypeError: Cannot add property 0, object is not extensible".
    // See related GH issue: https://github.com/react-hook-form/react-hook-form/issues/6999
    spendAmount: z.number().nullish().default(null),
    negotiationMode: z.nativeEnum(NegotiationMode).default(NegotiationMode.Prices),
  })
  .transform((data) => ({
    // set default values
    ...data,
    suppliers: data.suppliers.map((supplier) => ({
      ...supplier,
      negotiationSettings: {
        ...supplier.negotiationSettings,
        maxTotalPrice: supplier.negotiationSettings?.maxTotalPrice ?? null,
      },
    })),
  }))

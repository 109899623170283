import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { environment } from 'src/environments/environment'
import { getAccessToken } from '#src/main/hooks/auth/useAuthInternals'

export const priceListBaseApi = <T extends string>(reducerPath: T) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl: environment.REACT_APP_DIRECT_PROCUREMENT_BACKEND_URL ?? '',
      prepareHeaders: async (headers) => {
        const token = await getAccessToken()
        headers.set('Authorization', `Bearer ${token}`)

        return headers
      },
    }),
    endpoints: () => ({}),
    tagTypes: [],
  })

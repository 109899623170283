import { createContext, type ReactNode, useContext } from 'react'
import { Grid, Typography } from '@mui/material'
import { CheckboxCtrl } from '@components/Form'
import { useFormContext } from 'react-hook-form'
import { useFormConfig } from './Root'

const FormSectionContext = createContext<{
  open?: boolean
  toggleName?: string
}>({})

const useFormSection = () => useContext(FormSectionContext)

export const Section = ({
  children,
  configId,
  toggleName,
}: {
  children: ReactNode
  configId?: string
  toggleName?: string
}) => {
  const form = useFormContext()
  const formConfig = useFormConfig()
  const fieldConfig = configId ? formConfig.get(configId) : null

  if (fieldConfig && !fieldConfig.visible) {
    return null
  }

  const open = !toggleName || !!form.watch(toggleName)

  return (
    <FormSectionContext.Provider value={{ toggleName, open }}>
      <Grid
        className='form-section'
        item
        container
        spacing={2}
        sx={{
          '.form-section-title': {
            fontSize: '1rem',
          },
          '.form-section .form-section-title': {
            fontSize: '0.875rem',
          },
        }}
      >
        {children}
      </Grid>
    </FormSectionContext.Provider>
  )
}

export const SectionTitle = ({ children }: { children: string }) => {
  const { toggleName } = useFormSection()

  return (
    <Grid item xs={12}>
      {toggleName === undefined ? (
        <Typography className='form-section-title' variant='subtitle2'>
          {children}
        </Typography>
      ) : (
        <CheckboxCtrl
          label={children}
          labelProps={{ className: 'form-section-title', fontWeight: 700 }}
          name={toggleName}
        />
      )}
    </Grid>
  )
}

export const SectionDescription = ({ children }: { children: ReactNode }) => {
  const { open } = useFormSection()

  if (!open) {
    return null
  }

  return (
    <Grid item xs={12}>
      <Typography variant='body2'>{children}</Typography>
    </Grid>
  )
}

export const SectionContent = ({ children }: { children: ReactNode }) => {
  const { open } = useFormSection()

  if (!open) {
    return null
  }

  return children
}

import { useFormContext } from 'react-hook-form'
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import { PurchasingUIConfig } from '@procurement/store/types'
import { Incentive } from '@procurement/store/schema'
import { SourcingEventFormData } from './SourcingEventForm.schema'

interface IncentivesProps {
  incentives: NonNullable<PurchasingUIConfig['suite']['incentivesOptions']>
  name: `suppliers.${number}.negotiationSettings.incentives`
}

export const Incentives = ({ incentives, name }: IncentivesProps) => {
  const { watch, setValue } = useFormContext<SourcingEventFormData>()
  const selectedIncentives = watch(name)

  const handleCheckboxCheck = (incentive: Incentive) => {
    let updatedIncentives

    if (selectedIncentives?.includes(incentive.id)) {
      updatedIncentives = selectedIncentives.filter((incentiveId) => incentiveId !== incentive.id)
    } else {
      updatedIncentives = [...(selectedIncentives ?? []), incentive.id]
    }

    setValue(name, updatedIncentives)
  }

  return (
    <Grid item container rowSpacing={2}>
      {incentives.map((incentive) => (
        <Box key={incentive.id} sx={{ width: '100%' }}>
          <FormControlLabel
            checked={selectedIncentives?.includes(incentive.id)}
            onChange={() => handleCheckboxCheck(incentive)}
            control={<Checkbox sx={{ py: 0.5 }} />}
            label={<Typography variant='body2'>{incentive.primaryWording}</Typography>}
          />
        </Box>
      ))}
    </Grid>
  )
}

import { Grid, Stack, styled, Typography } from '@mui/material'
import { HeaderOutlinedButton, HeaderPrimaryButton, useLayoutContext } from '@components/Layout'
import { APP_HEADER_HEIGHT } from '@constants'
import { InnerContainer } from '@components/Layout/Containers'
import { NegotiationEventStatusChip } from '../NegotiationStatusChip/NegotiationStatusChip'
import {
  NegotiationEventAction,
  useNegotiationEventActions,
} from '@procurement/hooks/useNegotiationEventActions'
import { NegotiationEventFormContextProvider } from '../NegotiationEventForm/NegotiationEventFormContext'
import { MouseEvent, useState } from 'react'
import {
  getUpdatedQuoteAttachment,
  useDownloadNegotiationEventAttachmentQuery,
} from '@procurement/hooks/useDownloadNegotiationEventAttachmentQuery'
import { NegotiationEventFormDialog } from '@procurement/components/NegotiationEventForm/NegotiationEventFormDialog'
import { StartConfirmationModal } from '@procurement/pages/NegotiationEventList/NegotiationEventListTable/StartConfirmationModal/StartConfirmationModal'
import { useGetConfigurationQuery } from '@procurement/store/purchasing'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { SourcingEventFormDialog } from '../SourcingEventForm/SourcingEventFormDialog'

export const NegotiationEventDetailsHeader = () => {
  const { negotiationEvent } = useLayoutContext()
  const { activeProjectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({
    projectTag: activeProjectTag,
  })

  const [editModalOpen, setEditModalOpen] = useState(false)
  const [startConfirmationModalOpen, setStartConfirmationModalOpen] = useState(false)
  const [isStartNegotiationsLoading, setIsStartNegotiationsLoading] = useState(false)

  const {
    availableActions,
    handleNegotiationEventStartAction,
    handleNegotiationEventsDownloadAction,
  } = useNegotiationEventActions(negotiationEvent ? [negotiationEvent] : [])

  const downloadHandler = useDownloadNegotiationEventAttachmentQuery()

  if (!negotiationEvent) {
    return null
  }

  const onQuoteDownload = (attachmentId: number) => (event: MouseEvent) => {
    event.preventDefault()
    downloadHandler({
      attachmentId,
      projectTag: negotiationEvent.projectTag,
      negotiationEventId: negotiationEvent.id,
    })
  }

  const onResultDownload = () => (event: MouseEvent) => {
    event.preventDefault()
    handleNegotiationEventsDownloadAction()
  }

  const handleStartConfirmation = async () => {
    if (negotiationEvent) {
      try {
        setIsStartNegotiationsLoading(true)
        await handleNegotiationEventStartAction()
      } finally {
        setIsStartNegotiationsLoading(false)
      }
    }
    setStartConfirmationModalOpen(false)
  }

  const isMultiSupplier = negotiationEvent.suppliers.length > 1
  const quoteAttachment = getUpdatedQuoteAttachment(negotiationEvent.attachments)
  const hasEditAction = availableActions.includes(NegotiationEventAction.EDIT)
  const hasStartAction =
    availableActions.includes(NegotiationEventAction.START) &&
    !negotiationEvent.negotiationsStartedAt
  const hasDownloadAction = availableActions.includes(NegotiationEventAction.DOWNLOAD)
  const showAddSourcingEventButton = configuration?.suite.showAddSourcingEventButton

  return (
    <>
      <OuterContainer container>
        <InnerContainer>
          <Stack alignItems='center' direction='row' gap={2}>
            <Stack
              alignItems='inherit'
              direction='row'
              flexGrow={1}
              gap='inherit'
              overflow='hidden'
            >
              <Title lineHeight='2.5rem' noWrap variant='h1'>
                {negotiationEvent.title}
              </Title>
              <NegotiationEventStatusChip
                status={negotiationEvent.negotiationStatus}
                suppliers={negotiationEvent.suppliers}
                size='large'
                variant='inverted'
              />
            </Stack>
            {quoteAttachment && !isMultiSupplier && (
              <HeaderOutlinedButton onClick={onQuoteDownload(quoteAttachment.id)}>
                Download updated quote
              </HeaderOutlinedButton>
            )}
            {hasEditAction && (
              <HeaderOutlinedButton onClick={() => setEditModalOpen(true)}>
                Edit negotiation
              </HeaderOutlinedButton>
            )}
            {hasDownloadAction && (
              <HeaderPrimaryButton onClick={onResultDownload()}>
                Download results
              </HeaderPrimaryButton>
            )}
            {hasStartAction && (
              <HeaderPrimaryButton onClick={() => setStartConfirmationModalOpen(true)}>
                Start negotiation{isMultiSupplier ? 's' : ''}
              </HeaderPrimaryButton>
            )}
          </Stack>
        </InnerContainer>
        <NegotiationEventFormContextProvider>
          {showAddSourcingEventButton ? (
            <SourcingEventFormDialog
              open={editModalOpen}
              onClose={() => setEditModalOpen(false)}
              negotiationEvent={negotiationEvent}
            />
          ) : (
            <NegotiationEventFormDialog
              open={editModalOpen}
              onClose={() => setEditModalOpen(false)}
              negotiationEvent={negotiationEvent}
            />
          )}
        </NegotiationEventFormContextProvider>
      </OuterContainer>
      <StartConfirmationModal
        isMultiSupplier={isMultiSupplier}
        open={startConfirmationModalOpen}
        onCancel={() => setStartConfirmationModalOpen(false)}
        onSubmit={handleStartConfirmation}
        loading={isStartNegotiationsLoading}
      />
    </>
  )
}

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
}))

const OuterContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(4),
  backgroundColor: theme.palette.primary.main,
  marginTop: `calc(${APP_HEADER_HEIGHT}px - 0.5px)`,
  zIndex: theme.zIndex.secondaryHeader,
  position: 'sticky',
}))

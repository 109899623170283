import { Route } from 'react-router-dom'

import { Layout } from 'src/shared/components/Layout'
import { ProtectedRoute } from 'src/shared/components/ProtectedRoute'
import { PriceListRoutes } from './priceListRoutes'
import { DashboardPage } from '@pages/DashboardPage/DashboardPage'
import { locales } from '@common/constants'
import { SuitePermission } from '@common/types'
import { NegotiationEventsPage } from './pages/NegotiationEvents/NegotiationEventsPage'
import { NegotiationEventDetailsPage } from './pages/NegotiationEventDetails/NegotiationEventDetailsPage'

export const PriceListEntryPoint = () => {
  return (
    <>
      <Route element={<Layout locale={locales.us} />}>
        <Route
          path={PriceListRoutes.DASHBOARD}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.READ}>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PriceListRoutes.NEGOTIATIONS}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.MODIFY}>
              <NegotiationEventsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PriceListRoutes.NEGOTIATION_DETAILS}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.MODIFY}>
              <NegotiationEventDetailsPage />
            </ProtectedRoute>
          }
        />
      </Route>
    </>
  )
}

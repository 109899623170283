import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { environment } from 'src/environments/environment'
import { getAccessToken } from '#src/main/hooks/auth/useAuthInternals'

export const suiteApiBaseUrl =
  !environment.REACT_APP_ENV || environment.REACT_APP_ENV === 'development'
    ? environment.REACT_APP_SERVER_BASE_URL
    : `${window.location.protocol}//${window.location.host}/api/v1`

export const suiteBaseApi = createApi({
  reducerPath: 'suite-api',
  baseQuery: fetchBaseQuery({
    baseUrl: suiteApiBaseUrl,
    prepareHeaders: async (headers) => {
      const token = await getAccessToken()
      headers.set('Authorization', `Bearer ${token}`)

      return headers
    },
  }),
  endpoints: () => ({}),
})

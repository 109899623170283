import { useRef, useState } from 'react'
import { Box, Button, Grid, Stack, styled, Typography, useTheme } from '@mui/material'
import { ZoomInMap } from '@mui/icons-material'
import { Chart as ReactChart } from 'react-chartjs-2'
import { type Chart } from 'chart.js'
import { MarketId, ScenarioState } from '@common/types/scenario/ScenarioState'
import { useFormatter } from '@shared/hooks'
import { filterWithHistoricalData, getChartOptions, getDatasets } from './utils'
import { ChartFilter, HistoricalFilters } from '../components/Filters'
import { QoQChangesTable } from './QoQChangesTable'

interface Props {
  allItems: ScenarioState['item_info']
  allMarkets: ScenarioState['market_info']
  historical: Required<ScenarioState>['historical']
  defaultInputs: { marketId?: MarketId }
}

export const HistoricalChart = ({ allItems, allMarkets, historical, defaultInputs }: Props) => {
  const [selectedFilters, setSelectedFilters] = useState<HistoricalFilters>({
    items: [],
    markets: [],
  })

  const theme = useTheme()
  const formatter = useFormatter()
  const spendChartRef = useRef<Chart | null>(null)
  const priceChartRef = useRef<Chart | null>(null)
  const volumeChartRef = useRef<Chart | null>(null)

  const labels = Object.values(historical.metrics.index_to_labels)
  const options = getChartOptions(formatter)

  const datasets = getDatasets({
    filters: selectedFilters,
    historical,
    theme,
  })

  return (
    <Box mt={3}>
      <ChartFilter
        items={filterWithHistoricalData(allItems)}
        markets={filterWithHistoricalData(allMarkets)}
        filtersToShow={['items', 'markets', 'price']}
        defaultFilters={{ markets: defaultInputs.marketId }}
        onChange={setSelectedFilters}
      />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
        <Grid item xs={6}>
          <Stack direction='row' alignItems='center'>
            <Typography variant='body2' fontWeight='bold'>
              Spend: {selectedFilters.price?.label}
            </Typography>
            <ResetZoomButton onClick={() => spendChartRef.current?.resetZoom()} />
          </Stack>
          <ReactChart
            ref={spendChartRef}
            options={options}
            type='line'
            data={{
              labels,
              datasets: datasets.spend,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant='body2' fontWeight='bold' sx={{ mb: 1 }}>
            Average quarter-on-quarter changes for all items and markets
          </Typography>
          <QoQChangesTable historicalData={historical} />
        </Grid>
        <Grid item xs={6}>
          <Stack direction='row' alignItems='center'>
            <Typography variant='body2' fontWeight='bold'>
              Price: {selectedFilters.price?.label}
            </Typography>
            <ResetZoomButton onClick={() => priceChartRef.current?.resetZoom()} />
          </Stack>
          <ReactChart
            ref={priceChartRef}
            options={options}
            type='line'
            data={{
              labels,
              datasets: datasets.price,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Stack direction='row' alignItems='center'>
            <Typography variant='body2' fontWeight='bold'>
              Volume
            </Typography>
            <ResetZoomButton onClick={() => volumeChartRef.current?.resetZoom()} />
          </Stack>
          <ReactChart
            ref={volumeChartRef}
            options={options}
            type='line'
            data={{
              labels,
              datasets: datasets.volume,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

const ResetZoomButton = ({ onClick }: { onClick: () => void }) => (
  <StyledResetZoomButton variant='text' size='small' onClick={onClick} startIcon={<ZoomInMap />}>
    Reset zoom
  </StyledResetZoomButton>
)

const StyledResetZoomButton = styled(Button)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: theme.spacing(4),
  fontSize: '8px',
  color: theme.palette.grey.A700,
  ' .MuiButton-startIcon svg': {
    fontSize: '12px',
  },
}))

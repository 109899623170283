import z from 'zod'
import { emptyStringToNull } from '@procurement/utils/transformers'
import {
  attachmentSchema as baseAttachmentSchema,
  localAttachmentSchema,
  nullishNanNumberSchema,
  paymentDayObjectSchema,
  validateSingleDecimalPlaceIfNumber,
} from '@procurement/store/schema'

export type AttachmentSchema = z.infer<typeof attachmentSchema>
export type NegotiationEventLineItemSchema = z.infer<typeof negotiationEventLineItemSchema>
export type SourcingEventFormData = z.infer<typeof sourcingEventFormSchema>
export type SupplierSchema = z.infer<typeof supplierSchema>
export type SupplierLineItemSchema = z.infer<typeof supplierLineItemSchema>

const attachmentSchema = z.union([baseAttachmentSchema, localAttachmentSchema])

const negotiationEventLineItemSchema = z.object({
  category: z.string().nullable(),
  currency: z.string().trim().min(1),
  description: z.string().nullable(),
  externalId: z.string().nullable(),
  id: z.number().optional(),
  name: z.string().trim().min(1),
  numberInCollection: z.number().positive(),
  quantity: z.number().nonnegative(),
  unit: z.string().trim().min(1),
  unitPrice: z.null(),
  // Local fields
  rowId: z.number(),
})

const supplierLineItemSchema = z.object({
  numberInCollection: z.number().positive(),
  quantity: z.number().nonnegative(),
  unit: z.string().trim().min(1),
  unitPrice: z.number().nonnegative(),
  // Local fields
  rowId: z.number(),
})

const supplierSchema = z.object({
  id: z.number().optional(),
  attachments: z.array(attachmentSchema),
  contactName: z.string().trim().transform(emptyStringToNull).nullable(),
  email: z.string().email(),
  externalId: z.string().trim().transform(emptyStringToNull).nullable(),
  // TODO: can we move initialPaymentTerms to paymentDayObjectSchema in the future?
  // This will require the refactor of suite requisitionFormFields configuration
  initialPaymentTerms: z
    .object({
      discount: nullishNanNumberSchema.refine(
        validateSingleDecimalPlaceIfNumber,
        'Up to one decimal place allowed',
      ),
      discountDaysObject: z.union([nullishNanNumberSchema, paymentDayObjectSchema]),
      paymentDaysObject: z.union([nullishNanNumberSchema, paymentDayObjectSchema]),
    })
    .optional(),
  isOriginalSupplier: z.boolean(),
  locale: z.string().nullable(),
  name: z.string().trim().min(1),
  negotiationSettings: z
    .object({
      incentives: z.array(z.string()).optional(),
      maxPaymentDays: z.union([nullishNanNumberSchema, paymentDayObjectSchema]),
      minPaymentDays: z.union([nullishNanNumberSchema, paymentDayObjectSchema]),
      totalPriceTarget: z.number().nullable(),
    })
    .nullable(),
  phone: z.string().nullable(),
  lineItems: z.array(supplierLineItemSchema),
  // Local fields
  rowId: z.number(),
})

export const sourcingEventFormSchema = z
  .object({
    id: z.number().optional(),
    buyerContactEmail: z.string().email(),
    buyerContactName: z.string().trim().min(1),
    deliveryDate: z.date().nullable(),
    externalId: z.string().trim().min(1),
    isEveryCompetingQuoteComparable: z.boolean().nullable(),
    lineItems: z.array(negotiationEventLineItemSchema).min(1),
    quoteId: z.string().transform(emptyStringToNull).nullable(),
    suppliers: z.array(supplierSchema).min(1),
    title: z.string().trim().min(1),
    // Local fields
    incentivesEnabled: z.boolean().default(true),
    paymentDaysEnabled: z.boolean().default(true),
  })
  .transform((value) => ({ ...value, uniqueName: value.externalId }))

import { useUser } from '@hooks'
import { ArrowDropDown } from '@mui/icons-material'
import { Avatar, Badge, Box, Button, Stack, styled, Typography } from '@mui/material'
import { PropsWithChildren, useRef, useState } from 'react'
import { UserBadgeMenu } from './UserBadgeMenu'
import { useHasNewChangelogs } from './useHasNewChangelogs'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.error.main,
    boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
  },
}))

const NewChangelogsIndicator = ({
  children,
  showIndicator,
}: PropsWithChildren & {
  showIndicator: boolean
}) =>
  showIndicator ? (
    <StyledBadge
      overlap='circular'
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      variant='dot'
    >
      {children}
    </StyledBadge>
  ) : (
    <>{children}</>
  )

export const UserBadge = () => {
  const { user } = useUser()
  const { hasNewChangelogs } = useHasNewChangelogs()

  const buttonRef = useRef(null)
  const [popoverOpen, setPopoverOpen] = useState(false)

  if (!user) {
    return null
  }

  return (
    <Box>
      <Button
        color='accent'
        variant='contained'
        size='large'
        ref={buttonRef}
        onClick={() => setPopoverOpen((oldOpen) => !oldOpen)}
        // Since popover list is child to have popover same size as button,
        // mouse events to children are propagated to this parent
        disableRipple={popoverOpen}
        data-test-user-menu
      >
        <Stack direction='row' alignItems='center' spacing={1}>
          <NewChangelogsIndicator showIndicator={hasNewChangelogs}>
            <Avatar
              variant='rounded'
              alt='avatar'
              src={user.picture}
              imgProps={{ referrerPolicy: 'no-referrer' }}
              sx={{ width: '24px', height: '24px' }}
            />
          </NewChangelogsIndicator>
          <Typography
            aria-label='logout'
            component='span'
            variant='body2'
            fontWeight={700}
            letterSpacing='-0.005em'
            sx={{ color: 'background.default' }}
          >
            {user.name}
          </Typography>
          <ArrowDropDown />
        </Stack>
      </Button>
      <UserBadgeMenu buttonRef={buttonRef} open={popoverOpen} setOpen={setPopoverOpen} />
    </Box>
  )
}

import { ArrowOutward } from '@mui/icons-material'
import { Stack, styled, Typography } from '@mui/material'
import { capitalizeFirstLetter } from '@utils'
import { StatisticsItemData } from './types'

export const StatisticsItem = ({
  title,
  primaryValue,
  secondaryValue,
  options,
}: StatisticsItemData): JSX.Element => (
  <Stack textAlign='left' maxHeight='88px' maxWidth='220px' minWidth='174px'>
    <Typography component='span' variant='body2' lineHeight='24px' noWrap>
      {capitalizeFirstLetter(title)}
    </Typography>
    <Stack direction='row' mt={0.75} mb={0.25}>
      <Typography
        component='span'
        fontWeight={600}
        letterSpacing='-0.04em'
        fontSize='2rem'
        lineHeight='2rem'
        noWrap
      >
        {primaryValue}
      </Typography>
      {options?.primaryTrend && (
        <Arrow color={options.primaryTrend.color} rotate={options.primaryTrend.direction} />
      )}
    </Stack>
    {secondaryValue && (
      <Typography component='span' variant='body2' lineHeight='24px' noWrap sx={{ opacity: 0.5 }}>
        {secondaryValue}
      </Typography>
    )}
  </Stack>
)

const isErrorOrSuccessColor = (color: unknown): color is 'success' | 'error' => {
  return color === 'success' || color === 'error'
}

const Arrow = styled(ArrowOutward)(({ theme, color, rotate }) => ({
  fontSize: '2rem',
  marginLeft: theme.spacing(0.5),
  marginTop: theme.spacing(-0.5),
  stroke: isErrorOrSuccessColor(color) ? theme.palette[color].main : theme.palette.common.white,
  transform: rotate === 'down' ? 'rotate(90deg)' : undefined,
  strokeWidth: 1,
}))
